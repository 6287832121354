/*
 * Payment Messages
 *
 * This contains all the text for the Payment container.
 */

import { defineMessages } from 'react-intl';
import {
  TITLE_MR,
  TITLE_MS,
  TITLE_MX,
} from '../../containers/PassengersInfo/constants';

export const recapScope = 'app.components.Recap';

export const recapMessages = {
  passengersHeader: {
    id: `${recapScope}.passengersHeader`,
    defaultMessage: 'Passagers',
    en: 'Passengers',
    it: 'Passeggeri',
    es: 'Pasajeros',
    de: 'Passagiere',
    nl: 'Passagiers',
  },
  passenger: {
    id: `${recapScope}.passenger`,
    defaultMessage: 'Passager',
    en: 'Passenger',
    it: 'Passeggero',
    es: 'Pasajero',
    de: 'Passagier',
    nl: 'Passagier',
  },
  noDiscountCards: {
    id: `${recapScope}.noDiscountCards`,
    defaultMessage: 'Pas de carte de réduction',
    en: 'No discount cards',
    it: 'Nessuna carta sconti',
    es: 'Sin tarjeta de descuento',
    de: 'Keine Ermäßigungskarte',
    nl: 'Geen kortingskaart',
  },
  travelHeader: {
    id: `${recapScope}.travelHeader`,
    defaultMessage: 'Détail du trajet',
    en: 'Travel details',
    it: 'Dettagli del percorso',
    es: 'Detalles de la ruta',
    de: 'Details zur Fahrt',
    nl: 'Details van de route',
  },
  outboundTrip: {
    id: `${recapScope}.outboundTrip`,
    defaultMessage: 'Aller',
    en: 'Outbound',
    it: 'Andata',
    es: 'viaje de ida',
    de: 'Hinreise',
    nl: 'Ga naar',
  },
  returnTrip: {
    id: `${recapScope}.returnTrip`,
    defaultMessage: 'Retour',
    en: 'Return',
    it: 'Ritorno',
    es: 'Vuelta',
    de: 'Rückreise',
    nl: 'Terug',
  },
  mixedPaymentHeader: {
    id: `${recapScope}.mixedPaymentHeader`,
    defaultMessage: 'Paiement',
    en: 'Payment',
    it: 'Pagamento',
    es: 'Pago',
    de: 'Zahlung',
    nl: 'Betaling',
  },
  mixedPaymentSubHeader: {
    id: `${recapScope}.mixedPaymentSubHeader`,
    defaultMessage:
      "Les billets suivants sont disponibles à l'achat directement sur Tictactrip : ",
    en: 'The following tickets are directly available to sell on Tictactrip: ',
    it: "I seguenti biglietti sono disponibili per l'acquisto direttamente da Tictactrip: ",
    es: 'Los siguientes billetes se pueden comprar directamente en Tictactrip: ',
    de: 'Die folgenden Tickets können Sie direkt bei Tictactrip kaufen: ',
    nl: 'De volgende tickets zijn rechtstreeks bij Tictactrip te koop: ',
  },
  mixedPaymentBack: {
    id: `${recapScope}.mixedPaymentBack`,
    defaultMessage: 'Retour aux détails',
    en: 'Payment',
    it: 'Torna ai dettagli',
    es: 'Volver a los detalles',
    de: 'Zurück zu den Details',
    nl: 'Terug naar details',
  },
  mixedPaymentPay: {
    id: `${recapScope}.mixedPaymentPay`,
    defaultMessage: 'Réserver pour ',
    en: 'Book for ',
    it: 'Prenotare per ',
    es: 'Libro para ',
    de: 'Buchen für ',
    nl: 'Boek voor ',
  },
  createOrderLoading: {
    id: `${recapScope}.createOrderLoading`,
    defaultMessage: 'Création de la commande en cours',
    en: 'Creating the order in progress',
    it: 'Creazione dell’ordine in corso',
    es: 'Creación de la orden en curso',
    de: 'Bestellung wird erstellt',
    nl: 'Bestelling wordt aangemaakt',
  },
  [TITLE_MR]: {
    id: `${recapScope}.MR`,
    defaultMessage: 'M.',
    en: 'Mr.',
    it: 'Signor.',
    es: 'Sr.',
    de: 'Herr.',
    nl: 'Mr.',
  },
  [TITLE_MS]: {
    id: `${recapScope}.MS`,
    defaultMessage: 'Mme.',
    en: 'Ms.',
    it: 'Sig.ra',
    es: 'Sra.',
    de: 'Frau.',
    nl: 'Mevrouw.',
  },
  [TITLE_MX]: {
    id: `${recapScope}.MX`,
    defaultMessage: 'Mx.',
    en: 'Mx.',
    it: 'Mx.',
    es: 'Mx.',
    de: 'Mx.',
    nl: 'Mx.',
  },
  pay: {
    id: `${recapScope}.pay`,
    defaultMessage: 'Payer {price}',
    en: 'Pay {price}',
    it: 'Pagare {price}',
    es: 'Pagar {price}',
    de: 'Bezahlen {price}',
    nl: 'Betaal {price}',
  },
  pdfTickets: {
    id: `${recapScope}.pdfTickets`,
    defaultMessage: 'Mes billets',
    en: 'Download my Tickets',
    it: 'I miei biglietti',
    es: 'Mis entradas',
    de: 'Meine Tickets',
    nl: 'Mijn tickets downloaden',
  },
  pendingBooking: {
    id: `${recapScope}.pendingBooking`,
    defaultMessage: "Billets en cours d'émission",
    en: 'Your order is being processed',
    it: 'I biglietti vengono emessi',
    es: 'entradas en proceso de emisión',
    de: 'Ihre Bestellung wird gerade bearbeitet',
    nl: 'Uw bestelling wordt verwerkt',
  },
  feesTooltip: {
    id: `${recapScope}.feesTooltip`,
    defaultMessage: `Tarif de base : {basePrice}<br />
Frais de service : {feesPrice}<br />
Nos frais de services s’appliquent seulement sur les offres uniques proposées par Tictactrip. Ils nous permettent d’assurer le bon fonctionnement et le développement de notre plateforme.`,
    en: `Base price : {basePrice}<br />
Fees : {feesPrice}<br />
Fee only apply on custom trips made by Tictactrip. They allow us to improve our service to offer you the best experience possible`,
    it: `Tariffa base : {basePrice}<br />
Costo del servizio : {feesPrice}<br />
Le nostre tariffe di servizio si applicano solo alle offerte uniche proposte da Tictactrip. Ci permettono di garantire il corretto funzionamento e lo sviluppo della nostra piattaforma.`,
    es: `Tipo base : {basePrice}<br />
Cargo por servicio: {feesPrice}<br />
Nuestras tarifas de servicio se aplican únicamente a las ofertas únicas propuestas por Tictactrip. Nos permiten garantizar el buen funcionamiento y desarrollo de nuestra plataforma.
`,
    de: `Grundgebühr: {basePrice}<br />
Servicegebühr: {feesPrice}<br />
Unsere Dienstleistungsgebühren gelten nur für einmalige Angebote, die von Tictactrip angeboten werden. Sie ermöglichen uns, den reibungslosen Betrieb und die Weiterentwicklung unserer Plattform zu gewährleisten.
`,
    nl: `Basistarief: {basePrice}<br />
Servicekosten: {feesPrice}<br />
Onze servicekosten zijn alleen van toepassing op de unieke aanbiedingen van Tictactrip. Zij stellen ons in staat de goede werking en ontwikkeling van ons platform te garanderen.
`,
  },
  addToMyCalendar: {
    id: `${recapScope}.addToMyCalendar`,
    defaultMessage: 'Ajouter à mon calendrier',
    en: 'Add to my calendar',
    it: 'Aggiungi al mio calendario',
    es: 'Añadir a mi calendario',
    de: 'Zu meinem Kalender hinzufügen',
    nl: 'Toevoegen aan mijn agenda',
  },
};

export default defineMessages(recapMessages);
