/*
 * Privacy Messages
 *
 * This contains all the text for the Privacy container.
 */

import { defineMessages } from 'react-intl';
import { API_URL } from '../../helpers/constants';
import { NAVIGATION } from '../App/constants';

export const supportScope = 'app.containers.Support';

export const supportMessages = {
  pageTitle: {
    id: `${supportScope}.pageTitle`,
    defaultMessage: 'Un pépin, pas de pépin. <br /> Écrivez-nous. 👋',
    en: 'Write to us.',
    it: 'Scrivici.',
    es: 'Escríbanos.',
    de: 'Schreiben Sie uns.',
    nl: 'Schrijf ons.',
  },
  emailFieldLabel: {
    id: `${supportScope}.emailFieldLabel`,
    defaultMessage: 'Adresse email liée à votre réservation',
    en: 'Email address linked to your reservation',
    it: 'Indirizzo e-mail collegato alla prenotazione',
    es: 'Dirección de correo electrónico vinculada a la reserva',
    de: 'Mit der Reservierung verknüpfte E-Mail-Adresse',
    nl: 'E-mailadres gekoppeld aan reservering',
  },
  phoneFieldLabel: {
    id: `${supportScope}.phoneFieldLabel`,
    defaultMessage: 'Numéro de téléphone',
    en: 'Phone number',
    it: 'Numero di telefono',
    es: 'Número de teléfono',
    de: 'Mit der Reservierung verknüpfte E-Mail-Adresse',
    nl: 'Telefoonnummer',
  },
  orderIdFieldLabel: {
    id: `${supportScope}.orderIdFieldLabel`,
    defaultMessage: 'Numéro de commande (16 caractères)',
    en: 'Order number (16 characters)',
    it: "Numero d'ordine (16 caratteri)",
    es: 'Número de pedido (16 caracteres)',
    de: 'Bestellnummer (16 Zeichen)',
    nl: 'Bestelnummer (16 tekens)',
  },
  departureDateFieldLabel: {
    id: `${supportScope}.departureDateFieldLabel`,
    defaultMessage: 'Date de départ',
    en: 'Departure date',
    it: 'Data di partenza',
    es: 'Fecha de salida',
    de: 'Datum der Abreise',
    nl: 'Vertrekdatum',
  },
  contactSubjectFieldsLabel: {
    id: `${supportScope}.contactSubjectFieldsLabel`,
    defaultMessage: 'Quelle est la nature de votre demande ?',
    en: 'What is the nature of your request ?',
    it: 'Qual è la natura della vostra richiesta ?',
    es: '¿ Cuál es la naturaleza de su solicitud ?',
    de: 'Welcher Art ist Ihre Anfrage ?',
    nl: 'Wat is de aard van uw verzoek ?',
  },
  editTicketField: {
    id: `${supportScope}.editTicketField`,
    defaultMessage: 'Je souhaite annuler ou échanger mon billet',
    en: 'I want to cancel or exchange my ticket',
    it: 'Voglio annullare o cambiare il mio biglietto',
    es: 'Quiero anular o cambiar mi billete',
    de: 'Ich möchte mein Ticket stornieren oder umtauschen',
    nl: 'Ik wil mijn ticket annuleren of omruilen',
  },
  ticketCancelledByCompanyField: {
    id: `${supportScope}.ticketCancelledByCompanyField`,
    defaultMessage: 'Mon billet a été supprimé par la compagnie',
    en: 'My ticket was cancelled by the company',
    it: 'Il mio biglietto è stato annullato dalla compagnia',
    es: 'Mi billete fue cancelado por la compañía',
    de: 'Mein Ticket wurde von der Fluggesellschaft storniert',
    nl: 'Mijn ticket werd geannuleerd door het bedrijf',
  },
  notReceiveMyTicketsField: {
    id: `${supportScope}.notReceiveMyTicketsField`,
    defaultMessage: "Je n'ai pas reçu mes billets",
    en: 'I did not receive my tickets',
    it: 'Non ho ricevuto i miei biglietti',
    es: 'No he recibido mis entradas',
    de: 'Ich habe meine Tickets nicht erhalten',
    nl: 'Ik heb mijn tickets niet ontvangen',
  },
  notRefundedYetField: {
    id: `${supportScope}.notRefundedYetField`,
    defaultMessage: "Je n'ai pas encore été remboursé",
    en: "I haven't been refunded yet",
    it: 'Non sono ancora stato rimborsato',
    es: 'Todavía no he sido reembolsado',
    de: 'Ich wurde noch nicht erstattet',
    nl: 'Ik ben nog niet terugbetaald',
  },
  otherQuestionField: {
    id: `${supportScope}.otherQuestionField`,
    defaultMessage: "J'ai une autre question",
    en: 'I have another question',
    it: "Ho un'altra domanda",
    es: 'Tengo otra pregunta',
    de: 'Ich habe noch eine andere Frage',
    nl: 'Ik heb nog een andere vraag',
  },
  toBeNoted: {
    id: `${supportScope}.toBeNoted`,
    defaultMessage: 'À noter',
    en: 'To be noted',
    it: 'Da notare',
    es: 'A tener en cuenta',
    de: 'Zu beachten',
    nl: 'Te noteren',
  },
  'toBeNoted.editTicketField': {
    id: `${supportScope}.toBeNoted.editTicketField`,
    defaultMessage:
      "L'échange ou l'annulation d'un billet dépend de la compagnie de transport. <br /> Vérifiez les conditions avant de nous écrire. 🔎",
    en: 'Exchanging or canceling a ticket depends on the transportation company. <br /> Check the conditions before contacting us. 🔎',
    it: "Lo scambio o l'annullamento di un biglietto dipende dalla compagnia di trasporto. <br /> Controlla le condizioni prima di contattarci. 🔎",
    es: 'El intercambio o cancelación de un boleto depende de la compañía de transporte. <br /> Verifica las condiciones antes de contactarnos. 🔎',
    de: 'Der Umtausch oder die Stornierung eines Tickets hängt von der Transportgesellschaft ab. <br /> Überprüfen Sie die Bedingungen, bevor Sie uns kontaktieren. 🔎',
    nl: 'Het omruilen of annuleren van een ticket is afhankelijk van het transportbedrijf. <br /> Controleer de voorwaarden voordat u contact met ons opneemt. 🔎',
  },
  'toBeNoted.ticketCancelledByCompanyField': {
    id: `${supportScope}.toBeNoted.ticketCancelledByCompanyField`,
    defaultMessage:
      'Dès le formulaire envoyé, nous allons revenir vers vous le plus vite possible.',
    en: 'As soon as the form is sent, we will get back to you as soon as possible.',
    it: 'Non appena il modulo viene inviato, vi risponderemo al più presto.',
    es: 'Una vez enviado el formulario, nos pondremos en contacto con usted lo antes posible.',
    de: 'Sobald das Formular abgeschickt ist, werden wir uns so schnell wie möglich bei Ihnen melden.',
    nl: 'Zodra het formulier is verzonden, nemen wij zo spoedig mogelijk contact met u op.',
  },
  'toBeNoted.notReceiveMyTicketsField': {
    id: `${supportScope}.toBeNoted.notReceiveMyTicketsField`,
    defaultMessage:
      'Il arrive que nos mails arrivent en spams, pensez à bien vérifier dans votre boite mail.',
    en: 'It happens that our mails arrive in spam, remember to check your mailbox.',
    it: 'A volte le nostre e-mail arrivano come spam, quindi controllate la vostra casella di posta.',
    es: 'A veces nuestros correos electrónicos llegan como spam, así que asegúrese de comprobar su bandeja de entrada.',
    de: 'Es kommt vor, dass unsere E-Mails als Spam ankommen. Denken Sie daran, in Ihrer Mailbox nachzusehen.',
    nl: 'Soms komen onze e-mails binnen als spam, dus controleer uw inbox.',
  },
  'toBeNoted.notRefundedYetField': {
    id: `${supportScope}.toBeNoted.notRefundedYetField`,
    defaultMessage:
      'Dès le formulaire envoyé, nous allons revenir vers vous le plus vite possible.',
    en: 'As soon as the form is sent, we will get back to you as soon as possible.',
    it: 'Non appena il modulo viene inviato, vi risponderemo al più presto.',
    es: 'Una vez enviado el formulario, nos pondremos en contacto con usted lo antes posible.',
    de: 'Sobald das Formular abgeschickt ist, werden wir uns so schnell wie möglich bei Ihnen melden.',
    nl: 'Zodra het formulier is verzonden, nemen wij zo spoedig mogelijk contact met u op.',
  },
  'toBeNoted.otherQuestionField': {
    id: `${supportScope}.toBeNoted.otherQuestionField`,
    defaultMessage: `Votre réponse se trouve peut-être dans notre <a href="${API_URL}${NAVIGATION.FAQ}" style="color: unset;">FAQ<a/>.`,
    en: 'Your answer may be found in our FAQ.',
    it: 'La tua risposta potrebbe essere presente nella nostra FAQ.',
    es: 'Su respuesta puede encontrarse en nuestra FAQ.',
    de: 'Ihre Antwort finden Sie vielleicht in unseren FAQ.',
    nl: 'Uw antwoord kan te vinden zijn in onze FAQ.',
  },
  moreInformations: {
    id: `${supportScope}.moreInformations`,
    defaultMessage: "D'autres informations à ajouter ?",
    en: 'Is there any other information you would like to add ?',
    it: 'Ci sono altre informazioni che desiderate aggiungere ?',
    es: '¿ Desea añadir alguna otra información ?',
    de: 'Gibt es noch weitere Informationen, die Sie hinzufügen möchten ?',
    nl: 'Nog andere informatie toe te voegen ?',
  },
  moreInformationsPlaceholder: {
    id: `${supportScope}.moreInformationsPlaceholder`,
    defaultMessage: "J'ai perdu le train...",
    en: 'I missed the train...',
    it: 'Ho perso il treno...',
    es: 'Perdí el tren...',
    de: 'Ich habe den Zug verpasst...',
    nl: 'Ik heb de trein gemist...',
  },
  importImageOrPDFPlaceholder: {
    id: `${supportScope}.importImageOrPDFPlaceholder`,
    defaultMessage: 'Importer une image ou un fichier pdf',
    en: 'Import an image or a pdf file',
    it: "Importa un'immagine o un file pdf",
    es: 'Importar una imagen o un archivo pdf',
    de: 'Bild oder PDF-Datei importieren',
    nl: 'Importeer een afbeelding of pdf-bestand',
  },
  attachImageFieldLabel: {
    id: `${supportScope}.attachImageFieldLabel`,
    defaultMessage: 'Une photo, une image à joindre ?',
    en: 'Would you like to attach a photo or image ?',
    it: "Vorresti allegare una foto o un'immagine ?",
    es: '¿ Quieres adjuntar una foto o imagen ?',
    de: 'Möchten Sie ein Foto oder ein Bild anhängen ?',
    nl: 'Wilt u een foto of afbeelding bijvoegen ?',
  },
  send: {
    id: `${supportScope}.send`,
    defaultMessage: 'Envoyer',
    en: 'Send',
    it: 'Invia',
    es: 'Enviar',
    de: 'Senden',
    nl: 'Verzenden',
  },
  messageReceived: {
    id: `${supportScope}.messageReceived`,
    defaultMessage: 'Message reçu 5/5',
    en: 'Message received 5/5',
    it: 'Messaggio ricevuto 5/5',
    es: 'Mensaje recibido 5/5',
    de: 'Nachricht erhalten 5/5',
    nl: 'Bericht ontvangen 5/5',
  },
  messageSentConfirmation: {
    id: `${supportScope}.messageSentConfirmation`,
    defaultMessage:
      'Votre message a bien été envoyé. Nous allons revenir vers vous le plus vite possible.',
    en: 'Your message has been sent. We will get back to you as soon as possible.',
    it: 'Il tuo messaggio è stato inviato. Ti risponderemo il prima possibile.',
    es: 'Su mensaje ha sido enviado. Nos pondremos en contacto con usted lo antes posible.',
    de: 'Ihre Nachricht wurde gesendet. Wir werden uns so schnell wie möglich bei Ihnen melden.',
    nl: 'Uw bericht is verzonden. We zullen zo snel mogelijk contact met u opnemen.',
  },
  seeYouSoon: {
    id: `${supportScope}.seeYouSoon`,
    defaultMessage: 'À très vite ! 👋',
    en: 'See you soon ! 👋',
    it: 'A presto ! 👋',
    es: '¡Hasta pronto ! 👋',
    de: 'Bis bald ! 👋',
    nl: 'Tot snel ! 👋',
  },
  orderIdPlaceHolder: {
    id: `${supportScope}.examplePlaceholder`,
    defaultMessage: 'exemple: d4e7370c88e54091',
    en: 'example: d4e7370c88e54091',
    it: 'esempio: d4e7370c88e54091',
    es: 'ejemplo: d4e7370c88e54091',
    de: 'Beispiel: d4e7370c88e54091',
    nl: 'voorbeeld: d4e7370c88e54091',
  },
};

export default defineMessages(supportMessages);
