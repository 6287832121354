import { fromJS } from 'immutable';
import {
  LOADING,
  LOGIN_EMAIL,
  LOGIN_ERROR_ID,
  LOGIN_ORDER_ID,
  SET_LOADING_STATUS,
  SET_LOGIN_EMAIL,
  SET_LOGIN_ERROR_ID,
  SET_LOGIN_ORDER_ID,
} from './constants';

export const initialState = fromJS({
  [LOADING]: false,
  [LOGIN_EMAIL]: '',
  [LOGIN_ORDER_ID]: '',
  [LOGIN_ERROR_ID]: null,
});

function loginReducer(state = initialState, action) {
  switch (action.type) {
    case SET_LOADING_STATUS:
      return state.set(LOADING, fromJS(action.loadingStatus));
    case SET_LOGIN_EMAIL:
      return state.set(LOGIN_EMAIL, fromJS(action.email));
    case SET_LOGIN_ORDER_ID:
      return state.set(LOGIN_ORDER_ID, fromJS(action.orderId));
    case SET_LOGIN_ERROR_ID:
      return state.set(LOGIN_ERROR_ID, fromJS(action.errorId));
    default:
      return state;
  }
}

export default loginReducer;
