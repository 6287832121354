/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import fetchIntercept from 'fetch-intercept';
import { Fragment, useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Helmet } from 'react-helmet';
import { Redirect, Route, Switch } from 'react-router-dom';
import styled from 'styled-components/macro';
import tw from 'tailwind.macro';
import ErrorMessage from '../../components/ErrorMessage';
import Footer from '../../components/Footer';
import { Maintenance } from '../../components/Maintenance';
import { checkMaintenance } from '../../components/Maintenance/utils';
import { MobileHeader } from '../../components/MobileHeader';
import { shouldDisplayMobileHeader } from '../../components/MobileHeader/utils';
import { AdminContext } from '../../contexts/Admin';
import GlobalStyle from '../../global-styles';
import { isCrawler } from '../../helpers';
import {
  HTTP_STATUS_CODES,
  PAYLINE_CSS_URL,
  PAYLINE_JS_URL,
} from '../../helpers/constants';
import About from '../About/Lazy';
import Admin from '../Admin/Lazy';
import ApiStatus from '../Admin/View/Panel/ApiStatus/Lazy';
import City from '../City/Lazy';
import Company from '../Company/Lazy';
import Confirmation from '../Confirmation/Lazy';
import Faq from '../Faq/Lazy';
import FeatureFlags from '../FeatureFlags/Lazy';
import Header from '../Header';
import HomePage from '../HomePage/Lazy';
import HowItWorks from '../howItWorks/Lazy';
import LegalMention from '../LegalMention/Lazy';
import Login from '../Login/Lazy';
import Mountain from '../Mountain/Lazy';
import NotFoundPage from '../NotFoundPage/Lazy';
import Orders from '../Orders/Lazy';
import Partners from '../Partners/Lazy';
import PassengersInfo from '../PassengersInfoz/Lazyy';
import Payment from '../Payment/Lazy';
import PaymentLink from '../PaymentLink/Lazy';
import PaymentLinkConfirmation from '../PaymentLink/paymentLinkConfirmation';
import Privacy from '../Privacy/Lazy';
import CompaniesConfiguration from '../ProvidersConfiguration/Lazy';
import RedirectPage from '../Redirect/Lazy';
import RedirectAppPage from '../RedirectApp/Lazy';
import Results from '../Results/Lazy';
import Search from '../Search/Lazy';
import Support from '../Support';
import TermsOfSales from '../TermsOfSales/Lazy';
import {
  DEFAULT_DESCRIPTION,
  DEFAULT_TITLE,
  META_DEFAULT_IMAGE,
  NAVIGATION,
  TITLE_SUFFIX,
} from './constants';

const MainContainer = styled.div`
  ${tw`relative  flex flex-col flex-auto
    h-full w-full
    my-0 mx-auto
    bg-white`};
  max-width: 1440px;
`;

export const App = () => {
  const [isKeyboardOpen, setIsKeyboardOpen] = useState(false);
  /**
   * @description Defines a global function that represents if the mobile virtual keyboard is open
   * @returns {void}
   */
  useEffect(() => {
    window.tictactrip = {
      setIsKeyboardOpen,
    };
  }, []);

  const [isSystemUnderMaintenance, setIsSystemUnderMaintenance] =
    useState(false);

  useEffect(() => {
    const unregister = fetchIntercept.register({
      response: function (response) {
        switch (response.status) {
          case HTTP_STATUS_CODES.SERVICE_UNAVAILABLE: {
            setIsSystemUnderMaintenance(true);
            break;
          }
          default: {
            break;
          }
        }

        return response;
      },
    });

    // Check if the API is under maintenance
    (async () => {
      const maintenance = await checkMaintenance();

      setIsSystemUnderMaintenance(maintenance);
    })();

    return () => {
      unregister();
    };
  }, []);

  return (
    <Fragment>
      <Helmet titleTemplate={`%s${TITLE_SUFFIX}`}>
        <title>{DEFAULT_TITLE}</title>
        <meta content={DEFAULT_DESCRIPTION} name="description" />
        <meta content="#353746" name="theme-color" />
        <meta content="website" property="og:type" />
        <meta content={DEFAULT_TITLE + TITLE_SUFFIX} property="og:title" />
        <meta content={DEFAULT_DESCRIPTION} property="og:description" />
        <meta content={META_DEFAULT_IMAGE} property="og:image" />
        {!isCrawler(navigator.userAgent) && (
          <script defer src={PAYLINE_JS_URL} />
        )}
        {!isCrawler(navigator.userAgent) && (
          <link href={PAYLINE_CSS_URL} rel="stylesheet" />
        )}
      </Helmet>

      {isSystemUnderMaintenance &&
        !window.location.pathname.includes(NAVIGATION.ADMIN) && (
          <Maintenance
            setIsSystemUnderMaintenance={setIsSystemUnderMaintenance}
          />
        )}

      <Header />

      <MainContainer>
        <ErrorBoundary fallbackRender={ErrorMessage}>
          <Switch>
            <Route component={HomePage} exact path={NAVIGATION.HOME} />
            <Redirect
              exact
              from={`${NAVIGATION.RESULTS}/:id/outward`}
              to={NAVIGATION.HOME}
            />
            <Route
              path={NAVIGATION.RESULTS}
              render={(props) => <Results {...props} />}
            />
            <Route
              component={RedirectAppPage}
              exact
              path={NAVIGATION.REDIRECT_APP}
            />
            <Route
              path={NAVIGATION.REDIRECT}
              render={(props) => <RedirectPage {...props} />}
            />

            <Route
              component={PassengersInfo}
              exact
              path={`${NAVIGATION.INFO}/:cartId`}
            />
            <Route component={Payment} exact path={NAVIGATION.PAYMENT} />
            <Route
              component={Payment}
              exact
              path={`${NAVIGATION.PAYMENT}/:cartId`}
            />
            <Route
              exact
              path={`${NAVIGATION.CONFIRMATION}/:orderId`}
              render={(props) => (
                <AdminContext.Consumer>
                  {(admin) => <Confirmation {...props} admin={admin} />}
                </AdminContext.Consumer>
              )}
            />
            <Route component={Partners} exact path={NAVIGATION.PARTNERS} />
            <Route component={LegalMention} exact path={NAVIGATION.LEGAL} />
            <Route
              component={TermsOfSales}
              exact
              path={NAVIGATION.TERMS_OF_SALE}
            />
            <Route component={About} exact path={NAVIGATION.ABOUT} />
            <Route
              component={HowItWorks}
              exact
              path={NAVIGATION.HOW_IT_WORKS}
            />
            <Route component={Faq} exact path={NAVIGATION.FAQ} />
            <Route component={Faq} exact path={`${NAVIGATION.FAQ}/:pageId`} />
            <Route component={Privacy} exact path={NAVIGATION.PRIVACY} />
            <Route component={City} path={`${NAVIGATION.CITY}/:id`} />
            <Route component={Company} path={`${NAVIGATION.COMPANY}/:id`} />
            <Route
              path={`${NAVIGATION.SEARCH}/:origin/:destination/:pageType?`}
              render={(props) => (
                <AdminContext.Consumer>
                  {(admin) => <Search {...props} admin={admin} />}
                </AdminContext.Consumer>
              )}
            />
            <Route component={Mountain} exact path={`${NAVIGATION.MOUNTAIN}`} />
            <Route
              component={Mountain}
              path={`${NAVIGATION.MOUNTAIN}/:direction/:region`}
            />
            <Redirect exact from={NAVIGATION.STATIONS} to={NAVIGATION.HOME} />
            <Redirect
              from={`${NAVIGATION.STATIONS}/*-:gpuid`}
              to={NAVIGATION.HOME}
            />
            <Route
              exact
              path={NAVIGATION.ADMIN}
              render={(props) => <Admin {...props} />}
            />
            <Route
              exact
              path={`${NAVIGATION.ADMIN}${NAVIGATION.WIDGET_BOOKING}`}
              render={(props) => <Admin {...props} />}
            />
            <Route component={Login} path={NAVIGATION.LOGIN} />
            <Route
              component={PaymentLink}
              path={`${NAVIGATION.ADMIN}${NAVIGATION.PAYMENT_LINK}`}
            />
            <Route
              component={PaymentLinkConfirmation}
              path={`${NAVIGATION.PAYMENT_LINK}`}
            />
            <Route component={Orders} path={NAVIGATION.ORDERS} />
            <Route
              component={CompaniesConfiguration}
              path={`${NAVIGATION.ADMIN}${NAVIGATION.PROVIDERS_CONFIGURATION}`}
            />
            <Route component={Support} path={`${NAVIGATION.SUPPORT}`} />
            <Route
              component={ApiStatus}
              path={`${NAVIGATION.ADMIN}${NAVIGATION.STATUS}`}
            />
            <Route
              component={FeatureFlags}
              path={`${NAVIGATION.ADMIN}${NAVIGATION.FEATURE_FLAGS}`}
            />
            <Route component={NotFoundPage} path="" />
          </Switch>
        </ErrorBoundary>
      </MainContainer>

      {shouldDisplayMobileHeader() && (
        <MobileHeader isKeyboardOpen={isKeyboardOpen} />
      )}

      <Footer />

      <GlobalStyle />
    </Fragment>
  );
};

export default App;
