import PropTypes from 'prop-types';
import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import tw from 'tailwind.macro';

const LogosContainer = styled.div`
  ${tw`flex items-center justify-center`};
  gap: 1rem;
  grid-area: logos;

  @media (min-width: 768px) {
    gap: 2rem;
    justify-self: center;
  }
`;

const LogoLink = styled(Link)`
  ${tw`flex items-center justify-center`};
`;

const Logo = styled.img`
  ${tw`flex w-full`};

  object-fit: contain;
  height: 36px;
  max-width: 130px;

  @media (min-width: 768px) {
    height: 51px;
  }
`;

const LogoSeparator = styled.p`
  ${tw`text-center w-abs1`}
`;

const Logos = ({ logo, partnerLogo }) => (
  <LogosContainer partnerLogo={partnerLogo}>
    {partnerLogo && (
      <Fragment>
        <LogoLink to="/">
          <Logo
            alt="logo"
            sizes="(max-width: 768px) 36px, 51px"
            src={partnerLogo}
            width={130}
          />
        </LogoLink>
        <LogoSeparator>
          <strong>X</strong>
        </LogoSeparator>
      </Fragment>
    )}
    <LogoLink to="/">
      <Logo
        alt="logo"
        sizes="(max-width: 768px) 36px, 51px"
        src={logo}
        width={130}
      />
    </LogoLink>
  </LogosContainer>
);

Logos.propTypes = {
  logo: PropTypes.string,
  partnerLogo: PropTypes.string,
};

export { Logo, Logos };
