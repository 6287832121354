/*
 * About Messages
 *
 * This contains all the text for the About container.
 */

import { defineMessages } from 'react-intl';

export const aboutScope = 'app.containers.About';

export const aboutMessages = {
  metaDescription: {
    id: `${aboutScope}.metaDescription`,
    defaultMessage:
      'Découvrez l’équipe de Tictactrip, notre histoire ainsi que nos accomplissements ! Rejoignez-nous 😉',
    en: ' Discover the Tictactrip team, our history and our achievements! Join us 😉',
    es: ' ¡Descubre el equipo de Tictactrip, nuestra historia y nuestros logros! Únete a nosotros 😉',
    de: ' Dekorieren Sie das Tictactrip-Team, unsere Geschichte und unsere Leistungen! Begleiten Sie uns 😉',
    it: 'Scopri il team di Tictactrip, la nostra storia e i nostri successi! Unisciti a noi 😉',
    nl: 'Ontdek het Tictactrip team, onze geschiedenis en onze prestaties! Sluit je bij ons aan 😉 ',
  },
  title: {
    id: `${aboutScope}.title`,
    defaultMessage: "Salut nous c'est ",
    en: "Hi, it's us, ",
    it: 'Ciao, siamo noi, ',
    es: 'Hola, somos nosotros, ',
    de: 'Hallo, wir sind es, ',
    nl: 'Hoi, wij zijn het, ',
  },
  title2: {
    id: `${aboutScope}.title2`,
    defaultMessage: 'Tictactrip',
    en: 'Tictactrip',
    it: 'Tictactrip',
    es: 'Tictactrip',
    de: 'Tictactrip',
    nl: 'Tictactrip',
  },
  lowCarbonTransportation: {
    id: `${aboutScope}.lowCarbonTransportation`,
    defaultMessage:
      'On est là pour faire du transport bas-carbone le 1er choix. Plus de destinations, plus de transporteurs et moins de galères.',
    en: 'We are here to make low-carbon transportation the first choice. More destinations, more carriers, and fewer hassles.',
    it: 'Siamo qui per rendere il trasporto a basso tenore di carbonio la prima scelta. Più destinazioni, più vettori e meno problemi.',
    es: 'Estamos aquí para hacer del transporte con bajas emisiones de carbono la primera opción. Más destinos, más transportistas y menos problemas.',
    de: 'Wir sind hier, um den klimafreundlichen Transport zur ersten Wahl zu machen. Mehr Ziele, mehr Transportunternehmen und weniger Probleme.',
    nl: 'Wij zijn er om van duurzaam transport de eerste keuze te maken. Meer bestemmingen, meer vervoerders en minder gedoe.',
  },
  putTransportationInMotion: {
    id: `${aboutScope}.putTransportationInMotion`,
    defaultMessage: 'Mettre le transport en mouvement.',
    en: 'Put transportation in motion.',
    it: 'Mettere in moto il trasporto.',
    es: 'Poner en movimiento el transporte.',
    de: 'Den Transport in Bewegung setzen.',
    nl: 'Breng het transport in beweging.',
  },

  theLargestCatalog: {
    id: `${aboutScope}.theLargestCatalog`,
    defaultMessage: `
      <strong>
        Tictactrip est l'application avec le plus grand catalogue en France
      </strong>
      (et bientôt en Europe) de destinations et de transporteurs de trains,
      bus et covoiturage. Plus large que la S... car nous distribuons tous
      les acteurs concurrents. En multipliant les partenaires de transports
      et en améliorant notre technologie nous proposons le plus large
      éventail de possibilités.`,
    en: `
      <strong>
        Tictactrip is the application with the largest catalog in France
      </strong>
      (and soon in Europe) of destinations and carriers for trains,
      buses, and carpooling. Larger than the S... as we distribute all
      competing players. By multiplying transport partners
      and improving our technology, we offer the widest
      range of possibilities.`,
    it: `
      <strong>
        Tictactrip è l'applicazione con il catalogo più ampio in Francia
      </strong>
      (e presto in Europa) di destinazioni e vettori per treni,
      autobus e carpooling. Più ampio della S... perché distribuiamo tutti
      gli attori concorrenti. Moltiplicando i partner di trasporto
      e migliorando la nostra tecnologia, offriamo la gamma più ampia
      di possibilità.`,
    es: `
      <strong>
        Tictactrip es la aplicación con el catálogo más grande en Francia
      </strong>
      (y próximamente en Europa) de destinos y transportistas de trenes,
      autobuses y coche compartido. Más grande que el S... ya que distribuimos todos
      los actores competidores. Multiplicando los socios de transporte
      y mejorando nuestra tecnología, ofrecemos la gama más amplia
      de posibilidades.`,
    de: `
      <strong>
        Tictactrip ist die Anwendung mit dem größten Katalog in Frankreich
      </strong>
      (und bald in Europa) für Ziele und Transportunternehmen von Zügen,
      Bussen und Mitfahrgelegenheiten. Größer als die S..., weil wir alle
      konkurrierenden Anbieter vertreiben. Durch die Vermehrung von Transportpartnern
      und die Verbesserung unserer Technologie bieten wir die breiteste
      Palette von Möglichkeiten an.`,
    nl: `
      <strong>
        Tictactrip is de applicatie met het grootste aanbod in Frankrijk
      </strong>
      (en binnenkort in Europa) van bestemmingen en vervoerders voor treinen,
      bussen en carpoolen. Groter dan de S... omdat we alle
      concurrerende spelers distribueren. Door het vermenigvuldigen van transportpartners
      en het verbeteren van onze technologie bieden we het breedste
      scala aan mogelijkheden aan.`,
  },
  //-
  betterForTheWallet: {
    id: `${aboutScope}.betterForTheWallet`,
    defaultMessage: 'Meilleur pour le portefeuille.',
    en: 'Better for the wallet.',
    it: 'Migliore per il portafoglio.',
    es: 'Mejor para la billetera.',
    de: 'Besser für die Brieftasche.',
    nl: 'Beter voor de portemonnee.',
  },
  toPreferTheTrainOrBus: {
    id: `${aboutScope}.toPreferTheTrainOrBus`,
    defaultMessage:
      "Pour privilégier le train ou le bus, encore fallait-il qu'ils existent et que les prix soient raisonnables. Avec la loi Macron sur les bus en 2017 et l'ouverture à la concurrence du rail, c'est chose faite.",
    en: "To prefer the train or bus, they needed to exist and have reasonable prices. With the Macron law on buses in 2017 and the opening up of rail competition, it's done.",
    it: "Per preferire il treno o l'autobus, dovevano esistere e avere prezzi ragionevoli. Con la legge Macron sugli autobus nel 2017 e l'apertura alla concorrenza ferroviaria, è fatto.",
    es: 'Para preferir el tren o el autobús, necesitaban existir y tener precios razonables. Con la ley Macron sobre los autobuses en 2017 y la apertura a la competencia ferroviaria, está hecho.',
    de: 'Um den Zug oder den Bus zu bevorzugen, mussten sie existieren und vernünftige Preise haben. Mit dem Macron-Gesetz über Busse im Jahr 2017 und der Öffnung des Wettbewerbs im Schienenverkehr ist dies erledigt.',
    nl: 'Om de voorkeur te geven aan de trein of bus, moesten ze bestaan en redelijke prijzen hebben. Met de wet Macron over bussen in 2017 en de opening van de concurrentie op het spoor is dit geregeld.',
  },
  byBringingMaximumOfCarriers: {
    id: `${aboutScope}.byBringingMaximumOfCarriers`,
    defaultMessage:
      "En regroupant un maximum de transporteurs, nous participons à démocratiser l'utilisation de nouveaux transporteurs sur le territoire. Ces transporteurs apportent plus de solutions de liaisons, d'horaires et ",
    en: 'By bringing together a maximum of carriers, we contribute to democratizing the use of new carriers in the territory. These carriers provide more connection solutions, schedules, and ',
    it: "Riunendo un massimo di vettori, contribuiamo a democratizzare l'uso di nuovi vettori sul territorio. Questi vettori forniscono più soluzioni di collegamento, orari e ",
    es: 'Al reunir a un máximo de transportistas, contribuimos a democratizar el uso de nuevos transportistas en el territorio. Estos transportistas ofrecen más soluciones de conexión, horarios y ',
    de: 'Durch die Zusammenführung einer maximalen Anzahl von Transportunternehmen tragen wir dazu bei, die Nutzung neuer Transportunternehmen im Gebiet zu demokratisieren. Diese Transportunternehmen bieten mehr Verbindungslösungen, Fahrpläne und ',
    nl: 'Door een maximum aantal vervoerders samen te brengen, dragen we bij aan het democratiseren van het gebruik van nieuwe vervoerders in het gebied. Deze vervoerders bieden meer verbindingsopties, dienstregelingen en ',
  },
  byBringingMaximumOfCarriers2: {
    id: `${aboutScope}.byBringingMaximumOfCarriers2`,
    defaultMessage:
      'contribuent à baisser les prix des billets de trains ou de bus.',
    en: 'contribute to lowering the prices of train or bus tickets.',
    it: "contribuiscono a abbassare i prezzi dei biglietti del treno o dell'autobus.",
    es: 'contribuyen a reducir los precios de los billetes de tren o autobús.',
    de: 'tragen dazu bei, die Preise für Zug- oder Busfahrkarten zu senken.',
    nl: 'dragen bij aan het verlagen van de prijzen van trein- of buskaartjes.',
  },
  betterForThePlanet: {
    id: `${aboutScope}.betterForThePlanet`,
    defaultMessage: 'Mieux pour la planète.',
    en: 'Better for the planet.',
    it: 'Migliore per il pianeta.',
    es: 'Mejor para el planeta.',
    de: 'Besser für den Planeten.',
    nl: 'Beter voor de planeet.',
  },

  lowCarbonChoice: {
    id: `${aboutScope}.lowCarbonChoice`,
    defaultMessage:
      "Le train consomme 30 à 50 fois moins que l'avion et 8 fois moins que la voiture individuelle.",
    en: 'The train consumes 30 to 50 times less than the plane and 8 times less than the individual car.',
    it: "Il treno consuma da 30 a 50 volte meno dell'aereo e 8 volte meno dell'auto individuale.",
    es: 'El tren consume de 30 a 50 veces menos que el avión y 8 veces menos que el automóvil individual.',
    de: 'Der Zug verbraucht 30 bis 50 Mal weniger als das Flugzeug und 8 Mal weniger als das individuelle Auto.',
    nl: 'De trein verbruikt 30 tot 50 keer minder dan het vliegtuig en 8 keer minder dan de individuele auto.',
  },
  lowCarbonChoice2: {
    id: `${aboutScope}.lowCarbonChoice2`,
    defaultMessage:
      "Pourtant, il reste souvent difficile à privilégier : plus long, plus cher, moins de dessertes. Accélérer le développement du rail et des concurrents de transports améliorent le réseau.<br /><strong>Le choix est l'ultime moyen de faire du transport bas-carbone la meilleure option.</strong>",
    en: 'Yet, it is often challenging to prioritize: longer, more expensive, fewer routes. Accelerating the development of rail and transport competitors improves the network.<br /><strong>Choice is the ultimate way to make low-carbon transportation the best option.</strong>',
    it: 'Tuttavia, spesso è difficile privilegiarlo: più lungo, più costoso, meno tratte. Accelerare lo sviluppo del treno e dei concorrenti nel trasporto migliora la rete.<br /><strong>La scelta è il mezzo finale per rendere il trasporto a basso tenore di carbonio la migliore opzione.</strong>',
    es: 'Sin embargo, a menudo es difícil priorizarlo: más largo, más caro, menos rutas. Acelerar el desarrollo del tren y de los competidores en el transporte mejora la red.<br /><strong>La elección es la última manera de hacer que el transporte con bajas emisiones de carbono sea la mejor opción.</strong>',
    de: 'Dennoch ist es oft schwer zu priorisieren: länger, teurer, weniger Strecken. Die Beschleunigung der Entwicklung von Schienenverkehr und Transportkonkurrenten verbessert das Netzwerk.<br /><strong>Die Wahl ist der ultimative Weg, um klimafreundlichen Transport zur besten Option zu machen.</strong>',
    nl: 'Toch is het vaak moeilijk om het te prioriteren: langer, duurder, minder routes. Het versnellen van de ontwikkeling van het spoor en de concurrenten in het transport verbetert het netwerk.<br /><strong>Keuze is de ultieme manier om duurzaam transport de beste optie te maken.</strong>',
  },
  forEveryone: {
    id: `${aboutScope}.forEveryone`,
    defaultMessage: 'Pour vous, pour nous, pour tout le monde.',
    en: 'For you, for us, for everyone.',
    it: 'Per te, per noi, per tutti.',
    es: 'Para ti, para nosotros, para todos.',
    de: 'Für dich, für uns, für alle.',
    nl: 'Voor jou, voor ons, voor iedereen.',
  },
  weSellTickets: {
    id: `${aboutScope}.weSellTickets`,
    defaultMessage:
      "Nous ne sommes pas des machines. On vend des billets de trains, de bus et de covoit d'humains à humains. On les vend de la même façon qu'on aime les acheter : sans galères.<br /><br />On est pas parfait, vous non plus et c'est pas grave. On fait de notre mieux !",
    en: "We are not machines. We sell tickets for trains, buses, and ridesharing from humans to humans. We sell them the same way we like to buy them: without hassles.<br /><br />We are not perfect, neither are you, and that's okay. We're doing our best!",
    it: 'Non siamo macchine. Vendiamo biglietti per treni, autobus e carpooling da umani a umani. Li vendiamo nello stesso modo in cui ci piace comprarli: senza problemi.<br /><br />Non siamo perfetti, neanche voi, e va bene così. Facciamo del nostro meglio!',
    es: 'No somos máquinas. Vendemos boletos de trenes, autobuses y viajes compartidos de humanos a humanos. Los vendemos de la misma manera que nos gusta comprarlos: sin complicaciones.<br /><br />No somos perfectos, ustedes tampoco, y está bien. ¡Estamos haciendo nuestro mejor esfuerzo!',
    de: 'Wir sind keine Maschinen. Wir verkaufen Tickets für Züge, Busse und Mitfahrgelegenheiten von Menschen zu Menschen. Wir verkaufen sie genauso, wie wir sie gerne kaufen: ohne Probleme.<br /><br />Wir sind nicht perfekt, ihr auch nicht, und das ist in Ordnung. Wir geben unser Bestes!',
    nl: 'We zijn geen machines. We verkopen trein-, bus- en carpoolingtickets van mens tot mens. We verkopen ze op dezelfde manier als we ze graag kopen: zonder gedoe.<br /><br />We zijn niet perfect, jullie ook niet, en dat is prima. We doen ons best!',
  },
  quote: {
    id: `${aboutScope}.quote`,
    defaultMessage:
      "Étonnée de trouver le bon train tout de suite, d'étudier les tarifs simplement, de payer avec des explications assez claires, humoristiques et qui n'engagent à rien mais filent la pêche.",
    en: "Amazed to find the right train immediately, to study the fares simply, to pay with explanations that are clear enough, humorous, and don't commit to anything but boost morale.",
    it: 'Stupita di trovare subito il treno giusto, di studiare le tariffe in modo semplice, di pagare con spiegazioni abbastanza chiare, umoristiche e che non impegnano a nulla ma danno il buonumore.',
    es: 'Asombrada de encontrar el tren adecuado de inmediato, de estudiar las tarifas de manera simple, de pagar con explicaciones lo suficientemente claras, humorísticas y que no comprometen nada pero dan ánimo.',
    de: 'Erstaunt, sofort den richtigen Zug zu finden, die Tarife einfach zu studieren, mit Erklärungen zu bezahlen, die klar genug, humorvoll und nicht verpflichtend, sondern motivierend sind.',
    nl: 'Verbaasd om meteen de juiste trein te vinden, eenvoudig de tarieven te bestuderen, te betalen met uitleg die duidelijk genoeg is, humoristisch en niets verplicht maar wel de moraal opkrikt.',
  },
  quoteAuthor: {
    id: `${aboutScope}.quoteAuthor`,
    defaultMessage: '- Cécilia, 11 décembre 2023',
    en: '- Cécilia, December 11, 2023',
    it: '- Cécilia, 11 dicembre 2023',
    es: '- Cécilia, 11 de diciembre de 2023',
    de: '- Cécilia, 11. Dezember 2023',
    nl: '- Cécilia, 11 december 2023',
  },
  weAreHereForYou: {
    id: `${aboutScope}.weAreHereForYou`,
    defaultMessage: 'On est là, ',
    en: 'We are here, ',
    it: 'Siamo qui, ',
    es: 'Estamos aquí, ',
    de: 'Wir sind hier, ',
    nl: 'We zijn hier, ',
  },
  weAreHereForYou2: {
    id: `${aboutScope}.weAreHereForYou2`,
    defaultMessage: 'pour vous.',
    en: 'for you.',
    it: 'per te.',
    es: 'para ti.',
    de: 'für dich.',
    nl: 'voor jou.',
  },
  ifYouHaveAnyQuestions: {
    id: `${aboutScope}.ifYouHaveAnyQuestions`,
    defaultMessage:
      "Si vous avez des questions, notre service client est dispo toute la semaine. On répond hyper vite (un peu moins à l'heure du goûter).",
    en: 'If you have any questions, our customer service is available all week. We respond super fast (a bit less during snack time).',
    it: "Se avete domande, il nostro servizio clienti è disponibile tutta la settimana. Rispondiamo molto velocemente (un po' meno durante la merenda).",
    es: 'Si tienes alguna pregunta, nuestro servicio de atención al cliente está disponible toda la semana. Respondemos súper rápido (un poco menos a la hora de la merienda).',
    de: 'Wenn Sie Fragen haben, steht Ihnen unser Kundenservice die ganze Woche zur Verfügung. Wir antworten super schnell (etwas weniger während der Snackzeit).',
    nl: 'Als je vragen hebt, is onze klantenservice de hele week beschikbaar. We reageren super snel (iets minder tijdens het tussendoortje).',
  },
  contactUs: {
    id: `${aboutScope}.contactUs`,
    defaultMessage: 'Nous écrire',
    en: 'Contact Us',
    it: 'Contattaci',
    es: 'Contáctanos',
    de: 'Kontaktiere uns',
    nl: 'Neem contact op met ons',
  },
};

export default defineMessages(aboutMessages);
