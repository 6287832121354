import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import tw from 'tailwind.macro';
import Navigation from './Navigation';

const Container = styled.div`
  ${tw`fixed w-full h-full
  bg-white
  lg:hidden`};
  z-index: -1;
`;

export const Categories = styled.div`
  ${tw`w-full
    flex flex-col
    p-def`};
`;

const Drawer = ({ isMobileApp, locale, selectTab }) => (
  <Container>
    <Navigation
      Disposition={Categories}
      isMobileApp={isMobileApp}
      locale={locale}
      selectTab={selectTab}
    />
  </Container>
);

Drawer.propTypes = {
  isMobileApp: PropTypes.bool,
  locale: PropTypes.string,
  selectTab: PropTypes.func,
};

export default Drawer;
