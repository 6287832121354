import styled from 'styled-components/macro';
import tw from 'tailwind.macro';

const NavBar = styled.div`
  ${tw`flex justify-between items-center
  	w-full
  	px-def
  	md:flex-row`};
  max-width: 1080px;
`;

export default NavBar;
