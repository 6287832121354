/*
 *
 * Header constants
 *
 */

export const SELECT_TAB = 'app/Header/SELECT_TAB';
export const HANDLE_DRAWER = 'app/Header/HANDLE_DRAWER';
export const SET_IS_NAVBAR_BACK_BUTTON_HIDDEN =
  'app/Header/SET_IS_NAVBAR_BACK_BUTTON_HIDDEN';

export const REDUCER_DISPLAY_MODAL = 'displayModal';
export const REDUCER_DISPLAY_DRAWER = 'displayDrawer';
export const REDUCER_MODAL_CONTENT = 'modalContent';
export const REDUCER_SELECTED_TAB = 'selectedTab';
export const REDUCER_IS_NAVBAR_BACK_BUTTON_HIDDEN = 'isNavbarBackButtonHidden';

export const LANGUAGES = 'languages';
export const CONTACT = 'contact';
export const ORDERS = 'orders';

export const TOP_TRAVELS = 'TOP_TRAVELS';
export const TRAIN = 'TRAIN';
export const BUS = 'BUS';
export const CARPOOLING = 'CARPOOLING';
export const TRANSPORTS = 'TRANSPORTS';
