/*
 *
 * Passengers Constants
 *
 */

import PropTypes from 'prop-types';

export const ADD_PASSENGER = 'app/Passengers/ADD_PASSENGER';
export const UPDATE_PASSENGER_CATEGORY =
  'app/Passengers/UPDATE_PASSENGER_CATEGORY';
export const DELETE_PASSENGER = 'app/Passengers/DELETE_PASSENGER';
export const ADD_DISCOUNT_CARD = 'app/Passengers/ADD_DISCOUNT_CARD';
export const DELETE_DISCOUNT_CARD = 'app/Passengers/DELETE_DISCOUNT_CARD';
export const UPDATE_PASSENGER_AGE = 'app/Passengers/UPDATE_PASSENGER_AGE';
export const HANDLE_URL_PASSENGERS = 'app/Passengers/HANDLE_URL_PASSENGERS';
export const REPLACE_PASSENGERS = 'app/Passengers/REPLACE_PASSENGERS';
export const REPLACE_CUSTOMER = 'app/Passengers/REPLACE_CUSTOMER';

export const ADULT = 'adults';
export const SENIOR = 'seniors';
export const YOUNG = 'youths';

export const PASSENGERS = 'passengers';
export const CUSTOMER = 'customer';
export const TITLE = 'title';
export const FIRST_NAME = 'firstName';
export const LAST_NAME = 'lastName';
export const BIRTH_DAY = 'birthDay';
export const BIRTH_MONTH = 'birthMonth';
export const PHONE_NUMBER = 'phoneNumber';
export const MAIL = 'mail';
export const BIRTH_YEAR = 'birthYear';
export const IS_CUSTOMER = 'isCustomer';
export const CUSTOMER_ID = 'customerId';
export const DISCOUNT_CARD_ID = 'discountCardId';
export const IS_SUBSCRIBED_TO_NEWSLETTER = 'isSubscribedToNewsletter';
export const DOCUMENT_COUNTRY_CODE = 'documentCountryCode';
export const DOCUMENT_NUMBER = 'documentNumber';
export const DOCUMENT_EXPIRATION_DATE = 'documentExpirationDate';
export const DOCUMENT_TYPE = 'documentType';

export const SET_TITLE = 'app/Passengers/SET_PASSENGER_TITLE';
export const SET_FIRST_NAME = 'app/Passengers/SET_PASSENGER_FIRST_NAME';
export const SET_LAST_NAME = 'app/Passengers/SET_PASSENGER_LAST_NAME';
export const SET_BIRTH_DAY = 'app/Passengers/SET_PASSENGER_BIRTH_DAY';
export const SET_BIRTH_MONTH = 'app/Passengers/SET_PASSENGER_BIRTH_MONTH';
export const SET_BIRTH_YEAR = 'app/Passengers/SET_PASSENGER_BIRTH_YEAR';
export const SUBSCRIBE_TO_NEWSLETTER = 'app/Passengers/SUBSCRIBE_TO_NEWSLETTER';
export const SET_PHONE_NUMBER = 'app/Passengers/SET_PHONE_NUMBER';
export const SET_CUSTOMER_MAIL = 'app/Passengers/SET_CUSTOMER_MAIL';
export const SET_CUSTOMER_ID = 'app/Passengers/SET_CUSTOMER_ID';
export const RESET_CUSTOMER_ID = 'app/Passengers/RESET_CUSTOMER_ID';
export const SET_DISCOUNT_CARD_ID = 'app/Passengers/SET_DISCOUNT_CARD_ID';
export const SET_DOCUMENT_COUNTRY_CODE = 'app/Passengers/SET_COUNTRY_CODE';
export const SET_DOCUMENT_NUMBER = 'app/Passengers/SET_DOCUMENT_NUMBER';
export const SET_DOCUMENT_EXPIRATION_DATE =
  'app/Passengers/SET_DOCUMENT_EXPIRATION_DATE';
export const SET_DOCUMENT_TYPE = 'app/Passengers/SET_DOCUMENT_TYPE';

export const DEFAULT_ADULT_AGE = 35;
export const DEFAULT_SENIOR_AGE = 60;

// The maximum number of passengers that can be added
export const MAXIMUM_PASSENGERS = 9;

export const passengerPropTypes = PropTypes.shape({
  age: PropTypes.number,
  birthDay: PropTypes.number,
  birthMonth: PropTypes.number,
  birthYear: PropTypes.number,
  category: PropTypes.string,
  discountCardId: PropTypes.string,
  discountCards: PropTypes.array,
  firstName: PropTypes.string,
  id: PropTypes.number,
  isCustomer: PropTypes.bool,
  lastName: PropTypes.string,
  title: PropTypes.string,
});

export const customerPropTypes = PropTypes.shape({
  birthDay: PropTypes.number,
  birthMonth: PropTypes.number,
  birthYear: PropTypes.number,
  firstName: PropTypes.string,
  id: PropTypes.number,
  lastName: PropTypes.string,
  mail: PropTypes.string,
  title: PropTypes.string,
});
