import { lazy, Suspense } from 'react';
import LazyLoading from '../../components/LazyLoading';

export const LazyTermsOfSales = lazy(() =>
  import(/* webpackChunkName: 'TermsOfSales' */ './index'),
);

export default function TermsOfSales() {
  return (
    <Suspense fallback={<LazyLoading />}>
      <LazyTermsOfSales />
    </Suspense>
  );
}
