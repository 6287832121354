import { isMobileApp } from '../../helpers/app';

export const ANDROID_PLATFORM = 'android';
export const IOS_PLATFORM = 'ios';
export const UNKNOWN_PLATFORM = 'unknown';

export const appLink = {
  [ANDROID_PLATFORM]:
    'https://play.google.com/store/apps/details?id=com.tictactrip.comparateur.bus.train.sncf',
  [IOS_PLATFORM]:
    'https://apps.apple.com/us/app/tictactrip-trains-bus-covoit/id1500431872',
};

// Expire in 30 day
const bannerLocalStorageTTL = 2592000000;
const bannerLocalStorageKey = 'appBannerTTL';

/**
 * @description Detect if it is an Android or ios device
 * @returns {string}
 */
export const getMobilePlatform = () => {
  const userAgent = navigator.userAgent;

  if (/android/i.test(userAgent)) {
    return ANDROID_PLATFORM;
  }

  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return IOS_PLATFORM;
  }
  return UNKNOWN_PLATFORM;
};

/**
 * @description Defines the date until which the banner should not be displayed (30 day)
 * @returns {void}
 */
export const setAppBannerCloseTTL = () => {
  if (isCookieEnabled()) {
    localStorage.setItem(
      bannerLocalStorageKey,
      new Date().getDate() + bannerLocalStorageTTL,
    );
  }
};
/**
 * @description Returns if the banner can be open
 * @returns {boolean}
 */
export const canOpenBanner = () => {
  if (isCookieEnabled()) {
    const ttl = localStorage.getItem(bannerLocalStorageKey);

    if (!ttl) {
      return !isMobileApp(navigator.userAgent);
    }

    // Checks if the date until which the banner should not be displayed is past
    if (new Date().getDate() > ttl) {
      localStorage.removeItem(bannerLocalStorageKey);
      return !isMobileApp(navigator.userAgent);
    }
  }

  return false;
};

/**
 * @description Check if cookie is enabled
 * @returns {boolean}
 */
const isCookieEnabled = () => navigator.cookieEnabled;
