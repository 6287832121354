/**
 *
 * ModalBackground
 *
 */

import styled from 'styled-components/macro';
import tw from 'tailwind.macro';

const ModalBackground = styled.div`
  ${tw`fixed pin-t pin-l overflow-y-scroll
  	w-full h-full z-5`};
  background: rgba(53, 55, 70, 0.6);
  display: grid;
  align-items: center;
  justify-items: center;
`;

export default ModalBackground;
