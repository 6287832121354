import styled, { css } from 'styled-components/macro';
import tw from 'tailwind.macro';

const h4Style = css`
  ${tw`leading-h4-m sm:leading-h4 text-h4-m
  	md:text-h4`};
  font-weight: 600;
`;

const H4 = styled.h4`
  ${h4Style}
`;

export { H4 as default, h4Style };
