import '../../../animations/CSSTransition/fadeIn.css';
import '../../../animations/CSSTransition/slideLeft.css';
import PropTypes from 'prop-types';
import { Fragment } from 'react';
import { CSSTransition } from 'react-transition-group';
import styled from 'styled-components/macro';
import tw from 'tailwind.macro';
import { Icon18 } from '../../../components/Icon';
import { EFunnelStep } from '../../../contexts/App/types';
import { languages } from '../../../data/headerLinks';
import ChevronSrc from '../../../images/chevronBoldBlack.svg';
import IconUrlTictactrip from '../../../images/logoTextBlack.svg';
import IconUrlC from '../../../images/logoTextBlackC.svg';
import history from '../../../utils/history';
import { NAVIGATION } from '../../App/constants';
import { LANGUAGES } from '../constants';
import Drawer from './Drawer';
import Funnel from './Funnel';
import Languages from './Languages';
import { Logos } from './Logo';
import MobileExpand from './MobileExpand';
import Modal from './Modal';
import Navigation, { Categories } from './Navigation';
import { Container, Wrapper } from './Wrapper';

const BackButton = styled.button`
  ${tw`flex h-full items-center
  p-0 lg:hidden`}

  grid-area: backButton;

  ${({ isHidden }) => isHidden && 'visibility:hidden'}
`;

const Arrow = styled.img`
  ${Icon18}
  transform: rotate(180deg);
`;

const NavBar = styled.div`
  ${tw`flex justify-between items-center
  	w-full
  	px-def
  	md:flex-row`};

  @media (max-width: 991px) {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 2rem;
    grid-template-areas: 'backButton logos languages';
    grid-template-columns: 1fr 4fr 1fr;
  }

  @media (min-width: 992px) {
    max-width: 1080px;
  }
  position: relative;
`;

// Adapt logo depending on the environment
const LogoUrl =
  window.location.href.indexOf('comparatrip.eu') > -1
    ? IconUrlC
    : IconUrlTictactrip;

const HeaderView = ({
  currentPage,
  displayDrawer,
  displayModal,
  funnelStep,
  handleDrawer,
  isBookable,
  isMobileApp,
  isNavbarBackButtonHidden,
  isWidgetBooking,
  locale,
  modalContent,
  partnerLogo,
  searchUrl,
  selectedTab,
  selectLocale,
  selectTab,
}) => (
  <Container isInFunnel={funnelStep !== EFunnelStep.NONE}>
    <Wrapper>
      <NavBar>
        {currentPage !== NAVIGATION.HOME && history.length > 2 && (
          <BackButton
            id="backButton"
            isHidden={isNavbarBackButtonHidden}
            onClick={() => {
              // Workaround to avoid being redirected to /login after authentication fails on the /orders page.
              if (history.location.pathname === NAVIGATION.LOGIN) {
                if (isMobileApp) {
                  return history.push(NAVIGATION.HOME);
                }

                return history.go(-2);
              }

              history.goBack();
            }}
          >
            <Arrow alt="Go back" src={ChevronSrc} />
          </BackButton>
        )}
        <Logos logo={LogoUrl} partnerLogo={partnerLogo} />
        {!isWidgetBooking && funnelStep === EFunnelStep.NONE && (
          <Fragment>
            <Navigation
              Disposition={Categories}
              isMobileApp={isMobileApp}
              locale={locale}
              selectTab={selectTab}
            />

            <MobileExpand
              displayDrawer={displayDrawer}
              handleDrawer={handleDrawer}
            />
          </Fragment>
        )}
        {isWidgetBooking && funnelStep === EFunnelStep.NONE && (
          <Languages
            category={LANGUAGES}
            flag={languages.find((x) => x.lang === locale).flag}
            isWidgetBooking
            selectTab={selectTab}
          />
        )}
        {funnelStep !== EFunnelStep.NONE && (
          <Funnel
            currentStep={funnelStep}
            isBookable={isBookable}
            partnerLogo={partnerLogo}
            searchUrl={searchUrl}
          />
        )}
      </NavBar>
    </Wrapper>
    <CSSTransition
      appear
      classNames="slideLeft"
      in={!isWidgetBooking && displayDrawer}
      timeout={250}
      unmountOnExit
    >
      <Drawer
        displayDrawer={displayDrawer}
        isMobileApp={isMobileApp}
        locale={locale}
        selectTab={selectTab}
      />
    </CSSTransition>
    <CSSTransition
      classNames="fadeIn"
      in={!isMobileApp && displayModal}
      timeout={250}
      unmountOnExit
    >
      <Modal
        category={selectedTab}
        closeModal={selectTab}
        displayModal={displayModal}
        handleDrawer={handleDrawer}
        links={modalContent}
        selectLocale={selectLocale}
      />
    </CSSTransition>
  </Container>
);

HeaderView.propTypes = {
  currentPage: PropTypes.string,
  displayDrawer: PropTypes.bool,
  displayModal: PropTypes.bool,
  funnelStep: PropTypes.string,
  handleDrawer: PropTypes.func,
  isBookable: PropTypes.bool,
  isMobileApp: PropTypes.bool,
  isNavbarBackButtonHidden: PropTypes.bool,
  isWidgetBooking: PropTypes.bool,
  locale: PropTypes.string,
  modalContent: PropTypes.array,
  partnerLogo: PropTypes.string,
  searchUrl: PropTypes.string,
  selectLocale: PropTypes.func,
  selectTab: PropTypes.func.isRequired,
  selectedTab: PropTypes.string,
};

export default HeaderView;
