import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import tw from 'tailwind.macro';
import ArrowSrc from '../../../images/arrowRightDark.svg';
import { colors } from '../../../styles/constants';
import Flag from './Flag';

const Container = styled.div`
  ${tw`flex items-center justify-between
  lg:pl-abs3`};

  padding: 15px 0;
  justify-self: end;
  grid-area: languages;

  border-bottom: ${(props) =>
    props.isWidgetBooking ? 'none' : `1px solid ${colors.grey200}`};

  @media (min-width: 992px) {
    padding-top: 7px;
    padding-bottom: 7px;
    border-left: ${(props) =>
      props.isWidgetBooking ? 'none' : '1px solid black'};
    border-top: 0;
    border-bottom: 0;
    margin-left: ${(props) => (props.isWidgetBooking ? '0' : '30px')};
  }
`;

const Arrow = styled.img`
  ${tw`mx-abs1
    lg:hidden`};
  display: ${(props) => (props.isHidden ? 'none' : 'block')};
`;

const Dropdown = styled.div`
  ${tw`h-0`};
  border-top: 4px solid black;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  @media (max-width: 992px) {
    display: ${(props) => (props.isVisible ? 'block' : 'none')};
  }
`;

const Languages = ({ category, flag, isWidgetBooking, selectTab }) => (
  <Container
    id="tabLang"
    isWidgetBooking={isWidgetBooking}
    onClick={() => selectTab(category)}
  >
    <Flag alt="change language" height={17} src={flag} width={25.5} />
    <Arrow alt="icon arrow" isHidden={isWidgetBooking} src={ArrowSrc} />
    <Dropdown isVisible={isWidgetBooking} />
  </Container>
);

Languages.propTypes = {
  category: PropTypes.string,
  flag: PropTypes.string,
  isWidgetBooking: PropTypes.bool,
  selectTab: PropTypes.func,
};

export default Languages;
