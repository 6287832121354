import { API_URL } from '../../helpers/constants';

export const loginScope = 'app.containers.Login';

export const LOGIN = 'login';
export const URL_AUTHENTICATION = `${API_URL}/auth`;

export const LOADING = 'isLoading';
export const LOGIN_EMAIL = 'email';
export const LOGIN_ORDER_ID = 'orderId';
export const LOGIN_ERROR_ID = 'errorId';

export const AUTHENTICATE = 'app/Login/AUTHENTICATE';
export const SET_LOADING_STATUS = 'app/Login/SET_LOADING_STATUS';
export const SET_LOGIN_EMAIL = 'app/Login/SET_LOGIN_EMAIL';
export const SET_LOGIN_ORDER_ID = 'app/Login/SET_LOGIN_ORDER_ID';
export const SET_LOGIN_ERROR_ID = 'app/Login/SET_LOGIN_ERROR_ID';

export const LOGIN_ERROR_UNAUTHORIZED = 'unauthorized';

export const PAGE_TITLE = 'PageTitle';
export const LOGIN_CARD_TITLE = 'loginCardTitle';
export const LOGIN_MAIL_TITLE = 'loginMailTitle';
export const LOGIN_MAIL_PLACEHOLDER = 'loginMailPlaceholder';
export const LOGIN_ORDER_ID_TITLE = 'loginOrderIdTitle';
export const LOGIN_ORDER_ID_PLACEHOLDER = 'loginOrderIdPlaceholder';
export const LOGIN_FIND_ORDER_ID = 'loginFindOrderId';
export const LOGIN_SUBMIT = 'loginSubmit';
export const LOGIN_ERROR_EMPTY = 'errorEmpty';
