import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import tw from 'tailwind.macro';
import { Navigation } from './View/Navigation';

const Container = styled.nav`
  ${tw`sticky z-2 bg-white pin-b pin-r pin-l px-medium2 py-medium`};
  padding-bottom: max(var(--size-medium), env(safe-area-inset-bottom));

  box-shadow: 0px 0px 20px rgba(157, 163, 206, 0.5);
  display: ${(props) => (props.isKeyboardOpen ? 'none' : 'block')};
`;

/**
 * @description The mobile app header
 * @returns {JSX.Element}
 */
export const MobileHeader = ({ isKeyboardOpen }) => {
  return (
    <Container isKeyboardOpen={isKeyboardOpen}>
      <Navigation />
    </Container>
  );
};

MobileHeader.propTypes = {
  isKeyboardOpen: PropTypes.bool.isRequired,
};
