import { css } from 'styled-components/macro';

export const hideScrollbar = css`
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const htmlStyle = css`
  line-height: 1.5em;
  font-size: 1.6rem;
  @media (min-width: 768px) {
    font-size: 1.8rem;
  }
  text-align: justify;

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  li {
    display: block;
    margin-bottom: 2rem;
  }

  ul li::before {
    content: '\\2022';
    margin-right: 1rem;
  }

  li p {
    display: initial;
  }

  p {
    margin-bottom: 2rem;
  }

  a {
    color: #6a49f9;
    font-weight: 600;
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }

  .italic {
    font-style: italic;
  }

  .bold {
    font-weight: bold;
  }

  br {
    display: none;
  }
`;
