/**
 *
 * Header
 *
 */

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { selectCartData } from '../../common/Cart/selectors';
import { useApp } from '../../contexts/App';
import { getCurrentRootPage } from '../../helpers';
import { isMobileApp } from '../../helpers/app';
import { getBookableSegments } from '../../helpers/results';
import injectReducer from '../../utils/injectReducer';
import {
  appValuesPropTypes,
  locationPropTypes,
  SEARCH_URL,
} from '../App/constants';
import {
  makeSelectApp,
  makeSelectLocation,
  selectFunnelStep,
} from '../App/selectors';
import { changeLocale } from '../LanguageProvider/actions';
import { makeSelectLocale } from '../LanguageProvider/selectors';
import { handleDrawerAction, selectTabAction } from './actions';
import reducer from './reducer';
import makeSelectHeader from './selectors';
import HeaderView from './View';

export function Header(props) {
  const { funnelStep, partner } = useApp();

  return (
    <HeaderView
      currentPage={getCurrentRootPage(props.location.pathname)}
      displayDrawer={props.values.displayDrawer}
      displayModal={props.values.displayModal}
      funnelStep={funnelStep || props.funnelStep}
      handleDrawer={props.handleDrawer}
      isBookable={getBookableSegments(props.cart).length > 0}
      isMobileApp={isMobileApp(navigator.userAgent)}
      isNavbarBackButtonHidden={props.values.isNavbarBackButtonHidden}
      isWidgetBooking={!!partner}
      locale={props.locale}
      modalContent={props.values.modalContent}
      partnerLogo={partner?.logoUrl}
      searchUrl={props.appValues[SEARCH_URL]}
      selectLocale={props.selectLocale}
      selectTab={props.selectTab}
      selectedTab={props.values.selectedTab}
    />
  );
}

Header.propTypes = {
  appValues: appValuesPropTypes,
  cart: PropTypes.object,
  funnelStep: PropTypes.string,
  handleDrawer: PropTypes.func.isRequired,
  isNavbarBackButtonHidden: PropTypes.bool.isRequired,
  isWidgetBooking: PropTypes.bool,
  locale: PropTypes.string,
  location: locationPropTypes,
  selectLocale: PropTypes.func.isRequired,
  selectTab: PropTypes.func.isRequired,
  values: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
  values: makeSelectHeader(),
  appValues: makeSelectApp(),
  funnelStep: selectFunnelStep(),
  locale: makeSelectLocale(),
  location: makeSelectLocation(),
  cart: selectCartData(),
});

export function mapDispatchToProps(dispatch) {
  return {
    selectTab: (category) => dispatch(selectTabAction(category)),
    handleDrawer: (value) => dispatch(handleDrawerAction(value)),
    selectLocale: (locale) => dispatch(changeLocale(locale)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer({ key: 'header', reducer });

export default compose(withReducer, withConnect)(Header);
