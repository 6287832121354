import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components/macro';
import tw from 'tailwind.macro';
import { NAVIGATION } from '../../../containers/App/constants';
import { SearchIcon } from '../../../images/icons/search';
import { ReactComponent as IconTicket } from '../../../images/icons/ticket.svg';
import { colors } from '../../../styles/constants';
import history from '../../../utils/history';
import { Icon18 } from '../../Icon';
import messages from '../messages';

const LinkList = styled.div`
  ${tw`flex justify-around`}
`;

const Link = styled(NavLink)`
  ${tw`flex flex-col items-center no-underline w-full text-text`}
  gap:var(--size-small);

  font-size: 14px;
  * {
    font-weight: bold !important;
  }

  svg {
    ${Icon18}
  }

  path {
    stroke: ${colors.primary400};
  }

  &.active > *,
  &.active path {
    stroke: ${colors.secondary400};
    color: ${colors.secondary400};
  }
`;

const LinkContainer = styled.div`
  ${tw`w-full`}
`;

const LinkTitle = styled.span`
  ${tw`font-normal`}
`;

/**
 * @description The list of all links
 * @returns {JSX.Element}
 */
export const Navigation = () => (
  <LinkList>
    <LinkContainer>
      <Link
        exact
        isActive={() =>
          history.location.pathname === NAVIGATION.HOME ||
          [NAVIGATION.SEARCH].some((url) =>
            history.location.pathname.startsWith(url),
          )
        }
        to={NAVIGATION.HOME}
      >
        <SearchIcon color={colors['primary-400']} />
        <FormattedMessage {...messages.search}>
          {(msg) => <LinkTitle>{msg}</LinkTitle>}
        </FormattedMessage>
      </Link>
    </LinkContainer>

    <LinkContainer>
      <Link
        exact
        isActive={() =>
          [NAVIGATION.LOGIN, NAVIGATION.ORDERS].includes(
            history.location.pathname,
          )
        }
        to={NAVIGATION.ORDERS}
      >
        <IconTicket />
        <FormattedMessage {...messages.myOrders}>
          {(msg) => <LinkTitle>{msg}</LinkTitle>}
        </FormattedMessage>
      </Link>
    </LinkContainer>
  </LinkList>
);
