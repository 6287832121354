/*
 * LegalMention Messages
 *
 * This contains all the text for the LegalMention container.
 */

import { defineMessages } from 'react-intl';

export const legalMentionScope = 'app.containers.LegalMention';

export const legalMentionMessages = {
  header: {
    id: `${legalMentionScope}.header`,
    defaultMessage: 'Mentions légales',
    en: 'Legal Notices',
    es: 'Avisos legales',
    it: 'Avvisi legali',
    de: 'Rechtliche Hinweise',
    nl: 'Juridische mededelingen',
  },
  infoTitle: {
    id: `${legalMentionScope}.infoTitle`,
    defaultMessage: 'Informations légales',
    en: 'Legal Information',
    es: 'Información legal',
    it: 'Informazioni legali',
    de: 'Rechtliche Informationen',
    nl: 'Juridische informatie',
  },
  textLegal: {
    id: `${legalMentionScope}.textLegal`,
    defaultMessage: `Le site www.tictactrip.eu est édité et géré par Tictatrip, Société privée sous la forme juridique SAS (Société par actions simplifiés) à caractère commercial, immatriculée au registre du commerce et des sociétés de Paris sous le numéro 822 961 652.

     Le siègle social de l'entreprise se trouve au 22 rue Popincourt 75011 Paris.

     Société immatriculée au régime de TVA intracommunautaire n° FR34822961652.

     Société immatriculée à Atout France n° IM075180044.

     Le responsable éditorial est Simon Robain, le président.

     Le site est hébergé par l'entreprise Amazon Web Service (AWS), à burlington Plaza, Burlington Rd, Dublin 4, Ireland.
     Le site web de l'hébergeur étant : https://aws.amazon.com/fr/websites/`,
    en: `The www.tictactrip.eu website is published and managed by Tictatrip, a private company in the form of a SAS (simplified joint stock company), registered in the Paris Trade and Companies Register under number 822 961 652.

    The company's head office is located at 22 rue Popincourt 75011 Paris.

    Company registered for intra-Community VAT n° FR34822961652.

    Company registered with Atout France n° IM075180044.

    The editorial manager is Simon Robain, President.

    The site is hosted by Amazon Web Service (AWS), Burlington Plaza, Burlington Rd, Dublin 4, Ireland.
    The host's website is: https://aws.amazon.com/fr/websites/`,
    es: `El sitio web www.tictactrip.eu es editado y gestionado por Tictatrip, sociedad privada con forma de SAS (sociedad por acciones simplificada), inscrita en el Registro Mercantil y de Sociedades de París con el número 822 961 652.

     La sociedad tiene su domicilio social en 22 rue Popincourt 75011 París.

     La empresa está registrada a efectos del IVA intracomunitario con el número FR34822961652.

     Empresa registrada en Atout France con el número IM075180044.

     El director editorial es Simon Robain, el Presidente.

     El sitio web está alojado en Amazon Web Service (AWS), Burlington Plaza, Burlington Rd, Dublín 4, Irlanda.
     El sitio web del anfitrión es: https://aws.amazon.com/fr/websites/`,
    it: `Il sito www.tictactrip.eu è pubblicato e gestito da Tictatrip, una società privata sotto forma di SAS (società per azioni semplificata), iscritta al Registro del Commercio e delle Imprese di Parigi con il numero 822 961 652.

     La sede legale della società è al 22 rue Popincourt 75011 Parigi.

     La società è registrata ai fini dell'IVA intracomunitaria con il numero FR34822961652.

     Società registrata presso Atout France n. IM075180044.

     Il responsabile editoriale è Simon Robain, il presidente.

     Il sito web è ospitato da Amazon Web Service (AWS), Burlington Plaza, Burlington Rd, Dublino 4, Irlanda.
     Il sito web dell'host è: https://aws.amazon.com/fr/websites/`,
    de: `Die Website www.tictactrip.eu wird von Tictatrip herausgegeben und verwaltet, einer privaten Gesellschaft in der Rechtsform einer SAS (Société par actions simplifiés) mit kommerziellem Charakter, die im Handels- und Gesellschaftsregister von Paris unter der Nummer 822 961 652 eingetragen ist.

     Der Hauptsitz des Unternehmens befindet sich in 22 rue Popincourt 75011 Paris.

     Das Unternehmen ist für das innergemeinschaftliche Mehrwertsteuersystem unter der Nummer FR34822961652 registriert.

     Eingetragenes Unternehmen bei Atout France Nr. IM075180044.

     Der redaktionelle Verantwortliche ist Simon Robain, der Vorsitzende.

     Die Website wird von der Firma Amazon Web Service (AWS) in burlington Plaza, Burlington Rd, Dublin 4, Ireland, gehostet.
     Die Website des Hosts lautet: https://aws.amazon.com/fr/websites/`,
    nl: `De website www.tictactrip.eu wordt uitgegeven en beheerd door Tictatrip, een besloten vennootschap onder de vorm van een SAS (vereenvoudigde naamloze vennootschap), ingeschreven in het Handels- en Vennootschapsregister van Parijs onder het nummer 822 961 652.

     De maatschappelijke zetel van de vennootschap is gevestigd te 22 rue Popincourt 75011 Parijs.

     Het bedrijf is geregistreerd voor intracommunautaire btw onder nummer FR34822961652.

     Het bedrijf is geregistreerd bij Atout France onder nummer IM075180044.

     De redactiemanager is Simon Robain, de voorzitter.

     De website wordt gehost door Amazon Web Service (AWS), Burlington Plaza, Burlington Rd, Dublin 4, Ierland.
     De website van de host is: https://aws.amazon.com/fr/websites/`,
  },
  ODbLTitle: {
    id: `${legalMentionScope}.ODbLTitle`,
    defaultMessage: 'Données',
    en: 'Data',
    es: 'Datos',
    it: 'Dati',
    de: 'Daten',
    nl: 'Gegevens',
  },
  ODbL: {
    id: `${legalMentionScope}.ODbL`,
    defaultMessage: `Tictactrip utilise les versions des jeux de données communiqués par les producteurs suivants :
      - Région Provence-Alpes-Côte-d’Azur
      
      Ces données sont disponibles sur transport.data.gouv.fr sous la licence « Open Database Licence » (ODbL).`,
    en: `Tictactrip uses versions of datasets supplied by the following producers:
      - Provence-Alpes-Côte-d'Azur Region
      
      These data are available on transport.data.gouv.fr under the Open Database Licence (ODbL).`,
    es: `Tictactrip utiliza versiones de los conjuntos de datos proporcionados por los siguientes productores:
      - Región Provenza-Alpes-Costa Azul
      
      Estos datos están disponibles en transport.data.gouv.fr bajo la licencia Open Database Licence (ODbL).`,
    it: `Tictactrip utilizza versioni dei set di dati forniti dai seguenti produttori:
      - Regione Provenza-Alpi-Costa Azzurra
      
      Questi dati sono disponibili su transport.data.gouv.fr secondo la Open Database Licence (ODbL).`,
    de: `Tictactrip verwendet die Versionen der Datensätze, die von den folgenden Produzenten mitgeteilt wurden:
      - Region Provence-Alpes-Côte-d'Azur.
      
      Diese Daten sind auf transport.data.gouv.fr unter der "Open Database Licence" (ODbL) verfügbar.`,
    nl: `Tictactrip gebruikt versies van de datasets van de volgende producenten:
      - Regio Provence-Alpes-Côte-d'Azur
      
      Deze gegevens zijn beschikbaar op transport.data.gouv.fr onder de Open Database Licence (ODbL).`,
  },
};

export default defineMessages(legalMentionMessages);
