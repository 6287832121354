import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components/macro';
import tw from 'tailwind.macro';
import { LANGUAGES } from '../constants';
import Flag from './Flag';
import messages from './messages';
import ModalLinkItem from './ModalLinkItem';

const Container = styled.div`
  ${tw`flex flex-col flex-wrap
    pb-abs1 md:mt-0
    md:flex-row`};
  margin-top: 5.5rem;
`;

const Lang = styled.a`
  ${tw`flex items-center
  mr-abs4 text-h4-m text-text
    cursor-pointer`};
  padding-bottom: 15px;
  padding-top: 15px;
`;

const HiddenLinkList = styled.div`
  ${tw`hidden`};
`;

const ModalLinkList = ({
  areLinksCloaked,
  category,
  closeModal,
  handleDrawer,
  links,
  selectLocale,
}) => (
  <Container>
    {links.map((link) => {
      if (category === LANGUAGES) {
        return (
          <Lang
            key={link.lang}
            id={`locale${link.lang}`}
            onClick={() => {
              selectLocale(link.lang);
              closeModal(category);
              handleDrawer(false);
              document.body.style.position = 'initial';
            }}
          >
            <Flag src={link.flag} />
            <FormattedMessage {...messages[link.lang]} />
          </Lang>
        );
      }
      return (
        <ModalLinkItem
          key={link.url}
          closeDrawer={handleDrawer}
          closeModal={() => closeModal(category)}
          id={`${link.id}-${category}`}
          isCloaked={areLinksCloaked || link.isCloaked}
          mustReload={link.mustReload}
          name={link.name}
          openInNewTab={link.openInNewTab}
          url={link.url}
        />
      );
    })}
  </Container>
);

ModalLinkList.propTypes = {
  areLinksCloaked: PropTypes.bool,
  category: PropTypes.string,
  closeModal: PropTypes.func,
  handleDrawer: PropTypes.func,
  links: PropTypes.array,
  selectLocale: PropTypes.func,
};

export { HiddenLinkList, ModalLinkList };
