import styled from 'styled-components';
import tw from 'tailwind.macro';
import { InstagramIcon } from '../../../images/icons/socialNetwork/instagram';
import { LinkedinIcon } from '../../../images/icons/socialNetwork/linkedin';
import { TiktokIcon } from '../../../images/icons/socialNetwork/tiktok';
import { TwitterIcon } from '../../../images/icons/socialNetwork/twitter';
import { IconProps } from '../../../images/icons/types';
import { colors } from '../../../styles/constants';
import { Container, Wrapper } from './common';

const TICTACTRIP_SOCIAL_NETWORKS: Array<{
  name: string;
  href: string;
  logo: React.FC<IconProps>;
}> = [
  {
    name: 'Instagram',
    href: 'https://www.instagram.com/tictactrip.eu/',
    logo: InstagramIcon,
  },
  {
    name: 'Tiktok',
    href: 'https://www.tiktok.com/@tictactrip.eu',
    logo: TiktokIcon,
  },
  {
    name: 'Twitter',
    href: 'https://twitter.com/tictactrip',
    logo: TwitterIcon,
  },
  {
    name: 'Linkedin',
    href: 'https://fr.linkedin.com/company/tictactrip',
    logo: LinkedinIcon,
  },
];

const StyledWrapper = styled(Wrapper)`
  ${tw`flex`}
  gap:var(--size-medium);
`;

const Link = styled.a`
  ${tw`border border-solid border-secondary-400 rounded-full flex justify-center items-center`}
  width: 40px;
  height: 40px;
`;

type SocialNetworksProps = {
  isReduced: boolean;
};

const SocialNetworks = (props: SocialNetworksProps) => {
  if (props.isReduced) {
    return null;
  }

  return (
    <Container>
      <StyledWrapper>
        {TICTACTRIP_SOCIAL_NETWORKS.map((socialNetwork) => (
          <Link
            key={socialNetwork.name}
            href={socialNetwork.href}
            rel="me"
            target="_blank"
          >
            {<socialNetwork.logo color={colors['secondary-400']} />}
          </Link>
        ))}
      </StyledWrapper>
    </Container>
  );
};

export default SocialNetworks;
