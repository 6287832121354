/*
 * Privacy Messages
 *
 * This contains all the text for the Privacy container.
 */

import { defineMessages } from 'react-intl';

export const privacyScope = 'app.containers.Privacy';

export const privacyMessages = {
  header: {
    id: `${privacyScope}.header`,
    defaultMessage: 'Politique de confidentialité',
    en: 'Privacy Policy',
    es: 'Política de privacidad',
    it: 'Informativa sulla privacy',
    de: 'Datenschutzrichtlinie',
    nl: 'Privacybeleid',
  },
  title1: {
    id: `${privacyScope}.title1`,
    defaultMessage: 'Quel type de données pouvons nous collecter ?',
    en: 'What type of data can we collect?',
    es: '¿Qué tipo de datos podemos recopilar?',
    it: 'Che tipo di dati possiamo raccogliere?',
    de: 'Welche Art von Daten können wir sammeln?',
    nl: 'Welk type gegevens kunnen we verzamelen?',
  },
  title11: {
    id: `${privacyScope}.title11`,
    defaultMessage:
      'Les données et informations générées automatiquement par les utilisateurs',
    en: 'Data and information generated automatically by users',
    es: 'Datos e información generados automáticamente por los usuarios',
    it: 'Dati e informazioni generati automaticamente dagli utenti',
    de: 'Daten und Informationen, die automatisch von Benutzern generiert werden',
    nl: 'Gegevens en informatie die automatisch door gebruikers worden gegenereerd',
  },
  title12: {
    id: `${privacyScope}.title12`,
    defaultMessage: 'Les données que vous nous fournissez',
    en: 'Data you provide to us',
    es: 'Datos que nos proporciona',
    it: 'Dati che ci fornite',
    de: 'Daten, die Sie uns zur Verfügung stellen',
    nl: 'Gegevens die u aan ons verstrekt',
  },
  title13: {
    id: `${privacyScope}.title13`,
    defaultMessage: 'Les emails marketing et newsletters',
    en: 'Marketing emails and newsletters',
    es: 'Correos electrónicos de marketing y boletines informativos',
    it: 'Email di marketing e newsletter',
    de: 'Marketing-E-Mails und Newsletter',
    nl: 'Marketing-e-mails en nieuwsbrieven',
  },
  title14: {
    id: `${privacyScope}.title14`,
    defaultMessage: 'Gestion et utilisation des cookies',
    en: 'Management and use of cookies',
    es: 'Gestión y uso de cookies',
    it: 'Gestione e utilizzo dei cookie',
    de: 'Verwaltung und Nutzung von Cookies',
    nl: 'Beheer en gebruik van cookies',
  },
  title15: {
    id: `${privacyScope}.title15`,
    defaultMessage: 'Retargeting, le re-ciblage client',
    en: 'Retargeting, customer re-targeting',
    es: 'Retargeting, reorientación al cliente',
    it: 'Retargeting, re-targeting del cliente',
    de: 'Retargeting, Kundenretargeting',
    nl: 'Retargeting, klanten opnieuw targeten',
  },
  title16: {
    id: `${privacyScope}.title16`,
    defaultMessage: 'Analyse web et statistiques - Google Analytics',
    en: 'Web analytics and statistics - Google Analytics',
    es: 'Análisis web y estadísticas - Google Analytics',
    it: 'Analisi web e statistiche - Google Analytics',
    de: 'Webanalyse und Statistiken - Google Analytics',
    nl: 'Webanalyse en statistieken - Google Analytics',
  },
  title17: {
    id: `${privacyScope}.title17`,
    defaultMessage: 'Plugins pour les réseaux sociaux',
    en: 'Plugins for social networks',
    es: 'Plugins para redes sociales',
    it: 'Plugin per i social network',
    de: 'Plugins für soziale Netzwerke',
    nl: 'Plugins voor sociale netwerken',
  },
  title2: {
    id: `${privacyScope}.title2`,
    defaultMessage: 'Qui a accès à vos données ?',
    en: 'Who has access to your data?',
    es: '¿Quién tiene acceso a sus datos?',
    it: 'Chi ha accesso ai tuoi dati?',
    de: 'Wer hat Zugriff auf Ihre Daten?',
    nl: 'Wie heeft toegang tot uw gegevens?',
  },
  title21: {
    id: `${privacyScope}.title21`,
    defaultMessage: 'La transmission de données pour les réservations',
    en: 'Data transmission for bookings',
    es: 'Transmisión de datos para reservas',
    it: 'Trasmissione di dati per prenotazioni',
    de: 'Datenübertragung für Buchungen',
    nl: 'Gegevensoverdracht voor boekingen',
  },
  title211: {
    id: `${privacyScope}.title211`,
    defaultMessage: 'Vers les fournisseurs de transports',
    en: 'To transport providers',
    es: 'A los proveedores de transporte',
    it: 'Ai fornitori di trasporto',
    de: 'An Transportanbieter',
    nl: 'Naar transportaanbieders',
  },
  title212: {
    id: `${privacyScope}.title212`,
    defaultMessage: 'Vers les fournisseurs de paiement',
    en: 'To payment providers',
    es: 'A los proveedores de pago',
    it: 'Ai fornitori di pagamento',
    de: 'An Zahlungsanbieter',
    nl: 'Naar betalingsaanbieders',
  },
  title22: {
    id: `${privacyScope}.title22`,
    defaultMessage:
      'La transmission de données vers les fournisseurs de services',
    en: 'Data transmission to service providers',
    es: 'Transmisión de datos a proveedores de servicios',
    it: 'Trasmissione di dati a fornitori di servizi',
    de: 'Datenübertragung an Dienstleister',
    nl: 'Gegevensoverdracht naar dienstverleners',
  },
  title23: {
    id: `${privacyScope}.title23`,
    defaultMessage: 'La transmission de données en dehors de l’UE',
    en: 'Data transmission outside the EU',
    es: 'Transmisión de datos fuera de la UE',
    it: 'Trasmissione di dati al di fuori dell’UE',
    de: 'Datenübertragung außerhalb der EU',
    nl: 'Gegevensoverdracht buiten de EU',
  },
  title3: {
    id: `${privacyScope}.title3`,
    defaultMessage:
      'Quelle est la finalité de notre utilisation de vos données et sur quelle base juridique le faisons nous ?',
    en: 'What is the purpose of our use of your data and on what legal basis do we do it?',
    es: '¿Cuál es el propósito de nuestro uso de sus datos y sobre qué base legal lo hacemos?',
    it: 'Qual è lo scopo del nostro utilizzo dei tuoi dati e su quale base giuridica lo facciamo?',
    de: 'Was ist der Zweck unserer Nutzung Ihrer Daten und auf welcher rechtlichen Grundlage tun wir dies?',
    nl: 'Wat is het doel van ons gebruik van uw gegevens en op welke juridische basis doen we dat?',
  },
  title31: {
    id: `${privacyScope}.title31`,
    defaultMessage:
      'L’article 6, 1a du Règlement Général sur la Protection des Données',
    en: 'Article 6, 1a of the General Data Protection Regulation',
    es: 'Artículo 6, 1a del Reglamento General de Protección de Datos',
    it: 'Articolo 6, 1a del Regolamento generale sulla protezione dei dati',
    de: 'Artikel 6, 1a der Allgemeinen Datenschutzverordnung',
    nl: 'Artikel 6, 1a van de Algemene Verordening Gegevensbescherming',
  },
  title32: {
    id: `${privacyScope}.title32`,
    defaultMessage: 'L’article 6, 1b du RGPD',
    en: 'Article 6, 1b of the GDPR',
    es: 'Artículo 6, 1b del RGPD',
    it: 'Articolo 6, 1b del GDPR',
    de: 'Artikel 6, 1b der DSGVO',
    nl: 'Artikel 6, 1b van de AVG',
  },
  title33: {
    id: `${privacyScope}.title33`,
    defaultMessage: 'L’article 6, 1c du RGPD',
    en: 'Article 6, 1c of the GDPR',
    es: 'Artículo 6, 1c del RGPD',
    it: 'Articolo 6, 1c del GDPR',
    de: 'Artikel 6, 1c der DSGVO',
    nl: 'Artikel 6, 1c van de AVG',
  },
  title34: {
    id: `${privacyScope}.title34`,
    defaultMessage: 'L’article 6, 1f du RGPD',
    en: 'Article 6, 1f of the GDPR',
    es: 'Artículo 6, 1f del RGPD',
    it: 'Articolo 6, 1f del GDPR',
    de: 'Artikel 6, 1f der DSGVO',
    nl: 'Artikel 6, 1f van de AVG',
  },
  title4: {
    id: `${privacyScope}.title4`,
    defaultMessage:
      'Droits des utilisateurs sur leurs données personnelles, droit de porter plainte et droit d’opposition',
    en: 'User rights on their personal data, right to complain, and right to object',
    es: 'Derechos de los usuarios sobre sus datos personales, derecho a presentar una queja y derecho de oposición',
    it: 'Diritti degli utenti sui propri dati personali, diritto di presentare reclami e diritto di opposizione',
    de: 'Rechte der Benutzer über ihre persönlichen Daten, Beschwerderecht und Widerspruchsrecht',
    nl: 'Rechten van gebruikers op hun persoonlijke gegevens, recht om een klacht in te dienen en recht op bezwaar',
  },
  content1: {
    id: `${privacyScope}.content1`,
    defaultMessage: `L'opérateur du site web de Tictactrip qui est aussi le "responsable du traitement" en matière de législation relative à la protection des données est l'entreprise Tictactrip elle-même. 

Tictactrip SAS est enregistrée au Registre du Commerce et des Sociétés en France sous le n° 822 961 652. 

Le siège social de l'entreprise Tictactrip est situé au 22 rue Popincourt 75011 Paris. 

Vous pouvez nous contacter à tout moment sur l'adresse email : contact@tictactrip.eu pour toutes questions et informations relatives ou non à la gestion des données et de vos données personnelles.

Nous souhaitons vous informer que notre traitement des données personnelles est conforme au Règlement Général sur la Protection des Données (RGPD).`,
    en: `The operator of Tictactrip's website, who is also the "data controller" in terms of data protection legislation, is the company Tictactrip itself.

Tictactrip SAS is registered with the Trade and Companies Register in France under number 822 961 652.

The registered office of Tictactrip is located at 22 rue Popincourt, 75011 Paris.

You can contact us at any time at the email address: contact@tictactrip.eu for any questions and information related or unrelated to data management and your personal data.

We want to inform you that our processing of personal data complies with the General Data Protection Regulation (GDPR).`,
    es: `El operador del sitio web de Tictactrip, que también es el "responsable del tratamiento" en términos de legislación de protección de datos, es la empresa Tictactrip en sí.

Tictactrip SAS está registrada en el Registro Mercantil y de Sociedades de Francia con el número 822 961 652.

La sede social de Tictactrip está ubicada en 22 rue Popincourt, 75011 París.

Puede contactarnos en cualquier momento en la dirección de correo electrónico: contact@tictactrip.eu para cualquier pregunta e información relacionada o no con la gestión de datos y sus datos personales.

Queremos informarle que nuestro tratamiento de datos personales cumple con el Reglamento General de Protección de Datos (RGPD).`,
    it: `L'operatore del sito web di Tictactrip, che è anche il "responsabile del trattamento" ai sensi della legislazione sulla protezione dei dati, è l'azienda Tictactrip stessa.

Tictactrip SAS è registrata presso il Registro delle imprese e delle società in Francia con il numero 822 961 652.

La sede legale di Tictactrip si trova al 22 rue Popincourt, 75011 Parigi.

È possibile contattarci in qualsiasi momento all'indirizzo email: contact@tictactrip.eu per qualsiasi domanda e informazione relativa o non alla gestione dei dati e dei tuoi dati personali.

Vogliamo informarvi che il nostro trattamento dei dati personali è conforme al Regolamento generale sulla protezione dei dati (GDPR).`,
    de: `Der Betreiber der Website von Tictactrip, der auch der "Datenverantwortliche" im Sinne der Datenschutzgesetzgebung ist, ist das Unternehmen Tictactrip selbst.

Die Tictactrip SAS ist im Handels- und Gesellschaftsregister in Frankreich unter der Nummer 822 961 652 eingetragen.

Der Hauptsitz von Tictactrip befindet sich in der 22 rue Popincourt, 75011 Paris.

Sie können uns jederzeit unter der E-Mail-Adresse: contact@tictactrip.eu für alle Fragen und Informationen kontaktieren, die mit der Datenverwaltung und Ihren personenbezogenen Daten zusammenhängen oder nicht.

Wir möchten Sie darauf hinweisen, dass unsere Verarbeitung personenbezogener Daten den Bestimmungen der Datenschutz-Grundverordnung (DSGVO) entspricht.`,
    nl: `De exploitant van de website van Tictactrip, die ook de "verwerkingsverantwoordelijke" is in termen van wetgeving inzake gegevensbescherming, is het bedrijf Tictactrip zelf.

Tictactrip SAS is geregistreerd bij de Kamer van Koophandel in Frankrijk onder nummer 822 961 652.

Het hoofdkantoor van Tictactrip is gevestigd aan 22 rue Popincourt, 75011 Parijs.

U kunt te allen tijde contact met ons opnemen op het e-mailadres: contact@tictactrip.eu voor alle vragen en informatie, gerelateerd of niet gerelateerd aan gegevensbeheer en uw persoonlijke gegevens.

Wij willen u informeren dat onze verwerking van persoonsgegevens in overeenstemming is met de Algemene Verordening Gegevensbescherming (AVG).`,
  },
  content2: {
    id: `${privacyScope}.content2`,
    defaultMessage: `Sur le site Tictactrip, nous recueillons des données et autres informations qui sont automatiquement générées par votre navigateur web à chacune de vos visites sur notre site. Dans ces informations, il est question de :
- L’adresse IP,
- Les urls du site visitées avant de venir sur notre site internet (site web référent),
- Le navigateur utilisé,
- La date et l’heure de votre visite,
- Le temps que vous passez sur le site Tictactrip,
- La langue du navigateur, 
- Le système d’exploitation,
- Le dispositif d’accès utilisé,
- Les urls des pages visitées sur notre site.

Ces données sont stockées durant une période de deux ans, après quoi elles sont supprimées automatiquement et définitivement.`,
    en: `On the Tictactrip website, we collect data and other information that is automatically generated by your web browser each time you visit our site. This information includes:
- IP address,
- URLs of the sites visited before coming to our website (referring website),
- The browser used,
- Date and time of your visit,
- The time you spend on the Tictactrip site,
- Browser language,
- Operating system,
- Access device used,
- URLs of the pages visited on our site.

This data is stored for a period of two years, after which it is automatically and permanently deleted.`,
    es: `En el sitio web de Tictactrip, recopilamos datos y otra información que se genera automáticamente mediante su navegador web cada vez que visita nuestro sitio. Esta información incluye:
- Dirección IP,
- URLs de los sitios visitados antes de llegar a nuestro sitio web (sitio web de referencia),
- Navegador utilizado,
- Fecha y hora de su visita,
- El tiempo que pasa en el sitio Tictactrip,
- Idioma del navegador,
- Sistema operativo,
- Dispositivo de acceso utilizado,
- URLs de las páginas visitadas en nuestro sitio.

Estos datos se almacenan durante un período de dos años, después de lo cual se eliminan automáticamente y de forma permanente.`,
    it: `Sul sito web di Tictactrip raccogliamo dati e altre informazioni generate automaticamente dal tuo browser web ogni volta che visiti il nostro sito. Queste informazioni includono:
- Indirizzo IP,
- URL dei siti visitati prima di arrivare al nostro sito web (sito web di riferimento),
- Browser utilizzato,
- Data e ora della tua visita,
- Il tempo trascorso sul sito Tictactrip,
- Lingua del browser,
- Sistema operativo,
- Dispositivo di accesso utilizzato,
- URL delle pagine visitate sul nostro sito.

Questi dati vengono conservati per un periodo di due anni, dopodiché vengono eliminati automaticamente e definitivamente.`,
    de: `Auf der Website von Tictactrip erfassen wir Daten und andere Informationen, die bei jedem Besuch unserer Website automatisch von Ihrem Webbrowser generiert werden. Zu diesen Informationen gehören:
- IP-Adresse,
- URLs der besuchten Websites, bevor Sie unsere Website besuchen (verweisende Website),
- Der verwendete Browser,
- Datum und Uhrzeit Ihres Besuchs,
- Die Zeit, die Sie auf der Tictactrip-Website verbringen,
- Browsersprache,
- Betriebssystem,
- Verwendetes Zugriffsgerät,
- URLs der auf unserer Website besuchten Seiten.

Diese Daten werden für einen Zeitraum von zwei Jahren gespeichert und anschließend automatisch und dauerhaft gelöscht.`,
    nl: `Op de website van Tictactrip verzamelen we gegevens en andere informatie die automatisch wordt gegenereerd door uw webbrowser telkens wanneer u onze site bezoekt. Deze informatie omvat:
- IP-adres,
- URL's van de bezochte sites voordat u naar onze website kwam (verwijzende website),
- De gebruikte browser,
- Datum en tijd van uw bezoek,
- De tijd die u doorbrengt op de Tictactrip-site,
- Browsertaal,
- Besturingssysteem,
- Gebruikt toegangsapparaat,
- URL's van de pagina's die op onze site zijn bezocht.

Deze gegevens worden gedurende een periode van twee jaar bewaard, waarna ze automatisch en permanent worden verwijderd.`,
  },
  content3: {
    id: `${privacyScope}.content3`,
    defaultMessage: `L’application Tictactrip est une alternative pour vous permettre d’accéder aux offres et services de Tictactrip. Sur cette app, nous enregistrons, traitons et utilisons les données de la même manière que sur le site web Tictactrip, aux mêmes fins et sur les mêmes fondements juridiques. Le contenu de notre politique de confidentialité s’applique alors en conséquence.
    
    Également, nous enregistrons les données et informations que vous nous fournissez lors de l’utilisation de notre site, en rapport à votre planification de voyage :
  
  Également, nous enregistrons les données et informations que vous nous fournissez lors de l’utilisation de notre site, en rapport à votre planification de voyage :

- Dans le cas de la réservation d’un trajet sur notre site, nous vous demandons différents renseignements et données personnelles tels que : votre nom, votre numéro de téléphone, votre adresse e-mail, votre adresse de facturation, votre de type de paiement, vos informations sur vos cartes de réductions ainsi que vos informations de carte de crédit pour effectuer le paiement. Dans ce cas, nous ne vous demanderons jamais d’autres informations que celles nécessaires pour compléter le processus de réservation auprès de nos fournisseurs de transport. Sans ces données, nous ne pourrons alors pas traiter votre réservation. Les informations de carte de crédit sont directement transmises au fournisseur de paiement et nous ne les enregistrons pas. Vos données personnelles relatives à la réservation sont enregistrées dans notre base de donnée pendant une période de 10 ans. Nous conservons par la suite les informations requises par le droit commercial et fiscal pour des périodes de conservation légales qui sont le plus souvent de dix ans après la réservation et conclusion du contrat de ce fait.

- Dans le cas où vous vous inscriviez à un compte personnel sur le site Tictactrip avec la création d’un compte sur notre site, ces données seront alors stockées pour faciliter vos prochaines réservations. Ces données resteront enregistrées tant que vous aurez un compte utilisateur ouvert sur notre site Tictactrip. Dans l’optique où vous décideriez de supprimer votre compte, vos données personnelles associées seront immédiatement supprimées également. Cependant, les donnés de réservation potentielles et les données de base (ex : nom, adresse…) sont conservées comme énoncé ci-dessus.

- Dans le cas où vous nous contacteriez par mail ou si vous effectuez une demande de support client, les données que vous nous fournirez (par exemple : nom, email, téléphone…) seront utilisées pour vous répondre. Vos demandes de contact et de support client sont enregistrées pour une durée de 5 ans à compter de la date de réponse de nos équipes. Les droits commerciaux et fiscaux nous imposent de garder cette correspondance entre vous et nous pour les périodes de rétentions légales à savoir 6 ans en général.`,
    en: `The Tictactrip application is an alternative for you to access Tictactrip's offers and services. In this app, we record, process, and use data in the same way as on the Tictactrip website, for the same purposes and on the same legal basis. The content of our privacy policy applies accordingly.

Additionally, we record the data and information you provide us when using our site, related to your travel planning:

- In the case of booking a trip on our site, we ask for various information and personal data such as: your name, phone number, email address, billing address, payment method, information about your discount cards, and your credit card information to process the payment. In this case, we will never ask for information other than what is necessary to complete the booking process with our transport providers. Without this data, we will not be able to process your reservation. Credit card information is transmitted directly to the payment provider, and we do not store it. Your personal data related to the reservation is stored in our database for a period of 10 years. We then retain information required by commercial and tax law for legal retention periods, most often ten years after the reservation and contract conclusion.

- In case you create a personal account on the Tictactrip website with an account created on our site, this data will be stored to facilitate your future bookings. This data will remain recorded as long as you have an open user account on our Tictactrip site. In the event you decide to delete your account, your associated personal data will be immediately deleted as well. However, potential booking data and basic data (e.g., name, address) are retained as stated above.

- If you contact us by email or if you make a customer support request, the data you provide us (for example: name, email, phone number) will be used to respond to you. Your customer contact and support requests are recorded for a duration of 5 years from the date of our team's response. Commercial and tax rights require us to keep this correspondence between you and us for legal retention periods, which is generally 6 years.`,
    es: `La aplicación Tictactrip es una alternativa para que pueda acceder a las ofertas y servicios de Tictactrip. En esta aplicación, registramos, procesamos y utilizamos datos de la misma manera que en el sitio web de Tictactrip, para los mismos fines y sobre la misma base legal. El contenido de nuestra política de privacidad se aplica en consecuencia.

Además, registramos los datos e información que nos proporciona al utilizar nuestro sitio, en relación con la planificación de su viaje:

- En el caso de reservar un viaje en nuestro sitio, solicitamos diversos datos e información personal, como su nombre, número de teléfono, dirección de correo electrónico, dirección de facturación, método de pago, información sobre sus tarjetas de descuento y la información de su tarjeta de crédito para procesar el pago. En este caso, nunca solicitaremos información que no sea necesaria para completar el proceso de reserva con nuestros proveedores de transporte. Sin estos datos, no podremos procesar su reserva. La información de la tarjeta de crédito se transmite directamente al proveedor de pagos y no la almacenamos. Sus datos personales relacionados con la reserva se almacenan en nuestra base de datos durante un período de 10 años. Luego, conservamos la información requerida por la legislación comercial y fiscal durante períodos de retención legales, que generalmente son de diez años después de la reserva y la conclusión del contrato.

- En caso de que cree una cuenta personal en el sitio web de Tictactrip con una cuenta creada en nuestro sitio, estos datos se almacenarán para facilitar sus futuras reservas. Estos datos permanecerán registrados mientras tenga una cuenta de usuario abierta en nuestro sitio Tictactrip. En caso de que decida eliminar su cuenta, sus datos personales asociados se eliminarán inmediatamente. Sin embargo, los datos potenciales de reserva y los datos básicos (por ejemplo, nombre, dirección) se conservan como se indica anteriormente.

- Si se comunica con nosotros por correo electrónico o realiza una solicitud de soporte al cliente, los datos que nos proporcione (por ejemplo: nombre, correo electrónico, número de teléfono) se utilizarán para responderle. Sus solicitudes de contacto y soporte al cliente se registran durante un período de 5 años a partir de la fecha de respuesta de nuestros equipos. Los derechos comerciales y fiscales nos obligan a conservar esta correspondencia entre usted y nosotros durante los períodos de retención legales, que generalmente son de 6 años.`,
    it: `L'app Tictactrip è un'alternativa che ti consente di accedere alle offerte e ai servizi di Tictactrip. In questa app, registriamo, elaboriamo e utilizziamo i dati allo stesso modo del sito web Tictactrip, per gli stessi scopi e sulla stessa base giuridica. Il contenuto della nostra informativa sulla privacy si applica di conseguenza.

  Inoltre, registriamo i dati e le informazioni che ci fornisci durante l'uso del nostro sito, in relazione alla pianificazione del tuo viaggio:
  
  - Nel caso della prenotazione di un viaggio sul nostro sito, ti chiediamo vari dati e informazioni personali come: il tuo nome, il numero di telefono, l'indirizzo email, l'indirizzo di fatturazione, il tipo di pagamento, le informazioni sulle tue carte di sconto e le informazioni sulla tua carta di credito per effettuare il pagamento. In questo caso, non chiederemo mai informazioni diverse da quelle necessarie per completare il processo di prenotazione con i nostri fornitori di trasporto. Senza questi dati, non saremo in grado di elaborare la tua prenotazione. Le informazioni sulla carta di credito vengono trasmesse direttamente al fornitore di pagamento e non le memorizziamo. I tuoi dati personali relativi alla prenotazione vengono registrati nel nostro database per un periodo di 10 anni. Successivamente, conserviamo le informazioni richieste dalla legislazione commerciale e fiscale per periodi di conservazione legali, che sono generalmente di dieci anni dopo la prenotazione e la conclusione del contratto.
  
  - Nel caso in cui crei un account personale sul sito Tictactrip con la creazione di un account sul nostro sito, questi dati verranno quindi memorizzati per facilitare le tue future prenotazioni. Questi dati rimarranno registrati finché avrai un account utente aperto sul nostro sito Tictactrip. Nel caso decidessi di eliminare il tuo account, i tuoi dati personali associati verranno eliminati immediatamente. Tuttavia, i dati potenziali di prenotazione e i dati di base (ad esempio, nome, indirizzo) sono conservati come indicato sopra.
  
  - Nel caso in cui ci contatti via email o effettui una richiesta di supporto clienti, i dati che ci fornirai (ad esempio: nome, email, numero di telefono) saranno utilizzati per risponderti. Le tue richieste di contatto e`,
    de: `Die Tictactrip-Anwendung ist eine Alternative, mit der Sie auf die Angebote und Dienstleistungen von Tictactrip zugreifen können. In dieser App erfassen, verarbeiten und verwenden wir Daten auf die gleiche Weise wie auf der Tictactrip-Website, für die gleichen Zwecke und auf der gleichen rechtlichen Grundlage. Der Inhalt unserer Datenschutzrichtlinie gilt entsprechend.

  Außerdem erfassen wir die Daten und Informationen, die Sie uns bei der Nutzung unserer Website im Zusammenhang mit Ihrer Reiseplanung zur Verfügung stellen:
  
  - Im Falle der Buchung einer Fahrt auf unserer Website bitten wir um verschiedene Informationen und persönliche Daten wie Ihren Namen, Ihre Telefonnummer, Ihre E-Mail-Adresse, Ihre Rechnungsadresse, Ihre Zahlungsmethode, Informationen zu Ihren Rabattkarten sowie Ihre Kreditkartendaten zur Zahlungsabwicklung. In diesem Fall werden wir Sie niemals nach anderen Informationen als den für den Buchungsvorgang bei unseren Transportanbietern erforderlichen Daten fragen. Ohne diese Daten können wir Ihre Reservierung nicht bearbeiten. Kreditkartendaten werden direkt an den Zahlungsanbieter übermittelt, und wir speichern sie nicht. Ihre persönlichen Daten im Zusammenhang mit der Reservierung werden in unserer Datenbank für einen Zeitraum von 10 Jahren gespeichert. Anschließend bewahren wir Informationen auf, die nach Handels- und Steuerrecht für gesetzliche Aufbewahrungsfristen erforderlich sind, in der Regel zehn Jahre nach der Reservierung und Vertragsabschluss.
  
  - Falls Sie sich auf der Tictactrip-Website mit der Erstellung eines Kontos auf unserer Website registrieren, werden diese Daten gespeichert, um Ihre zukünftigen Buchungen zu erleichtern. Diese Daten bleiben gespeichert, solange Sie ein offenes Benutzerkonto auf unserer Tictactrip-Website haben. Wenn Sie sich entscheiden, Ihr Konto zu löschen, werden Ihre zugehörigen personenbezogenen Daten sofort gelöscht. Die potenziellen Buchungsdaten und Basisdaten (z. B. Name, Adresse) werden jedoch wie oben beschrieben aufbewahrt.
  
  - Falls Sie uns per E-Mail kontaktieren oder eine Anfrage beim Kundensupport stellen, werden die von Ihnen bereitgestellten Daten (z. B. Name, E-Mail, Telefonnummer) verwendet, um Ihnen zu antworten. Ihre Kundenkontakt- und Supportanfragen werden für einen Zeitraum von 5 Jahren ab dem Datum der Antwort unserer Teams aufgezeichnet. Handels- und Steuerrechte verpflichten uns, diese Korrespondenz zwischen Ihnen und uns für die gesetzlichen Aufbewahrungsfristen aufzubewahren, die in der Regel 6 Jahre betragen.`,

    nl: `De Tictactrip-app is een alternatief waarmee u toegang kunt krijgen tot de aanbiedingen en diensten van Tictactrip. In deze app registreren, verwerken en gebruiken we gegevens op dezelfde manier als op de Tictactrip-website, voor dezelfde doeleinden en op dezelfde juridische basis. De inhoud van ons privacybeleid is dienovereenkomstig van toepassing.

  Daarnaast registreren we de gegevens en informatie die u ons verstrekt bij het gebruik van onze website met betrekking tot uw reisplanning:
  
  - In het geval van het reserveren van een reis op onze website vragen we om verschillende gegevens en persoonlijke gegevens zoals uw naam, telefoonnummer, e-mailadres, factuuradres, betaalmethode, informatie over uw kortingskaarten en uw creditcardgegevens voor de betaling. In dit geval zullen we nooit om andere informatie vragen dan die nodig is om het boekingsproces bij onze vervoersaanbieders te voltooien. Zonder deze gegevens kunnen we uw reservering niet verwerken. Creditcardgegevens worden rechtstreeks aan de betaalaanbieder doorgegeven en we slaan ze niet op. Uw persoonlijke gegevens met betrekking tot de reservering worden gedurende een periode van 10 jaar in onze database bewaard. Vervolgens bewaren we informatie die vereist is door handels- en belastingwetgeving voor wettelijke bewaartermijnen, meestal tien jaar na de reservering en het sluiten van het contract.
  
  - Als u een persoonlijk account aanmaakt op de Tictactrip-website met het aanmaken van een account op onze site, worden deze gegevens vervolgens opgeslagen om uw toekomstige reserveringen te vergemakkelijken. Deze gegevens blijven geregistreerd zolang u een open gebruikersaccount heeft op onze Tictactrip-website. Als u besluit uw account te verwijderen, worden uw bijbehorende persoonlijke gegevens onmiddellijk verwijderd. Potentiële boekingsgegevens en basisgegevens (bijvoorbeeld naam, adres) worden echter bewaard zoals hierboven beschreven.
  
  - Als u contact met ons opneemt via e-mail of als u een verzoek indient bij de klantenondersteuning, worden de gegevens die u ons verstrekt (bijvoorbeeld: naam, e-mail, telefoonnummer) gebruikt om u te antwoorden. Uw klantcontact- en ondersteuningsverzoeken worden gedurende een periode van 5 jaar vanaf de datum van het antwoord van onze teams geregistreerd. Handels- en belastingrechten vereisen dat we deze correspondentie tussen u en ons bewaren voor wettelijke bewaartermijnen, meestal 6 jaar.`,
  },
  content4: {
    id: `${privacyScope}.content4`,
    defaultMessage: `Si vous êtes abonné à notre newsletter, soit en vous y étant expressément inscrit sur notre site, soit suite à votre accord donné à la suite d’une réservation sur notre site, nous vous enverrons une newsletter et des emails marketing, de la publicité, des offres promotionnelles et services complémentaires (hébergement, déplacement…), ainsi que des informations sur Tictactrip.
Vous pouvez vous désinscrire de la newsletter et de notre liste d’email en indiquant que vous souhaitez retirer votre consentement à tout moment. Pour ce faire, dans chacun de nos emails se trouve un lien « désabonnement ». Dans le cadre de votre retrait de consentement concernant la réception d’emails gratuits de la part de Tictactrip, vos coordonnées seront immédiatement supprimées, sauf si vous possédez un compte utilisateur sur notre site, ou que vous avez effectué et effectuerez potentiellement des réservations sur notre site (voir ci-dessus).`,
    es: `Si está suscrito a nuestro boletín, ya sea porque se ha registrado expresamente en nuestro sitio o porque ha dado su consentimiento después de realizar una reserva en nuestro sitio, le enviaremos un boletín y correos electrónicos de marketing, publicidad, ofertas promocionales y servicios complementarios (alojamiento, desplazamientos...), así como información sobre Tictactrip.
Puede darse de baja del boletín y de nuestra lista de correo indicando que desea retirar su consentimiento en cualquier momento. Para ello, en cada uno de nuestros correos electrónicos hay un enlace "darse de baja". En el caso de retirar el consentimiento con respecto a la recepción de correos electrónicos gratuitos de Tictactrip, sus datos se eliminarán inmediatamente, a menos que tenga una cuenta de usuario en nuestro sitio o haya realizado y realice potencialmente reservas en nuestro sitio (ver arriba).

Además, la gestión del envío de correos electrónicos y del boletín se realiza exclusivamente a través del proveedor externo: Mailjet. Por lo tanto, sus datos de usuario:
- Nombre, apellido,
- Dirección de correo electrónico.
Estos datos se transmiten a un servidor de Mailjet específico para Tictactrip. Por lo tanto, estos datos solo son utilizables y accesibles para Tictactrip.

Si elige darse de baja del boletín, esta información se eliminará automáticamente del servidor de Mailjet.

Al no encontrarse en ninguno de nuestros servidores propios, no podemos aceptar la responsabilidad por cualquier mala gestión de estos datos por parte del proveedor externo Mailjet.`,
    it: `Se sei iscritto alla nostra newsletter, che tu lo abbia fatto esplicitamente sul nostro sito o abbia dato il tuo consenso in seguito a una prenotazione sul nostro sito, ti invieremo una newsletter e email di marketing, pubblicità, offerte promozionali e servizi aggiuntivi (alloggio, spostamenti...), oltre a informazioni su Tictactrip.
Puoi disiscriverti dalla newsletter e dalla nostra lista email indicando che desideri revocare il tuo consenso in qualsiasi momento. A tal fine, in ogni nostra email troverai un link "disiscriviti". Nel caso del ritiro del consenso riguardante la ricezione di email gratuite da parte di Tictactrip, le tue informazioni saranno cancellate immediatamente, a meno che tu non abbia un account utente sul nostro sito o non abbia effettuato e effettuerai potenzialmente prenotazioni sul nostro sito (vedi sopra).

Inoltre, la gestione delle email e della newsletter avviene esclusivamente tramite il fornitore terzo: Mailjet. Pertanto, i tuoi dati utente:
- Nome, cognome,
- Indirizzo email.
Questi dati vengono trasmessi a un server Mailjet specifico per Tictactrip. Pertanto, questi dati sono utilizzabili e accessibili solo da Tictactrip.

Également, la gestion des emailing et de la newsletter passe exclusivement par le fournisseur tiers : Mailjet. Ainsi, vos données d’utilisateurs : 
- Nom, prénom,
- Adresse email.
Ces données sont transmises à un serveur de Mailjet spécifique à Tictactrip. Ces données ne sont donc utilisables et accessible qu’à Tictactrip.
Se scegli di disiscriverti dalla newsletter, queste informazioni verranno automaticamente cancellate dal server Mailjet.

Si vous choisissez de vous désabonner de la newsletter, ces informations seront automatiquement supprimées du serveur Mailjet. 
Non trovandosi su uno dei nostri server proprietari, non possiamo accettare la responsabilità per eventuali problemi di gestione di questi dati da parte del fornitore terzo Mailjet.`,
    de: `Als u zich hebt ingeschreven voor onze nieuwsbrief, hetzij door u daar uitdrukkelijk voor aan te melden op onze site, hetzij door uw toestemming te geven na een boeking op onze site, sturen wij u een nieuwsbrief en marketinge-mails, reclame, aanbiedingen en aanvullende diensten (accommodatie, reizen, enz.), alsmede informatie over Tictactrip.
    U kunt zich te allen tijde afmelden voor de nieuwsbrief en onze e-maillijst door aan te geven dat u uw toestemming wilt intrekken. Om dit te doen bevat elk van onze e-mails een link "uitschrijven". Als u uw toestemming voor het ontvangen van gratis e-mails van Tictactrip intrekt, worden uw gegevens onmiddellijk verwijderd, tenzij u een gebruikersaccount op onze site hebt of boekingen op onze site hebt gemaakt en mogelijk nog gaat maken (zie hierboven).`,
    nl: `Als u zich heeft aangemeld voor onze nieuwsbrief, hetzij omdat u zich expliciet heeft geregistreerd op onze site, hetzij na uw akkoord na een reservering op onze site, sturen wij u een nieuwsbrief en marketingmails, advertenties, promotieaanbiedingen en aanvullende diensten (accommodatie, reizen...), evenals informatie over Tictactrip.
U kunt zich afmelden voor de nieuwsbrief en onze e-maillijst door aan te geven dat u op elk moment uw toestemming wilt intrekken. Daartoe vindt u in elk van onze e-mails een "uitschrijven"-link. In het kader van het intrekken van de toestemming met betrekking tot het ontvangen van gratis e-mails van Tictactrip worden uw gegevens onmiddellijk verwijderd, tenzij u een gebruikersaccount op onze site heeft of boekingen op onze site heeft gedaan en dit potentieel ook blijft doen (zie hierboven).

Ook verloopt het beheer van e-mails en de nieuwsbrief uitsluitend via de externe provider: Mailjet. Daarom worden uw gebruikersgegevens:
- Naam, voornaam,
- E-mailadres.
Deze gegevens worden doorgestuurd naar een specifieke Mailjet-server voor Tictactrip. Deze gegevens zijn dus alleen bruikbaar en toegankelijk voor Tictactrip.

Als u ervoor kiest om u af te melden voor de nieuwsbrief, worden deze gegevens automatisch verwijderd van de Mailjet-server.

Omdat ze zich niet bevinden op een van onze eigen servers, kunnen we geen verantwoordelijkheid aanvaarden voor eventueel onjuist beheer van deze gegevens door de externe provider Mailjet.`,
  },
  content5: {
    id: `${privacyScope}.content5`,
    defaultMessage: `Nous utilisons des cookies, comme la plupart des exploitants de sites web aujourd’hui, qui se déposent sur votre navigateur une fois que vous visitez notre site Tictactrip.
Les cookies sont en fait des petits fichiers .txt qui se stockent sur votre navigateur, que ce soit sur votre navigateur ou appareil mobile, après votre visite sur différents sites web.

Ces cookies ne comportent aucune donnée personnelle. En effet, certains cookies que nous utilisons sont supprimés automatiquement à la fin de votre session sur notre site, autrement dit, lorsque vous quittez notre site. Ceux-ci sont couramment appelés « cookies de sessions ». Par ailleurs, d’autres cookies restent quant à eux stockés sur votre appareil et nous permettent, à nous ou à nos partenaires commerciaux, de reconnaître votre navigateur lors de vos prochaines visites sur notre site. Ceux-ci sont couramment appelés « cookies persistants».

Ces cookies ne contiennent aucune donnée personnelle. Certains des cookies que nous utilisons sont supprimés à nouveau à l’expiration de la session, c’est-à-dire lorsque vous fermez votre navigateur (ceux-ci sont appelés cookies de session). D’autres cookies restent stockés sur votre appareil et nous permettent, ou nos partenaires commerciaux, de reconnaître votre navigateur lors de visites ultérieures (cookies persistants).

Notre utilisation des cookies nous permet de recueillir des informations utiles sur les usages de nos clients sur le site Tictactrip. De ce fait, il nous est possible de fournir un accès plus intuitif et facile à notre site web et ses différents services.

Les différents buts de notre utilisation des cookies sont :
- D’enregistrer vos paramètres d’utilisation notamment pour la langue et la monnaie utilisés pour notre site.
- Pour vous assurer une transition sûre entre nos pages de résultats du site et vos requêtes de recherche.
- Pour surveiller le rendement et le bon fonctionnement de nos systèmes (il n’y a aucune évaluation des données par les utilisateurs)
- Pour suivre les statistiques de notre site grâce à des fournisseurs de services d’analyse web. Ainsi, nous pourrons effectuer du « retargeting » et suivre les conversions qui utilisent des cookies aussi (voir ci-dessus).

Sur le site Tictactrip, si vous êtes amené à cliquer sur un lien qui vous renvoie vers e site web de l’un de nos fournisseurs (par exemple dans le cadre d’une réservation de billets que l’on ne prend pas en compte sur le site Tictactrip), il devient alors possible que d’autres site web stockent des cookies sur votre navigateur. Nous n’avons dans ce cadre, aucun contrôle sur les cookies et autres données recueillies et nous ne pouvons accepter aucune responsabilité dans ce cas.`,
    en: `We use cookies, like most website operators today, which are deposited on your browser once you visit our Tictactrip site.
Cookies are actually small .txt files that are stored on your browser, whether you're using a browser or a mobile device, after your visit to various websites.

These cookies do not contain any personal data. Indeed, some cookies that we use are automatically deleted at the end of your session on our site, in other words, when you leave our site. These are commonly referred to as "session cookies". Additionally, other cookies remain stored on your device and allow us, or our business partners, to recognize your browser during your future visits to our site. These are commonly called "persistent cookies."

These cookies do not contain any personal data. Some of the cookies we use are deleted again at the end of the session, meaning when you close your browser (these are called session cookies). Other cookies remain stored on your device and allow us, or our business partners, to recognize your browser during subsequent visits (persistent cookies).

Our use of cookies allows us to collect useful information about our customers' usage on the Tictactrip site. As a result, we can provide a more intuitive and easy access to our website and its various services.

The different purposes of our use of cookies are:
- To record your user settings, especially for the language and currency used on our site.
- To ensure a smooth transition between our site's result pages and your search queries.
- To monitor the performance and proper functioning of our systems (there is no user data evaluation)
- To track the statistics of our site through web analytics service providers. This way, we can perform "retargeting" and track conversions using cookies as well (see above).

On the Tictactrip site, if you are led to click on a link that redirects you to the website of one of our providers (for example, in the case of booking tickets that are not handled on the Tictactrip site), it becomes possible for other websites to store cookies on your browser. In this context, we have no control over the cookies and other data collected, and we cannot accept any responsibility in this case.`,
    es: `Utilizamos cookies, como la mayoría de los operadores de sitios web hoy en día, que se depositan en tu navegador una vez que visitas nuestro sitio Tictactrip.
Los cookies son, de hecho, pequeños archivos .txt que se almacenan en tu navegador, ya sea en tu navegador o dispositivo móvil, después de tu visita a diferentes sitios web.

Estos cookies no contienen datos personales. De hecho, algunos cookies que utilizamos se eliminan automáticamente al final de tu sesión en nuestro sitio, es decir, cuando abandonas nuestro sitio. Estos se llaman comúnmente "cookies de sesión". Por otro lado, otros cookies permanecen almacenados en tu dispositivo y nos permiten, a nosotros o a nuestros socios comerciales, reconocer tu navegador durante tus visitas futuras a nuestro sitio. Estos se llaman comúnmente "cookies persistentes".

Estos cookies no contienen datos personales. Algunos de los cookies que utilizamos se eliminan nuevamente al finalizar la sesión, es decir, cuando cierras tu navegador (estos se llaman cookies de sesión). Otros cookies permanecen almacenados en tu dispositivo y nos permiten, o a nuestros socios comerciales, reconocer tu navegador durante visitas posteriores (cookies persistentes).

Nuestro uso de cookies nos permite recopilar información útil sobre los hábitos de nuestros clientes en el sitio Tictactrip. De esta manera, podemos proporcionar un acceso más intuitivo y fácil al nuestro sitio web y sus diversos servicios.

Los diferentes objetivos de nuestro uso de cookies son:
- Registrar tus configuraciones de usuario, especialmente para el idioma y la moneda utilizados en nuestro sitio.
- Asegurar una transición segura entre las páginas de resultados de nuestro sitio y tus consultas de búsqueda.
- Supervisar el rendimiento y el buen funcionamiento de nuestros sistemas (no hay ninguna evaluación de datos por parte de los usuarios).
- Seguir las estadísticas de nuestro sitio a través de proveedores de servicios de análisis web. De esta manera, podemos realizar "retargeting" y seguir las conversiones que también utilizan cookies (ver arriba).

En el sitio Tictactrip, si haces clic en un enlace que te lleva al sitio web de uno de nuestros proveedores (por ejemplo, en el caso de una reserva de boletos que no se tiene en cuenta en el sitio Tictactrip), es posible que otros sitios web almacenen cookies en tu navegador. En este contexto, no tenemos control sobre las cookies y otros datos recopilados y no podemos aceptar ninguna responsabilidad en este caso.`,
    it: `Utilizziamo i cookie, come la maggior parte degli operatori di siti web oggi, che si depositano sul tuo browser una volta che visiti il nostro sito Tictactrip.
I cookie sono in realtà piccoli file .txt che si memorizzano sul tuo browser, che tu stia usando un browser o un dispositivo mobile, dopo la tua visita a diversi siti web.

Questi cookie non comportano alcun dato personale. Infatti, alcuni cookie che utilizziamo vengono eliminati automaticamente alla fine della tua sessione sul nostro sito, cioè quando lasci il nostro sito. Questi sono comunemente chiamati "cookie di sessione". Altri cookie, d'altra parte, rimangono memorizzati sul tuo dispositivo e ci consentono, a noi o ai nostri partner commerciali, di riconoscere il tuo browser durante le tue future visite al nostro sito. Questi sono comunemente chiamati "cookie persistenti".

Questi cookie non contengono alcun dato personale. Alcuni dei cookie che utilizziamo vengono eliminati nuovamente alla scadenza della sessione, cioè quando chiudi il tuo browser (questi sono chiamati cookie di sessione). Altri cookie rimangono memorizzati sul tuo dispositivo e ci consentono, o ai nostri partner commerciali, di riconoscere il tuo browser durante le visite successive (cookie persistenti).

Il nostro utilizzo dei cookie ci consente di raccogliere informazioni utili sui comportamenti dei nostri clienti sul sito Tictactrip. In questo modo, possiamo fornire un accesso più intuitivo e facile al nostro sito web e ai suoi vari servizi.

Gli obiettivi principali del nostro utilizzo dei cookie sono:
- Registrare le tue impostazioni utente, in particolare per la lingua e la valuta utilizzate sul nostro sito.
- Garantire una transizione sicura tra le pagine dei risultati del nostro sito e le tue richieste di ricerca.
- Monitorare le prestazioni e il corretto funzionamento dei nostri sistemi (non c'è alcuna valutazione dei dati da parte degli utenti).
- Seguire le statistiche del nostro sito attraverso fornitori di servizi di analisi web. In questo modo, possiamo effettuare il "retargeting" e seguire le conversioni che utilizzano anche i cookie (vedi sopra).

Sul sito Tictactrip, se clicchi su un link che ti rimanda al sito web di uno dei nostri fornitori (ad esempio, nel caso di una prenotazione di biglietti che non viene gestita sul sito Tictactrip), è possibile che altri siti web memorizzino cookie sul tuo browser. In questo contesto, non abbiamo alcun controllo sui cookie e altri dati raccolti, e non possiamo accettare alcuna responsabilità in questo caso.`,

    de: `Wir verwenden Cookies, wie die meisten Betreiber von Websites heute, die auf Ihrem Browser abgelegt werden, sobald Sie unsere Tictactrip-Website besuchen.
Cookies sind tatsächlich kleine .txt-Dateien, die auf Ihrem Browser gespeichert werden, unabhängig davon, ob Sie einen Browser oder ein mobiles Gerät verwenden, nach Ihrem Besuch auf verschiedenen Websites.

Diese Cookies enthalten keine persönlichen Daten. Einige Cookies, die wir verwenden, werden automatisch am Ende Ihrer Sitzung auf unserer Website gelöscht, das heißt, wenn Sie unsere Website verlassen. Diese werden allgemein als "Sitzungscookies" bezeichnet. Andererseits bleiben andere Cookies auf Ihrem Gerät gespeichert und ermöglichen es uns oder unseren Geschäftspartnern, Ihren Browser bei Ihren zukünftigen Besuchen auf unserer Website zu erkennen. Diese werden allgemein als "dauerhafte Cookies" bezeichnet.

Diese Cookies enthalten keine persönlichen Daten. Einige der von uns verwendeten Cookies werden erneut am Ende der Sitzung gelöscht, das heißt, wenn Sie Ihren Browser schließen (diese werden als Sitzungscookies bezeichnet). Andere Cookies bleiben auf Ihrem Gerät gespeichert und ermöglichen es uns oder unseren Geschäftspartnern, Ihren Browser bei zukünftigen Besuchen zu erkennen (dauerhafte Cookies).

Unsere Verwendung von Cookies ermöglicht es uns, nützliche Informationen über das Nutzerverhalten unserer Kunden auf der Tictactrip-Website zu sammeln. Auf diese Weise können wir einen intuitiveren und einfacheren Zugang zu unserer Website und ihren verschiedenen Diensten bieten.

Die verschiedenen Zwecke unserer Verwendung von Cookies sind:
- Ihre Benutzereinstellungen zu speichern, insbesondere für die auf unserer Website verwendete Sprache und Währung.
- Einen reibungslosen Übergang zwischen den Ergebnisseiten unserer Website und Ihren Suchanfragen zu gewährleisten.
- Die Leistung und den ordnungsgemäßen Betreibung unserer Systeme zu überwachen (es gibt keine Bewertung von Benutzerdaten)
- Die Statistiken unserer Website über Webanalyse-Dienstleister zu verfolgen. Auf diese Weise können wir "Retargeting" durchführen und Konversionen verfolgen, die ebenfalls Cookies verwenden (siehe oben).

Auf der Tictactrip-Website, wenn Sie dazu veranlasst werden, auf einen Link zu klicken, der Sie auf die Website eines unserer Anbieter weiterleitet (zum Beispiel im Falle der Buchung von Tickets, die nicht auf der Tictactrip-Website behandelt werden), besteht die Möglichkeit, dass andere Websites Cookies auf Ihrem Browser speichern. In diesem Zusammenhang haben wir keine Kontrolle über die gespeicherten Cookies und sonstigen Daten, und wir können keine Verantwortung in diesem Fall übernehmen.`,
    nl: `Wij gebruiken cookies, net als de meeste exploitanten van websites vandaag de dag, die zich op uw browser nestelen zodra u onze Tictactrip-website bezoekt.
Cookies zijn eigenlijk kleine .txt-bestanden die op uw browser worden opgeslagen, of u nu een browser of een mobiel apparaat gebruikt, na uw bezoek aan verschillende websites.

Deze cookies bevatten geen persoonlijke gegevens. Sommige cookies die we gebruiken, worden inderdaad automatisch verwijderd aan het einde van uw sessie op onze site, met andere woorden, wanneer u onze site verlaat. Deze worden doorgaans "sessiecookies" genoemd. Aan de andere kant blijven andere cookies op uw apparaat opgeslagen en stellen ze ons of onze zakelijke partners in staat om uw browser te herkennen tijdens uw volgende bezoeken aan onze site. Deze worden doorgaans "persistente cookies" genoemd.

Deze cookies bevatten geen persoonlijke gegevens. Sommige van de cookies die we gebruiken, worden opnieuw verwijderd aan het einde van de sessie, dat wil zeggen wanneer u uw browser sluit (deze worden sessiecookies genoemd). Andere cookies blijven op uw apparaat opgeslagen en stellen ons of onze zakelijke partners in staat om uw browser te herkennen tijdens latere bezoeken (persistente cookies).

Ons gebruik van cookies stelt ons in staat nuttige informatie te verzamelen over het gebruik van onze klanten op de Tictactrip-website. Hierdoor kunnen we een meer intuïtieve en gemakkelijke toegang tot onze website en de verschillende services bieden.

De verschillende doelen van ons gebruik van cookies zijn:
- Het registreren van uw gebruikersinstellingen, met name voor de taal en valuta die op onze site worden gebruikt.
- Het zorgen voor een veilige overgang tussen de resultatenpagina's van onze site en uw zoekopdrachten.
- Het monitoren van de prestaties en het goede functioneren van onze systemen (er is geen evaluatie van gegevens door gebruikers)
- Het volgen van de statistieken van onze site via leveranciers van webanalyse-services. Op deze manier kunnen we "retargeting" uitvoeren en conversies volgen die ook cookies gebruiken (zie hierboven).

Op de Tictactrip-website, als u wordt gevraagd om op een link te klikken die u doorverwijst naar de website van een van onze leveranciers (bijvoorbeeld bij het boeken van tickets die niet worden verwerkt op de Tictactrip-website), is het mogelijk dat andere websites cookies op uw browser opslaan. In dit verband hebben we geen controle over de opgeslagen cookies en andere verzamelde gegevens, en we kunnen geen verantwoordelijkheid aanvaarden in dit geval.`,
  },
  content6: {
    id: `${privacyScope}.content6`,
    defaultMessage: `Sur le site Tictactrip, nous utilisons des « retargeting tags », en français, des étiquettes de re-ciblage marketing, afin de vous proposer de la publicité ciblée liée à vos intérêts, sur des sites web tiers.

Le « retargeting tag » est le nom donné à l’élément JavaScript placé dans le code source de notre site web. Dans le cas où vous visiteriez une page de notre site contenant cette même balise, un fournisseur de publicité en ligne « retargeting provider » en anglais (exemple : Google ou Facebook), place un cookie sur votre navigateur et l’associe à un groupe d’audience cible, qui aura les même intérêts que vous.

Le fournisseur de publicité ciblée utilise alors les cookies pour rassembler des données relatives à vos intérêts, à votre profil de consommateur :par exemple grâce aux pages visité sur Tictactrip, et les requêtes de recherches comme si vous aviez recherché un trajet Paris Lyon. Pour cela, il vous attribuera un pseudonyme correspondant à un « profil type ». Grâce à ces informations, le « retargeting provider » affichera des annonces publicitaires ciblées pour vous et basées sur vos intérêts définis suite à vos visites de sites web tiers. Cependant, aucune donnée d’identification personnelle directe n’est stockée ou transmise par qui que ce soit et à qui que ce soit. Les « profils de consommation » ne seront pas non plus fusionnés avec vos données personnelles. Le fournisseur de publicité ciblée peut potentiellement vous identifier et suivre vos visites sur notre site web.`,
    en: `On the Tictactrip website, we use "retargeting tags" to offer you targeted advertising linked to your interests on third-party websites.

The "retargeting tag" is the name given to the JavaScript element placed in the source code of our website. When you visit a page on our site containing this tag, a retargeting provider (e.g. Google or Facebook) places a cookie on your browser and associates it with a target audience group that has the same interests as you.
The targeted advertising provider then uses cookies to gather data about your interests and consumer profile: for example, the pages you visit on Tictactrip, and search queries such as Paris-Lyon. To do this, it will assign you a pseudonym corresponding to a "typical profile". Using this information, the "retargeting provider" will display advertisements targeted to you and based on your interests as defined following your visits to third-party websites. However, no direct personal identification data is stored or transmitted by anyone to anyone. Nor will "consumer profiles" be merged with your personal data. The targeted advertising provider may potentially identify you and track your visits to our website.
`,
    es: `En el sitio web de Tictactrip, utilizamos etiquetas de retargeting para ofrecerle publicidad personalizada vinculada a sus intereses en sitios web de terceros.

La "etiqueta de retargeting" es el nombre que recibe el elemento JavaScript colocado en el código fuente de nuestro sitio web. Si visita una página de nuestro sitio que contiene esta etiqueta, un proveedor de retargeting (por ejemplo, Google o Facebook) coloca una cookie en su navegador y lo asocia a un grupo de público objetivo que tiene los mismos intereses que usted.

A continuación, el proveedor de publicidad dirigida utiliza cookies para recopilar datos relativos a sus intereses y a su perfil de consumidor: por ejemplo, gracias a las páginas que visita en Tictactrip, y a las solicitudes de búsqueda como, por ejemplo, si busca un trayecto de París a Lyon. Para ello, le asignará un seudónimo correspondiente a un "perfil típico". Utilizando esta información, el "proveedor de retargeting" le mostrará anuncios dirigidos a usted y basados en sus intereses definidos tras sus visitas a sitios web de terceros. Sin embargo, nadie almacena ni transmite a nadie datos de identificación personal directa. Tampoco se fusionarán "perfiles de consumidores" con sus datos personales. El proveedor de publicidad dirigida puede potencialmente identificarle y hacer un seguimiento de sus visitas a nuestro sitio web.
`,
    it: `Sul sito web di Tictactrip utilizziamo tag di retargeting per offrirvi pubblicità mirata legata ai vostri interessi su siti web di terzi.

Il "tag di retargeting" è il nome dato all'elemento JavaScript inserito nel codice sorgente del nostro sito web. Se l'utente visita una pagina del nostro sito contenente questo tag, un fornitore di servizi di retargeting (ad esempio Google o Facebook) inserisce un cookie nel suo browser e lo associa a un gruppo di pubblico target che ha i suoi stessi interessi.

Il fornitore di pubblicità mirata utilizza quindi i cookie per raccogliere dati relativi ai vostri interessi e al vostro profilo di consumatori: ad esempio, grazie alle pagine che visitate su Tictactrip e alle richieste di ricerca, come se steste cercando un viaggio da Parigi a Lione. A tal fine, vi assegnerà uno pseudonimo corrispondente a un "profilo tipico". Utilizzando queste informazioni, il "fornitore di retargeting" visualizzerà annunci pubblicitari mirati all'utente e basati sui suoi interessi definiti in seguito alle sue visite a siti web di terzi. Tuttavia, nessun dato di identificazione personale diretta viene memorizzato o trasmesso da nessuno a nessuno. Inoltre, i "profili dei consumatori" non verranno uniti ai vostri dati personali. Il fornitore di pubblicità mirata può potenzialmente identificarvi e tracciare le vostre visite al nostro sito web.
`,
    de: `Auf der Tictactrip-Website verwenden wir sogenannte Retargeting-Tags, um Ihnen auf Websites von Drittanbietern gezielte, interessenbezogene Werbung anzuzeigen.

Retargeting-Tag" ist die Bezeichnung für ein JavaScript-Element, das im Quellcode unserer Website platziert wird. Wenn Sie eine Seite unserer Website besuchen, die dieses Tag enthält, setzt ein Anbieter von Online-Werbung (z. B. Google oder Facebook) ein Cookie in Ihrem Browser und ordnet Sie einer Zielgruppe zu, die die gleichen Interessen wie Sie hat.

Der Anbieter der gezielten Werbung verwendet dann Cookies, um Daten über Ihre Interessen und Ihr Verbraucherprofil zu sammeln: z. B. über die Seiten, die Sie auf Tictactrip besucht haben, und über Suchanfragen, z. B. wenn Sie nach einer Fahrt von Paris nach Lyon gesucht haben. Zu diesem Zweck wird er Ihnen ein Pseudonym zuweisen, das einem "typischen Profil" entspricht. Anhand dieser Informationen zeigt der "Retargeting-Provider" für Sie gezielte Werbeanzeigen an, die auf Ihren Interessen basieren, die Sie aufgrund Ihrer Besuche auf Websites Dritter festgelegt haben. Es werden jedoch keine direkten persönlich identifizierbaren Daten gespeichert oder von wem auch immer und an wen auch immer weitergegeben. Auch die "Nutzungsprofile" werden nicht mit Ihren persönlichen Daten zusammengeführt. Der Anbieter von zielgerichteter Werbung kann Sie potenziell identifizieren und Ihre Besuche auf unserer Website verfolgen.`,

    nl: `De aanbieder van gerichte reclame maakt vervolgens gebruik van cookies om gegevens te verzamelen met betrekking tot uw interesses en uw consumentenprofiel: bijvoorbeeld dankzij de pagina's die u op Tictactrip bezoekt en zoekopdrachten, bijvoorbeeld als u op zoek bent naar een reis van Parijs naar Lyon. Hiervoor wordt u een pseudoniem toegewezen dat overeenkomt met een "typisch profiel". Met behulp van deze informatie zal de "retargeting provider" advertenties weergeven die op u zijn gericht en gebaseerd zijn op uw interesses zoals gedefinieerd naar aanleiding van uw bezoeken aan websites van derden. Er worden echter geen directe persoonlijke identificatiegegevens opgeslagen of doorgegeven door wie dan ook aan wie dan ook. Er worden ook geen "consumentenprofielen" samengevoegd met uw persoonlijke gegevens. De aanbieder van gerichte reclame kan u mogelijk identificeren en uw bezoeken aan onze website volgen.

Op de website van Tictactrip maken wij gebruik van "retargeting tags" om u gerichte reclame aan te bieden die gekoppeld is aan uw interesses op websites van derden.

De "retargeting tag" is de naam van het JavaScript-element dat in de broncode van onze website wordt geplaatst. Als je een pagina op onze site bezoekt die deze tag bevat, plaatst een retargetingprovider (bijv. Google of Facebook) een cookie op je browser en associeert deze met een doelgroep die dezelfde interesses heeft als jij.De aanbieder van gerichte reclame maakt vervolgens gebruik van cookies om gegevens te verzamelen met betrekking tot uw interesses en uw consumentenprofiel: bijvoorbeeld dankzij de pagina's die u op Tictactrip bezoekt en zoekopdrachten, bijvoorbeeld als u op zoek bent naar een reis van Parijs naar Lyon. Hiervoor wordt u een pseudoniem toegewezen dat overeenkomt met een "typisch profiel". Met behulp van deze informatie zal de "retargeting provider" advertenties weergeven die op u zijn gericht en gebaseerd zijn op uw interesses zoals gedefinieerd naar aanleiding van uw bezoeken aan websites van derden. Er worden echter geen directe persoonlijke identificatiegegevens opgeslagen of doorgegeven door wie dan ook aan wie dan ook. Er worden ook geen "consumentenprofielen" samengevoegd met uw persoonlijke gegevens. De aanbieder van gerichte reclame kan u mogelijk identificeren en uw bezoeken aan onze website volgen.
`,
  },
  content7: {
    id: `${privacyScope}.content7`,
    defaultMessage: `Sur notre site web Tictactrip nous utilisons Google Analytics qui est un service d’analyse web à la disposition de tous grâce à Google LLC. . Google utilise des cookies (voir plus haut), qui sont stockés, une nouvelle fois, sur votre navigateur et nous permettent par la suite d’analyser simplement l’utilisation que vous faites de notre site web.

Les informations générées par les cookies d’utilisation de Google, y compris pour les utilisateurs actuels (sur le site au même moment que l’analyse), mais sans adresse IP définie (elle sera tronquée, voir juste après), sont transmises à un serveur Google aux États-Unis et stockées là-bas. Nous avons activé « l’anonymisation des IP », votre adresse IP sera tronquée par Google au sein d’un État membre de l’Union Européenne ou de l’espace économique Européen afin d’éviter la fuite de données à l’extérieur de l’Union Européenne.

En outre, Google peut fournir ces données à des tiers si la loi l’exige ou si ces tiers traitent ces données pour le compte de Google. L’adresse IP envoyée par votre navigateur à Google Analytics ne sera pas combinée avec d’autre données de Google. 

Il vous est utile de savoir que vous pouvez choisir d’empêcher l’installation de cookies sur votre navigateur en changeant les paramètres de dernier. Néanmoins, veuillez noter aussi que cette interdiction d’installation de cookies peut empêcher le bon fonctionnement de différentes fonctionnalités des services Tictactrip sur notre site. Aussi, il vous est envisageable d’empêcher la collecte de vos données de cookies et données d’utilisation (y compris pour l’adresse IP) par Google en téléchargeant et utilisant le plugin de navigateur disponible ici : <a href="https://tools.google.com/dlpage/gaoptout" target="_blank">https://tools.google.com/dlpage/gaoptout</a> .

Les données personnelles associées aux cookies comme les identificateurs d’utilisateur (User-ID) et les identificateurs publicitaires (cookies DoubleClick et autres par exemple) seront supprimés automatiquement après 14 mois.

Il en va de même pour tous ces différents points avec l’utilisation de Google Tag Manager, autre outil de Google.

Notre utilisation de Google Analytics est en conformité avec les exigences convenues entre les règlementations de protection des données de l’État français et Google. Voici plus d’informations complémentaires : Google Dublin, Google Ireland Ltd., Gordon House, Barrow Street, Dublin 4, Irlande, Fax : +353 (1) 436 1001.

Termes d’utilisation : <a href="https://marketingplatform.google.com/about/analytics/terms/fr/" target="_blank">https://marketingplatform.google.com/about/analytics/terms/fr/</a>
Politique de confidentialité : <a href="https://policies.google.com/privacy" target="_blank">https://policies.google.com/privacy</a>

Google est d’autant plus contraint par la protection européenne des données UE-États-Unis : <a href="https://www.privacyshield.gov/EU-US-Framework" target="_blank">https://www.privacyshield.gov/EU-US-Framework</a>
`,
    en: `On our Tictactrip website, we use Google Analytics, which is a web analytics service provided by Google LLC. Google uses cookies (see above), which are stored once again on your browser and allow us to analyze how you use our website.

The information generated by Google's usage cookies, including for current users (on the site at the same time as the analysis) but without a defined IP address (it will be truncated, see just after), is transmitted to a Google server in the United States and stored there. We have activated "IP anonymization"; your IP address will be truncated by Google within a member state of the European Union or the European Economic Area to prevent data leakage outside the European Union.

In addition, Google may provide this data to third parties if required by law or if these third parties process this data on behalf of Google. The IP address sent by your browser to Google Analytics will not be combined with other Google data.

It is useful for you to know that you can choose to prevent the installation of cookies on your browser by changing its settings. However, please note that this prohibition of cookie installation may prevent the proper functioning of various Tictactrip services on our site. Also, you can prevent the collection of your cookie and usage data (including IP address) by Google by downloading and using the browser plugin available here: <a href="https://tools.google.com/dlpage/gaoptout" target="_blank">https://tools.google.com/dlpage/gaoptout</a>.

Personal data associated with cookies, such as user identifiers (User-ID) and advertising identifiers (DoubleClick cookies and others, for example), will be automatically deleted after 14 months.

The same applies to all these different points with the use of Google Tag Manager, another tool from Google.

Our use of Google Analytics complies with the agreements between the data protection regulations of the French state and Google. Here is additional information: Google Dublin, Google Ireland Ltd., Gordon House, Barrow Street, Dublin 4, Ireland, Fax: +353 (1) 436 1001.

Terms of use: <a href="https://marketingplatform.google.com/about/analytics/terms/en/" target="_blank">https://marketingplatform.google.com/about/analytics/terms/en/</a>
Privacy policy: <a href="https://policies.google.com/privacy" target="_blank">https://policies.google.com/privacy</a>

Google is further bound by the European data protection EU-US: <a href="https://www.privacyshield.gov/EU-US-Framework" target="_blank">https://www.privacyshield.gov/EU-US-Framework</a>
`,
    es: `En nuestro sitio web Tictactrip utilizamos Google Analytics, que es un servicio de análisis web disponible para todos gracias a Google LLC. Google utiliza cookies (ver arriba), que se almacenan, una vez más, en su navegador y nos permiten analizar simplemente el uso que hace de nuestro sitio web.

La información generada por las cookies de uso de Google, incluidos los usuarios actuales (en el sitio al mismo tiempo que el análisis), pero sin una dirección IP definida (se truncará, ver justo después), se transmite a un servidor de Google en Estados Unidos y se almacena allí. Hemos activado la "anonimización de IP"; su dirección IP se truncará por Google dentro de un estado miembro de la Unión Europea o del Espacio Económico Europeo para evitar la fuga de datos fuera de la Unión Europea.

Además, Google puede proporcionar estos datos a terceros si la ley lo exige o si estos terceros procesan estos datos en nombre de Google. La dirección IP enviada por su navegador a Google Analytics no se combinará con otros datos de Google.

Le resultará útil saber que puede optar por evitar la instalación de cookies en su navegador cambiando su configuración. Sin embargo, tenga en cuenta que esta prohibición de la instalación de cookies puede evitar el buen funcionamiento de varias funciones de los servicios de Tictactrip en nuestro sitio. Además, puede evitar la recopilación de sus datos de cookies y datos de uso (incluida la dirección IP) por parte de Google descargando y utilizando el complemento del navegador disponible aquí: <a href="https://tools.google.com/dlpage/gaoptout" target="_blank">https://tools.google.com/dlpage/gaoptout</a>.

Los datos personales asociados con las cookies, como los identificadores de usuario (User-ID) y los identificadores publicitarios (cookies DoubleClick y otros, por ejemplo), se eliminarán automáticamente después de 14 meses.

Lo mismo se aplica a todos estos puntos diferentes con el uso de Google Tag Manager, otra herramienta de Google.

Nuestro uso de Google Analytics cumple con los acuerdos entre las regulaciones de protección de datos del estado francés y Google. Aquí hay información adicional: Google Dublin, Google Ireland Ltd., Gordon House, Barrow Street, Dublín 4, Irlanda, Fax: +353 (1) 436 1001.

Términos de uso: <a href="https://marketingplatform.google.com/about/analytics/terms/es/" target="_blank">https://marketingplatform.google.com/about/analytics/terms/es/</a>
Política de privacidad: <a href="https://policies.google.com/privacy" target="_blank">https://policies.google.com/privacy</a>

Google está más vinculado por la protección de datos europea UE-EE. UU .: <a href="https://www.privacyshield.gov/EU-US-Framework" target="_blank">https://www.privacyshield.gov/EU-US-Framework</a>
`,
    it: `Sul nostro sito web Tictactrip utilizziamo Google Analytics, che è un servizio di analisi web a disposizione di tutti grazie a Google LLC. Google utilizza i cookie (vedi sopra), che vengono memorizzati, ancora una volta, sul tuo browser e ci consentono in seguito di analizzare semplicemente l'utilizzo che fai del nostro sito web.

Le informazioni generate dai cookie di utilizzo di Google, compresi gli utenti attuali (sul sito nello stesso momento dell'analisi), ma senza un indirizzo IP definito (sarà troncato, vedi subito dopo), vengono trasmesse a un server di Google negli Stati Uniti e lì memorizzate. Abbiamo attivato la "anonimizzazione dell'IP"; il tuo indirizzo IP sarà troncato da Google all'interno di uno stato membro dell'Unione europea o dello Spazio economico europeo per evitare la fuoriuscita dei dati al di fuori dell'Unione europea.

Inoltre, Google potrebbe fornire questi dati a terzi se la legge lo richiede o se questi terzi elaborano questi dati per conto di Google. L'indirizzo IP inviato dal tuo browser a Google Analytics non sarà abbinato ad altri dati di Google.

Ti sarà utile sapere che puoi scegliere di impedire l'installazione di cookie sul tuo browser modificando le relative impostazioni. Tuttavia, tieni presente che questa proibizione dell'installazione di cookie potrebbe impedire il corretto funzionamento di varie funzionalità dei servizi Tictactrip sul nostro sito. Inoltre, puoi impedire la raccolta dei tuoi dati di cookie e di utilizzo (compreso l'indirizzo IP) da parte di Google scaricando e utilizzando il plugin del browser disponibile qui: <a href="https://tools.google.com/dlpage/gaoptout" target="_blank">https://tools.google.com/dlpage/gaoptout</a>.

I dati personali associati ai cookie, come gli identificatori utente (User-ID) e gli identificatori pubblicitari (cookie DoubleClick e altri, ad esempio), verranno automaticamente eliminati dopo 14 mesi.

Lo stesso vale per tutti questi diversi punti con l'uso di Google Tag Manager, un altro strumento di Google.

Il nostro utilizzo di Google Analytics è conforme agli accordi tra le normative sulla protezione dei dati dello stato francese e Google. Ecco ulteriori informazioni: Google Dublino, Google Ireland Ltd., Gordon House, Barrow Street, Dublino 4, Irlanda, Fax: +353 (1) 436 1001.

Condizioni d'uso: <a href="https://marketingplatform.google.com/about/analytics/terms/it/" target="_blank">https://marketingplatform.google.com/about/analytics/terms/it/</a>
Informativa sulla privacy: <a href="https://policies.google.com/privacy" target="_blank">https://policies.google.com/privacy</a>

Google è ulteriormente vincolato dallo scudo europeo per la protezione dei dati UE-USA: <a href="https://www.privacyshield.gov/EU-US-Framework" target="_blank">https://www.privacyshield.gov/EU-US-Framework</a>
`,
    de: `Auf unserer Website Tictactrip verwenden wir Google Analytics, einen Webanalysedienst, der allen durch Google LLC zur Verfügung steht. Google verwendet Cookies (siehe oben), die erneut in Ihrem Browser gespeichert werden und es uns ermöglichen, die Nutzung unserer Website einfach zu analysieren.

Die Informationen, die durch die Verwendung von Google-Cookies generiert werden, einschließlich für aktuelle Benutzer (auf der Website zum gleichen Zeitpunkt wie die Analyse), jedoch ohne eine festgelegte IP-Adresse (diese wird gekürzt, siehe gleich danach), werden an einen Google-Server in den USA übertragen und dort gespeichert. Wir haben die "IP-Anonymisierung" aktiviert; Ihre IP-Adresse wird von Google innerhalb eines Mitgliedstaats der Europäischen Union oder des Europäischen Wirtschaftsraums gekürzt, um den Datenaustritt aus der Europäischen Union zu vermeiden.

Darüber hinaus kann Google diese Daten Dritten zur Verfügung stellen, wenn das Gesetz dies verlangt oder wenn diese Dritten diese Daten im Auftrag von Google verarbeiten. Die IP-Adresse, die Ihr Browser an Google Analytics sendet, wird nicht mit anderen Google-Daten kombiniert.

Es ist für Sie nützlich zu wissen, dass Sie die Installation von Cookies in Ihrem Browser verhindern können, indem Sie dessen Einstellungen ändern. Beachten Sie jedoch auch, dass dieses Verbot der Installation von Cookies die ordnungsgemäße Funktion verschiedener Funktionen der Tictactrip-Dienste auf unserer Website verhindern kann. Außerdem können Sie die Erfassung Ihrer Cookie- und Nutzungsdaten (einschließlich IP-Adresse) durch Google verhindern, indem Sie das Browser-Plugin hier herunterladen und verwenden: <a href="https://tools.google.com/dlpage/gaoptout" target="_blank">https://tools.google.com/dlpage/gaoptout</a>.

Personenbezogene Daten, die mit Cookies verbunden sind, wie Benutzerkennungen (User-ID) und Werbekennungen (DoubleClick-Cookies und andere, zum Beispiel), werden automatisch nach 14 Monaten gelöscht.

Gleiches gilt für all diese verschiedenen Punkte bei der Verwendung von Google Tag Manager, einem anderen Tool von Google.

Unsere Verwendung von Google Analytics entspricht den Vereinbarungen zwischen den Datenschutzbestimmungen des französischen Staates und Google. Hier finden Sie weitere Informationen: Google Dublin, Google Ireland Ltd., Gordon House, Barrow Street, Dublin 4, Irland, Fax: +353 (1) 436 1001.

Nutzungsbedingungen: <a href="https://marketingplatform.google.com/about/analytics/terms/de/" target="_blank">https://marketingplatform.google.com/about/analytics/terms/de/</a>
Datenschutzrichtlinie: <a href="https://policies.google.com/privacy" target="_blank">https://policies.google.com/privacy</a>

Google ist umso mehr durch den europäischen Datenschutz EU-USA gebunden: <a href="https://www.privacyshield.gov/EU-US-Framework" target="_blank">https://www.privacyshield.gov/EU-US-Framework</a>
`,
    nl: `Op onze website Tictactrip maken we gebruik van Google Analytics, een webanalyse-service die beschikbaar is voor iedereen dankzij Google LLC. Google maakt gebruik van cookies (zie hierboven), die opnieuw worden opgeslagen in uw browser en ons vervolgens in staat stellen eenvoudig het gebruik van onze website te analyseren.

De informatie gegenereerd door de gebruikte Google-cookies, inclusief voor huidige gebruikers (op de site op hetzelfde moment als de analyse), maar zonder een vastgesteld IP-adres (dat wordt afgekapt, zie hierna), wordt verzonden naar een Google-server in de Verenigde Staten en daar opgeslagen. We hebben "IP-anonimisering" ingeschakeld; uw IP-adres wordt door Google afgekapt binnen een lidstaat van de Europese Unie of de Europese Economische Ruimte om gegevenslekken buiten de Europese Unie te voorkomen.

Bovendien kan Google deze gegevens aan derden verstrekken als de wet dat vereist of als deze derden deze gegevens namens Google verwerken. Het IP-adres dat uw browser naar Google Analytics stuurt, wordt niet gecombineerd met andere gegevens van Google.

Het is handig om te weten dat u de installatie van cookies in uw browser kunt voorkomen door de instellingen te wijzigen. Houd er echter ook rekening mee dat dit verbod op het installeren van cookies het goede functioneren van verschillende functies van de Tictactrip-services op onze site kan verhinderen. Ook kunt u voorkomen dat Google uw cookie- en gebruiksgegevens (inclusief IP-adres) verzamelt door de browserplug-in hier te downloaden en te gebruiken: <a href="https://tools.google.com/dlpage/gaoptout" target="_blank">https://tools.google.com/dlpage/gaoptout</a>.

Persoonsgegevens die zijn gekoppeld aan cookies, zoals gebruikersidentificatoren (User-ID) en advertentie-identificatoren (DoubleClick-cookies en andere, bijvoorbeeld), worden automatisch verwijderd na 14 maanden.

Ditzelfde geldt voor al deze verschillende punten bij het gebruik van Google Tag Manager, een ander hulpmiddel van Google.

Ons gebruik van Google Analytics voldoet aan de overeenkomsten tussen de gegevensbeschermingsvoorschriften van de Franse staat en Google. Hier vindt u meer informatie: Google Dublin, Google Ireland Ltd., Gordon House, Barrow Street, Dublin 4, Ierland, Fax: +353 (1) 436 1001.

Gebruiksvoorwaarden: <a href="https://marketingplatform.google.com/about/analytics/terms/nl/" target="_blank">https://marketingplatform.google.com/about/analytics/terms/nl/</a>
Privacybeleid: <a href="https://policies.google.com/privacy" target="_blank">https://policies.google.com/privacy</a>

Google is des te meer gebonden door de Europese gegevensbescherming EU-VS: <a href="https://www.privacyshield.gov/EU-US-Framework" target="_blank">https://www.privacyshield.gov/EU-US-Framework</a>
`,
  },
  content8: {
    id: `${privacyScope}.content8`,
    defaultMessage: `Seul le blog de Tictactrip possède ce type de service. Les fournisseurs de médias sociaux nomment ces service : des plugins sociaux.

Pour que vous puissiez utiliser ce type de service et réagir sur les réseaux sociaux à nos blogposts, nous utilisons la solution nommée « 2-click ». Si vous vous rendez sur le blog Tictactrip, aucune donnée personnelle ne sera transférée au fournisseur du plugin. Vous reconnaitrez le fournisseur du plugin par le logo du bouton. Grâce à ce même bouton, vous pourrez communiquer directement avec le fournisseur.

Si vous cliquez sur ce bouton et activez par la suite le plugin, le fournisseur aura dès lors accès aux informations de page visitée sur notre site web. Cela signifie que les informations mentionnées plus haut seront transmises à ce fournisseur.

Si vous disposez d’un compte sur le réseau social dont il est question et que vous êtes connecté, le fournisseur pourra lier votre visite sur notre blog à votre interaction avec le plugin social (exemple : si vous cliquez sur le bouton « J’aime »), à votre compte utilisateur sur le réseau en question (dans cet exemple il s’agirait de votre compte Facebook).
Nous n’avons aucun contrôle et aucune influence sur les données récoltées et traitées par la suite par le fournisseur du plugin et nous ne connaissons pas l’envergure de l’étendue des traitements de données, les objectifs et les conditions de conservation et suppression de ces données qu’en fait le fournisseur du plugin. `,
    een: `Only the Tictactrip blog has this type of service. Social media providers call these services: social plugins.

    To enable you to use this type of service and interact on social networks with our blog posts, we use the solution called "2-click." When you visit the Tictactrip blog, no personal data will be transferred to the plugin provider. You can recognize the plugin provider by the button's logo. Through the same button, you can communicate directly with the provider.
    
    If you click on this button and subsequently activate the plugin, the provider will then have access to the information about the page visited on our website. This means that the information mentioned above will be transmitted to this provider.
    
    If you have an account on the social network in question and you are logged in, the provider may link your visit to our blog to your interaction with the social plugin (for example, if you click the "Like" button), to your user account on the respective network (in this example, your Facebook account).
    We have no control or influence over the data collected and processed later by the plugin provider, and we do not know the extent of the data processing, the purposes, and the conditions for storing and deleting this data by the plugin provider.
    `,
    es: `Solo el blog de Tictactrip tiene este tipo de servicio. Los proveedores de redes sociales llaman a estos servicios: plugins sociales.

    Para que pueda utilizar este tipo de servicio e interactuar en las redes sociales con nuestras publicaciones de blog, utilizamos la solución llamada "2-clics". Cuando visita el blog de Tictactrip, no se transferirán datos personales al proveedor del plugin. Puede reconocer al proveedor del plugin por el logotipo del botón. A través del mismo botón, puede comunicarse directamente con el proveedor.
    
    Si hace clic en este botón y activa posteriormente el plugin, el proveedor tendrá acceso a la información sobre la página visitada en nuestro sitio web. Esto significa que la información mencionada anteriormente se transmitirá a este proveedor.
    
    Si tiene una cuenta en la red social en cuestión y está conectado, el proveedor puede vincular su visita a nuestro blog con su interacción con el plugin social (por ejemplo, si hace clic en el botón "Me gusta"), con su cuenta de usuario en la red respectiva (en este ejemplo, su cuenta de Facebook).
    No tenemos control ni influencia sobre los datos recopilados y procesados posteriormente por el proveedor del plugin, y no conocemos la extensión del procesamiento de datos, los objetivos y las condiciones de almacenamiento y eliminación de estos datos por parte del proveedor del plugin.
    `,
    it: `Solo il blog di Tictactrip dispone di questo tipo di servizio. I fornitori di social media chiamano questi servizi: plugin sociali.

    Per consentirti di utilizzare questo tipo di servizio e interagire sui social network con i nostri articoli del blog, utilizziamo la soluzione chiamata "2-click". Quando visiti il blog di Tictactrip, nessun dato personale verrà trasferito al fornitore del plugin. Puoi riconoscere il fornitore del plugin dal logo del pulsante. Attraverso lo stesso pulsante, puoi comunicare direttamente con il fornitore.
    
    Se fai clic su questo pulsante e attivi successivamente il plugin, il fornitore avrà accesso alle informazioni sulla pagina visitata sul nostro sito web. Ciò significa che le informazioni menzionate sopra saranno trasmesse a questo fornitore.
    
    Se hai un account sul social network in questione e sei connesso, il fornitore può collegare la tua visita al nostro blog alla tua interazione con il plugin sociale (ad esempio, se fai clic sul pulsante "Mi piace"), al tuo account utente sulla rete in questione (in questo esempio, il tuo account Facebook).
    Non abbiamo alcun controllo o influenza sui dati raccolti e successivamente elaborati dal fornitore del plugin, e non conosciamo l'estensione del trattamento dei dati, gli obiettivi e le condizioni di archiviazione ed eliminazione di questi dati da parte del fornitore del plugin.
    `,
    de: `Nur der Blog von Tictactrip verfügt über diese Art von Dienst. Die Anbieter von sozialen Medien nennen diese Dienste: soziale Plugins.

    Damit Sie diesen Dienst nutzen und auf sozialen Netzwerken auf unsere Blogbeiträge reagieren können, verwenden wir die Lösung namens "2-Klick". Wenn Sie den Blog von Tictactrip besuchen, werden keine persönlichen Daten an den Plugin-Anbieter übertragen. Sie erkennen den Plugin-Anbieter am Logo des Buttons. Über denselben Button können Sie direkt mit dem Anbieter kommunizieren.
    
    Wenn Sie auf diesen Button klicken und anschließend das Plugin aktivieren, hat der Anbieter Zugriff auf die Informationen zur besuchten Seite auf unserer Website. Das bedeutet, dass die oben genannten Informationen an diesen Anbieter übertragen werden.
    
    Wenn Sie ein Konto im betreffenden sozialen Netzwerk haben und angemeldet sind, kann der Anbieter Ihren Besuch auf unserem Blog mit Ihrer Interaktion mit dem sozialen Plugin verknüpfen (zum Beispiel wenn Sie auf den "Gefällt mir" Button klicken), mit Ihrem Benutzerkonto im jeweiligen Netzwerk (in diesem Beispiel Ihr Facebook-Konto).
    Wir haben keine Kontrolle oder Einfluss auf die später vom Plugin-Anbieter gesammelten und verarbeiteten Daten, und wir kennen den Umfang der Datenverarbeitung, die Zwecke und die Bedingungen für die Speicherung und Löschung dieser Daten durch den Plugin-Anbieter nicht.
    `,
    nl: `Alleen de Tictactrip-blog heeft dit soort service. Leveranciers van sociale media noemen deze services: sociale plugins.

    Om u in staat te stellen gebruik te maken van dit type service en te reageren op sociale netwerken op onze blogposts, gebruiken we de oplossing genaamd "2-click". Wanneer u de Tictactrip-blog bezoekt, worden er geen persoonlijke gegevens overgedragen aan de plug-in-provider. U herkent de plug-in-provider aan het logo van de knop. Via dezelfde knop kunt u rechtstreeks communiceren met de provider.
    
    Als u op deze knop klikt en vervolgens de plug-in activeert, heeft de provider toegang tot informatie over de bezochte pagina op onze website. Dit betekent dat de hierboven genoemde informatie naar deze provider wordt verzonden.
    
    Als u een account heeft op het betreffende sociale netwerk en u bent ingelogd, kan de provider uw bezoek aan onze blog koppelen aan uw interactie met de sociale plug-in (bijvoorbeeld als u op de knop "Vind ik leuk" klikt), aan uw gebruikersaccount op het betreffende netwerk (in dit voorbeeld uw Facebook-account).
    We hebben geen controle of invloed op de gegevens die later door de plug-in-provider worden verzameld en verwerkt, en we kennen de omvang van de gegevensverwerking, de doelen en de voorwaarden voor het opslaan en verwijderen van deze gegevens door de plug-in-provider niet.
    `,
  },
  content9: {
    id: `${privacyScope}.content9`,
    defaultMessage:
      'Désormais, nous allons nous intéresser à la transmission de vos données personnelles afin de savoir qui y a accès après votre utilisation et/ou réservation sur le site Tictactrip ! On sait que c’est important pour vous, on en fait une priorité chez nous !',
    en: `Now, we will focus on the transmission of your personal data to understand who has access to it after your use and/or reservation on the Tictactrip website! We know it's important to you, and we prioritize it here!
      `,
    es: 'Ahora nos centraremos en la transmisión de sus datos personales para entender quién tiene acceso a ellos después de su uso y/o reserva en el sitio web de Tictactrip. ¡Sabemos que es importante para ti, lo priorizamos aquí!      ',
    it: 'Ora ci concentreremo sulla trasmissione dei tuoi dati personali per capire chi ha accesso ad essi dopo la tua utilizzazione e/o prenotazione sul sito di Tictactrip! Sappiamo che è importante per te, lo mettiamo al primo posto qui!      ',
    de: 'Nun werden wir uns auf die Übertragung Ihrer persönlichen Daten konzentrieren, um herauszufinden, wer darauf zugreifen kann, nachdem Sie die Tictactrip-Website genutzt und/oder reserviert haben! Wir wissen, dass dies für Sie wichtig ist, und wir stellen es hier in den Vordergrund!      ',
    nl: 'Nu zullen we ons richten op de overdracht van uw persoonlijke gegevens om te begrijpen wie er toegang toe heeft na uw gebruik en/of reservering op de Tictactrip-website! We weten dat het belangrijk voor u is, en we geven er hier prioriteit aan!      ',
  },
  content10: {
    id: `${privacyScope}.content10`,
    defaultMessage: `Tictactrip est avant tout un comparateur de bus, train et covoiturage. De ce fait, nous n’avions, au début du moins, pas la possibilité de proposer le paiement intégré, directement sur le site. Aujourd’hui, nous proposons le paiement intégré mais nous n’avons pas qualification à émettre nous même vos billets de transport. Nous devons alors passer par nos fournisseurs de transport et réserver votre place auprès d’eux.

C’est pourquoi, il est nécessaire et même obligatoire que nous transmettions à ces fournisseurs partenaires (compagnies de bus, compagnies de train et compagnies de covoiturage), vos données personnelles requise et uniquement celles requise, nous préférons insister. Jamais nous ne leur fournirons vos données personnelles dont il n’ont pas besoins pour réserver un trajet. Une fois ces données personnelles nécessaires transmises, le fournisseur de transport éditera votre billet et nous vous l’enverrons. 

Ces fournisseurs de transport sont alors des tiers qui engagent leur responsabilité quant au traitement de vos données personnelles (comme si vous aviez directement réservé vos billets sur leurs sites) et traiteront vos données conformément à leurs propres politiques de confidentialité.

Pour certains pays tels que la Russie, l’Ukraine ou la Pologne, les réservations sont traitées par notre partenaire de service : Amadeus IT Group SA (Salvador de Madariaga 1, Madrid 28027, Espagne) ainsi que ses sociétés affiliées. Pour ce type de transactions, nous nous devons de transmettre vos donnés de réservation à Amadeus, et une fois encore uniquement ces données essentielles à la réservation même, qui les traite par la suite sous sa propre responsabilité conformément à sa propre politique de confidentialité et de protection des données.

<a href="https://amadeus.com/fr/politiques/amadeus-et-le-rgpd" target="_blank">https://amadeus.com/fr/politiques/amadeus-et-le-rgpd</a>

Ce transfert de données requiert que votre consentement nous soit donné pour chaque processus de réservation, en accord avec l’art. 6(1)(a) RGPD.`,
    en: `Tictactrip is primarily a bus, train, and carpool comparator. Therefore, initially, we did not have the ability to offer integrated payment directly on the site. Today, we offer integrated payment, but we are not qualified to issue your transport tickets ourselves. We must go through our transport providers and book your seat with them.

For this reason, it is necessary and even obligatory that we transmit to these partner providers (bus companies, train companies, and carpooling companies) your required personal data and only the data required, we prefer to emphasize. We will never provide them with your personal data that they do not need to book a trip. Once these necessary personal data are transmitted, the transport provider will issue your ticket, and we will send it to you.

These transport providers are then third parties who are responsible for the processing of your personal data (as if you had directly booked your tickets on their sites) and will process your data in accordance with their own privacy policies.

For some countries such as Russia, Ukraine, or Poland, reservations are processed by our service partner: Amadeus IT Group SA (Salvador de Madariaga 1, Madrid 28027, Spain) and its affiliated companies. For this type of transactions, we are required to transmit your reservation data to Amadeus, and once again, only this essential data for the reservation itself, which it subsequently processes under its own responsibility in accordance with its own privacy and data protection policy.

<a href="https://amadeus.com/fr/politiques/amadeus-et-le-rgpd" target="_blank">https://amadeus.com/fr/politiques/amadeus-et-le-rgpd</a>

This data transfer requires your consent to be given to us for each booking process, in accordance with Art. 6(1)(a) GDPR.
`,
    es: `Tictactrip es principalmente un comparador de autobuses, trenes y coche compartido. Por lo tanto, al principio, no teníamos la posibilidad de ofrecer el pago integrado directamente en el sitio. Hoy en día, ofrecemos el pago integrado, pero no tenemos la calificación para emitir sus boletos de transporte nosotros mismos. Por lo tanto, debemos pasar por nuestros proveedores de transporte y reservar su lugar con ellos.

Por esta razón, es necesario e incluso obligatorio que transmitamos a estos proveedores asociados (empresas de autobuses, empresas de trenes y empresas de coche compartido) sus datos personales requeridos y solo aquellos necesarios, preferimos insistir en esto. Nunca les proporcionaremos sus datos personales que no necesiten para reservar un viaje. Una vez transmitidos estos datos personales necesarios, el proveedor de transporte emitirá su boleto y se lo enviaremos.

Estos proveedores de transporte son terceros que asumen la responsabilidad del tratamiento de sus datos personales (como si hubiera reservado sus boletos directamente en sus sitios) y tratarán sus datos de acuerdo con sus propias políticas de privacidad.

Para algunos países como Rusia, Ucrania o Polonia, las reservas son procesadas por nuestro socio de servicio: Amadeus IT Group SA (Salvador de Madariaga 1, Madrid 28027, España) y sus empresas afiliadas. Para este tipo de transacciones, debemos transmitir sus datos de reserva a Amadeus, y una vez más, solo estos datos esenciales para la reserva en sí, que luego procesa bajo su propia responsabilidad de acuerdo con su propia política de privacidad y protección de datos.

<a href="https://amadeus.com/fr/politiques/amadeus-et-le-rgpd" target="_blank">https://amadeus.com/fr/politiques/amadeus-et-le-rgpd</a>

Esta transferencia de datos requiere que se nos otorgue su consentimiento para cada proceso de reserva, de acuerdo con el art. 6(1)(a) del RGPD.
`,
    it: `Tictactrip è prima di tutto un comparatore di bus, treni e carpooling. Di conseguenza, inizialmente, non avevamo la possibilità di offrire il pagamento integrato direttamente sul sito. Oggi offriamo il pagamento integrato, ma non siamo qualificati per emettere i biglietti di trasporto direttamente. Dobbiamo quindi passare attraverso i nostri fornitori di trasporto e prenotare il tuo posto con loro.

Per questo motivo, è necessario e persino obbligatorio che trasmettiamo a questi fornitori partner (compagnie di autobus, compagnie ferroviarie e compagnie di carpooling) i tuoi dati personali richiesti e solo quelli necessari, preferiamo sottolinearlo. Non forniremo mai loro i tuoi dati personali di cui non hanno bisogno per prenotare un viaggio. Una volta trasmessi questi dati personali necessari, il fornitore di trasporto emetterà il tuo biglietto e te lo invieremo.

Questi fornitori di trasporto sono quindi terze parti che assumono la responsabilità del trattamento dei tuoi dati personali (come se avessi prenotato direttamente i tuoi biglietti sui loro siti) e tratteranno i tuoi dati in conformità con le loro politiche sulla privacy.

Per alcuni paesi come Russia, Ucraina o Polonia, le prenotazioni sono elaborate dal nostro partner di servizio: Amadeus IT Group SA (Salvador de Madariaga 1, Madrid 28027, Spagna) e le sue società affiliate. Per questo tipo di transazioni, dobbiamo trasmettere i tuoi dati di prenotazione ad Amadeus, e ancora una volta, solo questi dati essenziali per la prenotazione stessa, che verranno successivamente elaborati sotto la sua responsabilità in conformità con la sua politica sulla privacy e protezione dei dati.

<a href="https://amadeus.com/fr/politiques/amadeus-et-le-rgpd" target="_blank">https://amadeus.com/fr/politiques/amadeus-et-le-rgpd</a>

Questa trasmissione di dati richiede il tuo consenso per ogni processo di prenotazione, in conformità all'art. 6(1)(a) del GDPR.
`,
    de: `Tictactrip est principalement un comparateur de bus, de trains et de covoiturage. Par conséquent, au début du moins, nous n'avions pas la possibilité d'offrir le paiement intégré directement sur le site. Aujourd'hui, nous proposons le paiement intégré, mais nous ne sommes pas qualifiés pour émettre vos billets de transport nous-mêmes. Nous devons donc passer par nos fournisseurs de transport et réserver votre place auprès d'eux.

C'est pourquoi il est nécessaire, voire obligatoire, que nous transmettions à ces fournisseurs partenaires (compagnies de bus, compagnies de train et compagnies de covoiturage) vos données personnelles requises et uniquement celles requises, nous préférons insister. Nous ne leur fournirons jamais vos données personnelles dont ils n'ont pas besoin pour réserver un trajet. Une fois ces données personnelles nécessaires transmises, le fournisseur de transport éditera votre billet et nous vous l'enverrons.

Ces fournisseurs de transport sont donc des tiers qui assument la responsabilité du traitement de vos données personnelles (comme si vous aviez réservé directement vos billets sur leurs sites) et traiteront vos données conformément à leurs propres politiques de confidentialité.

Pour certains pays tels que la Russie, l'Ukraine ou la Pologne, les réservations sont traitées par notre partenaire de service : Amadeus IT Group SA (Salvador de Madariaga 1, Madrid 28027, Espagne) ainsi que ses sociétés affiliées. Pour ce type de transactions, nous devons transmettre vos données de réservation à Amadeus, et une fois encore, uniquement ces données essentielles à la réservation elle-même, qui les traite par la suite sous sa propre responsabilité conformément à sa propre politique de confidentialité et de protection des données.

<a href="https://amadeus.com/fr/politiques/amadeus-et-le-rgpd" target="_blank">https://amadeus.com/fr/politiques/amadeus-et-le-rgpd</a>

Ce transfert de données requiert que votre consentement nous soit donné pour chaque processus de réservation, en accord avec l'art. 6(1)(a) RGPD.
`,
    nl: `Tictactrip is in de eerste plaats een vergelijker van bus-, trein- en carpoolopties. Daarom hadden we aanvankelijk niet de mogelijkheid om geïntegreerde betaling rechtstreeks op de site aan te bieden. Tegenwoordig bieden we geïntegreerde betaling aan, maar we zijn niet gekwalificeerd om zelf uw vervoersbewijzen uit te geven. We moeten dus gebruikmaken van onze transportpartners en uw plek bij hen reserveren.

Daarom is het noodzakelijk en zelfs verplicht dat we deze partnerverstrekkers (busmaatschappijen, spoorwegmaatschappijen en carpoolbedrijven) uw vereiste persoonlijke gegevens en alleen de benodigde gegevens doorgeven, we benadrukken dit liever. We zullen hen nooit uw persoonlijke gegevens verstrekken die ze niet nodig hebben om een reis te boeken. Zodra deze noodzakelijke persoonlijke gegevens zijn doorgegeven, zal de transportaanbieder uw ticket uitgeven en sturen wij het naar u op.

Deze transportaanbieders zijn dus derde partijen die verantwoordelijk zijn voor de verwerking van uw persoonlijke gegevens (alsof u uw tickets rechtstreeks op hun sites had geboekt) en zullen uw gegevens verwerken in overeenstemming met hun eigen privacybeleid.

Voor sommige landen zoals Rusland, Oekraïne of Polen worden reserveringen verwerkt door onze servicepartner: Amadeus IT Group SA (Salvador de Madariaga 1, Madrid 28027, Spanje) en zijn gelieerde ondernemingen. Voor dit soort transacties moeten we uw reserveringsgegevens doorgeven aan Amadeus, en nogmaals, alleen deze essentiële gegevens voor de reservering zelf, die vervolgens onder zijn eigen verantwoordelijkheid worden verwerkt in overeenstemming met zijn eigen privacy- en gegevensbeschermingsbeleid.

<a href="https://amadeus.com/fr/politiques/amadeus-et-le-rgpd" target="_blank">https://amadeus.com/fr/politiques/amadeus-et-le-rgpd</a>

Deze gegevensoverdracht vereist dat uw toestemming ons wordt gegeven voor elk reserveringsproces, in overeenstemming met art. 6(1)(a) AVG.
`,
  },
  content11: {
    id: `${privacyScope}.content11`,
    defaultMessage: `Les informations concernant les détails de votre paiement (les informations concernant votre carte de crédit) pour les réservations que vous effectuez directement sur le site Tictactrip, sont traitées par un processeur de paiement Internet de confiance, qui transmettra de lui-même directement ces données à la société de carte de crédit concernée.

Pour s’assurer que la carte de crédit est utilisée en toute légitimité et de prévenir les potentiels cas de fraude, les informations chiffrées des cartes de crédit sont transférées un service de sécurisation des paiements, extérieur à Tictactrip, à des fins de vérification. Parmi ces informations, des données personnelles supplémentaires peuvent être incluses.

Toutes les données concernant les cartes de crédits suite à une réservation sur notre site, sont traitées par des processeurs de paiement Internet de qualité et de confiance. Toutes les données concernant vos informations de carte de crédit sont chiffrées (la plupart du temps à l’aide du protocole SSL) avant de les transmettre au processeur de paiement. Cela permet d’éviter tout risque. Vos données ne seront en aucun cas communiquées par les fournisseurs de solution de paiement.`,
    en: `Your payment details (credit card information) for bookings you make directly on the Tictactrip site are processed by a trusted Internet payment processor, which will in turn transmit this data directly to the relevant credit card company.

To ensure that the credit card is used legitimately and to prevent potential cases of fraud, encrypted credit card information is transferred to a payment security service, external to Tictactrip, for verification purposes. This information may include additional personal data.

All data concerning credit cards following a reservation on our site is processed by high quality and trusted Internet payment processors. All data concerning your credit card information is encrypted (in most cases using SSL protocol) before being transmitted to the payment processor. This prevents any risk. Under no circumstances will your data be passed on to payment solution providers.`,
    es: `La información sobre los detalles de su pago (información sobre su tarjeta de crédito) para las reservas que realiza directamente en el sitio de Tictactrip es procesada por un procesador de pagos en línea de confianza, que transmitirá directamente estos datos a la compañía de tarjeta de crédito correspondiente.

Para asegurarse de que la tarjeta de crédito se utilice legítimamente y prevenir posibles casos de fraude, la información cifrada de las tarjetas de crédito se transfiere a un servicio de seguridad de pagos externo a Tictactrip para su verificación. Entre esta información, pueden incluirse datos personales adicionales.

Todos los datos relacionados con las tarjetas de crédito después de una reserva en nuestro sitio son procesados por procesadores de pagos en línea de calidad y confianza. Todos los datos sobre su información de tarjeta de crédito están cifrados (la mayoría de las veces con el protocolo SSL) antes de transmitirse al procesador de pagos. Esto evita cualquier riesgo. Sus datos no serán comunicados en ningún caso por los proveedores de soluciones de pago.
`,
    it: `Le informazioni sui dettagli del suo pagamento (le informazioni sulla sua carta di credito) per le prenotazioni effettuate direttamente sul sito Tictactrip sono elaborate da un processore di pagamento online affidabile, che trasmetterà direttamente questi dati alla società della carta di credito interessata.

Per garantire che la carta di credito venga utilizzata legittimamente e prevenire potenziali casi di frode, le informazioni criptate delle carte di credito vengono trasferite a un servizio di sicurezza dei pagamenti esterno a Tictactrip per scopi di verifica. Tra queste informazioni potrebbero essere incluse informazioni personali aggiuntive.

Tutti i dati relativi alle carte di credito dopo una prenotazione sul nostro sito sono elaborati da processori di pagamento online affidabili e di qualità. Tutti i dati relativi alle informazioni della sua carta di credito sono cifrati (per lo più con il protocollo SSL) prima di essere trasmessi al processore di pagamento. Ciò evita qualsiasi rischio. I suoi dati non saranno in nessun caso comunicati dai fornitori di soluzioni di pagamento.
`,
    de: `Die Informationen zu den Einzelheiten Ihrer Zahlung (Informationen zu Ihrer Kreditkarte) für die Reservierungen, die Sie direkt auf der Website von Tictactrip vornehmen, werden von einem vertrauenswürdigen Online-Zahlungsprozessor verarbeitet, der diese Daten direkt an das jeweilige Kreditkartenunternehmen übermittelt.

Um sicherzustellen, dass die Kreditkarte legitim verwendet wird und potenzielle Betrugsfälle zu verhindern, werden die verschlüsselten Informationen der Kreditkarten zu einem externen Zahlungssicherungsdienst übertragen, der für Überprüfungszwecke verwendet wird. Unter diesen Informationen können zusätzliche personenbezogene Daten enthalten sein.

Alle Daten im Zusammenhang mit Kreditkarten nach einer Reservierung auf unserer Website werden von zuverlässigen und vertrauenswürdigen Online-Zahlungsprozessoren verarbeitet. Alle Daten zu Ihren Kreditkarteninformationen sind verschlüsselt (meistens mit dem SSL-Protokoll), bevor sie an den Zahlungsprozessor übertragen werden. Dies vermeidet jedes Risiko. Ihre Daten werden in keinem Fall von den Zahlungslösungsanbietern weitergegeben.
`,
    nl: `De informatie over de details van uw betaling (informatie over uw creditcard) voor de reserveringen die u rechtstreeks op de Tictactrip-website maakt, wordt verwerkt door een betrouwbare online betaalprocessor, die deze gegevens rechtstreeks zal doorgeven aan het betreffende creditcardbedrijf.

Om ervoor te zorgen dat de creditcard legitiem wordt gebruikt en potentiële fraudegevallen te voorkomen, worden de versleutelde gegevens van creditcards overgedragen aan een externe betaalbeveiligingsservice, voor verificatiedoeleinden. Onder deze informatie kunnen aanvullende persoonlijke gegevens worden opgenomen.

Alle gegevens met betrekking tot creditcards na een reservering op onze site worden verwerkt door betrouwbare en kwalitatieve online betaalprocessors. Alle gegevens over uw creditcardinformatie zijn versleuteld (meestal met het SSL-protocol) voordat ze naar de betaalprocessor worden verzonden. Dit voorkomt elk risico. Uw gegevens worden in geen geval doorgegeven door de betalingsoplossingsproviders.
`,
  },
  content12: {
    id: `${privacyScope}.content12`,
    defaultMessage: `Il est possible dans certaines mesures, que nous fassions appel à des fournisseurs de services tiers, particulièrement pour des besoins techniques, commerciaux, juridiques et fiscaux. Également, il peut potentiellement s’agir de fournisseurs d’hébergement web, d’un réseau de fournisseur pour mettre en cache du contenu et le créer, de fournisseurs de solutions d’analyses et de statistiques, des fournisseurs pour nous aider dans la gestion des demandes de supports, la fourniture de systèmes CRM, toute la gestion centralisée des newsletter et des services de mails marketing (voir « Les emails marketing et newsletters » plus haut), les solutions contre le spam et les fraude ainsi que les fournisseurs de services pour les technologie de l’information (IT) et les services de développement.

L’ensemble de ces différents fournisseurs reçoivent des données personnelles uniquement dans le cadre de l’exécution de leur services, pour nous et en notre nom. Ils sont tenus par le contrat entre eux et nous, de ne pas utiliser les données à caractère personnel à d’autres fins.`,
    en: `To some extent, we may engage third-party service providers, especially for technical, business, legal, and tax-related needs. It may also potentially involve web hosting providers, a network of providers for caching and content creation, analytics and statistics solutions providers, providers to assist us in managing support requests, CRM system providers, centralized management of newsletters, and email marketing services (see "Email Marketing and Newsletters" above), spam and fraud prevention solutions, as well as service providers for information technology (IT) and development services.

All these various providers receive personal data only in the execution of their services, for us and on our behalf. They are bound by the contract between them and us not to use personal data for other purposes.
`,
    es: `En cierta medida, es posible que recurramos a proveedores de servicios externos, especialmente para necesidades técnicas, comerciales, legales y fiscales. También puede tratarse potencialmente de proveedores de alojamiento web, una red de proveedores para almacenar en caché y crear contenido, proveedores de soluciones de análisis y estadísticas, proveedores para ayudarnos en la gestión de solicitudes de soporte, proveedores de sistemas CRM, gestión centralizada de boletines y servicios de marketing por correo electrónico (ver "Marketing por Correo Electrónico y Boletines" arriba), soluciones de prevención de spam y fraude, así como proveedores de servicios para tecnología de la información (TI) y servicios de desarrollo.

Todos estos diversos proveedores reciben datos personales solo en el marco de la ejecución de sus servicios, para nosotros y en nuestro nombre. Están obligados por el contrato entre ellos y nosotros a no utilizar los datos personales con fines distintos.
`,
    it: `In certa misura, potremmo avvalerci di fornitori di servizi terzi, specialmente per esigenze tecniche, commerciali, legali e fiscali. Potrebbe anche coinvolgere potenzialmente fornitori di hosting web, una rete di fornitori per la memorizzazione nella cache e la creazione di contenuti, soluzioni di analisi e statistiche, fornitori per aiutarci nella gestione delle richieste di supporto, fornitori di sistemi CRM, gestione centralizzata di newsletter e servizi di marketing via email (vedi "Email Marketing e Newsletter" sopra), soluzioni di prevenzione dello spam e frodi, nonché fornitori di servizi per tecnologia dell'informazione (IT) e servizi di sviluppo.

Tutti questi vari fornitori ricevono dati personali solo nell'ambito dell'esecuzione dei loro servizi, per nostro conto. Sono vincolati dal contratto tra loro e noi a non utilizzare i dati personali per altri scopi.
`,
    de: `In gewissem Umfang können wir auf Dienstleister von Drittanbietern zurückgreifen, insbesondere für technische, geschäftliche, rechtliche und steuerliche Bedürfnisse. Es kann sich auch potenziell um Webhosting-Anbieter, ein Netzwerk von Anbietern für das Zwischenspeichern und Erstellen von Inhalten, Anbieter von Analyse- und Statistiklösungen, Anbieter handeln, die uns bei der Bearbeitung von Supportanfragen unterstützen, CRM-Systemanbieter, zentralisierte Verwaltung von Newslettern und E-Mail-Marketing-Dienstleistungen (siehe oben "E-Mail-Marketing und Newsletter"), Spam- und Betrugspräventionslösungen sowie Dienstleister für Informationstechnologie (IT) und Entwicklungsdienstleistungen.

Alle diese verschiedenen Anbieter erhalten personenbezogene Daten nur im Rahmen der Durchführung ihrer Dienstleistungen für uns und in unserem Namen. Sie sind vertraglich verpflichtet, die personenbezogenen Daten nicht für andere Zwecke zu verwenden.
`,
    nl: `In zekere mate kunnen we een beroep doen op externe dienstverleners, met name voor technische, zakelijke, juridische en fiscale behoeften. Het kan ook potentieel gaan om webhostingproviders, een netwerk van providers voor het cachen en creëren van inhoud, analyse- en statistiekoplossingen, providers om ons te helpen bij het beheren van ondersteuningsverzoeken, CRM-systeemproviders, gecentraliseerd beheer van nieuwsbrieven en e-mailmarketingdiensten (zie "E-mailmarketing en Nieuwsbrieven" hierboven), spam- en fraudepreventieoplossingen, evenals dienstverleners voor informatietechnologie (IT) en ontwikkelingsdiensten.

Al deze verschillende providers ontvangen persoonlijke gegevens alleen in het kader van de uitvoering van hun diensten, voor ons en namens ons. Ze zijn contractueel gebonden om de persoonlijke gegevens niet voor andere doeleinden te gebruiken.
`,
  },
  content13: {
    id: `${privacyScope}.content13`,
    defaultMessage: `Les différents traitements de données évoqués précédemment peuvent éventuellement entraîner la transmission de données à des destinataires situés en dehors de l’Union Européenne. Bien entendu, cette transmission de données à l’étranger se fait en conformité avec les règlements sur la protection de la vie privée, voir ce lien : <a href="https://www.privacyshield.gov/welcome" target="_blank">https://www.privacyshield.gov/welcome</a>. Également, cette transmission est aussi basée sur les contrats types de l’Union Européenne, voir ici : <a href="https://ec.europa.eu/info/law/law-topic/data-protection_en" target="_blank">https://ec.europa.eu/info/law/law-topic/data-protection_en</a>.
Vos informations personnelles peuvent aussi être transférées à des fournisseurs de transport situés dans des pays hors Union Européenne, dans lesquels le voyage est prévu (bien que nous ne traitions que des pays d’Europe). Tictactrip ne peut cependant fournir d’autres informations complémentaires sur la protection des données offerte par ces pays tiers. Aucune décision d’adéquation, conformément à l’article 45 alinéa 3 RGPD ou de garanties appropriées, conformément à l’article 46 RGPD, ne sont nécessaires pour ces transferts de données. Cependant il existe deux cas, où cela est nécessaire : 
- Pour l’exécution d’un contrat entre vous et Tictactrip, ou bien même dans le cadre de mesures pré-contractuelles prises suite à votre demande (article 49 - 1 - b, RGPD).
- Pour la conclusion ou l’exécution d’un contrat conclu dans votre propre intérêt entre Tictactrip et personne physique ou morale tiers (article 49 - 1 - c, RGPD).`,
    en: `The various data processing activities mentioned earlier may potentially involve the transmission of data to recipients located outside the European Union. Of course, this foreign data transmission is done in accordance with privacy regulations; see this link: <a href="https://www.privacyshield.gov/welcome" target="_blank">https://www.privacyshield.gov/welcome</a>. Additionally, this transmission is also based on the European Union's standard contractual clauses; see here: <a href="https://ec.europa.eu/info/law/law-topic/data-protection_en" target="_blank">https://ec.europa.eu/info/law/law-topic/data-protection_en</a>.
Your personal information may also be transferred to transport providers located in countries outside the European Union, where the journey is planned (although we only process European countries). Tictactrip, however, cannot provide additional information on the data protection offered by these third countries. No adequacy decision, in accordance with Article 45(3) GDPR, or appropriate safeguards, in accordance with Article 46 GDPR, are required for these data transfers. However, there are two cases where this is necessary:
- For the performance of a contract between you and Tictactrip, or even in the context of pre-contractual measures taken at your request (Article 49(1)(b), GDPR).
- For the conclusion or performance of a contract concluded in your own interest between Tictactrip and a natural or legal person third party (Article 49(1)(c), GDPR).
`,
    es: `Las diferentes actividades de procesamiento de datos mencionadas anteriormente pueden implicar eventualmente la transmisión de datos a destinatarios ubicados fuera de la Unión Europea. Por supuesto, esta transmisión de datos al extranjero se realiza de conformidad con las regulaciones de privacidad; consulte este enlace: <a href="https://www.privacyshield.gov/welcome" target="_blank">https://www.privacyshield.gov/welcome</a>. Además, esta transmisión también se basa en las cláusulas contractuales estándar de la Unión Europea; vea aquí: <a href="https://ec.europa.eu/info/law/law-topic/data-protection_en" target="_blank">https://ec.europa.eu/info/law/law-topic/data-protection_en</a>.
Su información personal también puede transferirse a proveedores de transporte ubicados en países fuera de la Unión Europea, donde se planifica el viaje (aunque solo procesamos países europeos). Sin embargo, Tictactrip no puede proporcionar información adicional sobre la protección de datos ofrecida por estos países terceros. No se requiere ninguna decisión de adecuación, de conformidad con el artículo 45(3) del RGPD, ni garantías apropiadas, de conformidad con el artículo 46 del RGPD, para estas transferencias de datos. Sin embargo, hay dos casos en los que esto es necesario:
- Para la ejecución de un contrato entre usted y Tictactrip, o incluso en el contexto de medidas precontractuales tomadas a su solicitud (artículo 49(1)(b) del RGPD).
- Para la conclusión o ejecución de un contrato celebrado en su propio interés entre Tictactrip y una persona física o jurídica tercera (artículo 49(1)(c) del RGPD).
`,
    it: `Le varie attività di elaborazione dati menzionate in precedenza possono comportare eventualmente la trasmissione di dati a destinatari situati al di fuori dell'Unione Europea. Naturalmente, questa trasmissione di dati all'estero avviene in conformità con le normative sulla privacy; vedere questo link: <a href="https://www.privacyshield.gov/welcome" target="_blank">https://www.privacyshield.gov/welcome</a>. Inoltre, questa trasmissione si basa anche sulle clausole contrattuali standard dell'Unione Europea; vedere qui: <a href="https://ec.europa.eu/info/law/law-topic/data-protection_en" target="_blank">https://ec.europa.eu/info/law/law-topic/data-protection_en</a>.
Le tue informazioni personali possono anche essere trasferite a fornitori di trasporto situati in paesi al di fuori dell'Unione Europea, dove è previsto il viaggio (anche se trattiamo solo paesi europei). Tuttavia, Tictactrip non può fornire ulteriori informazioni sulla protezione dei dati offerta da questi paesi terzi. Non è richiesta alcuna decisione di adeguatezza, ai sensi dell'articolo 45, paragrafo 3, del GDPR, né garanzie adeguate, ai sensi dell'articolo 46 del GDPR, per questi trasferimenti di dati. Tuttavia, ci sono due casi in cui questo è necessario:
- Per l'esecuzione di un contratto tra te e Tictactrip, o anche nel contesto di misure precontrattuali adottate su tua richiesta (articolo 49, paragrafo 1, lettera b, del GDPR).
- Per la conclusione o l'esecuzione di un contratto concluso nel tuo proprio interesse tra Tictactrip e una persona fisica o giuridica terza (articolo 49, paragrafo 1, lettera c, del GDPR).
`,
    de: `Die verschiedenen oben erwähnten Datenverarbeitungsaktivitäten können möglicherweise die Übertragung von Daten an Empfänger außerhalb der Europäischen Union nach sich ziehen. Selbstverständlich erfolgt diese Übertragung von Daten ins Ausland in Übereinstimmung mit den Datenschutzbestimmungen; siehe diesen Link: <a href="https://www.privacyshield.gov/welcome" target="_blank">https://www.privacyshield.gov/welcome</a>. Diese Übertragung basiert auch auf den Standardvertragsklauseln der Europäischen Union; siehe hier: <a href="https://ec.europa.eu/info/law/law-topic/data-protection_en" target="_blank">https://ec.europa.eu/info/law/law-topic/data-protection_en</a>.
Ihre persönlichen Informationen können auch an Transportanbieter in Ländern außerhalb der Europäischen Union übertragen werden, in denen die Reise geplant ist (obwohl wir nur europäische Länder verarbeiten). Tictactrip kann jedoch keine zusätzlichen Informationen zum Datenschutz bieten, die von diesen Drittländern angeboten werden. Für diese Datenübertragungen sind weder ein Angemessenheitsbeschluss gemäß Artikel 45 Absatz 3 der DSGVO noch geeignete Garantien gemäß Artikel 46 der DSGVO erforderlich. Es gibt jedoch zwei Fälle, in denen dies erforderlich ist:
- Für die Erfüllung eines Vertrags zwischen Ihnen und Tictactrip, oder sogar im Rahmen von vorvertraglichen Maßnahmen, die auf Ihre Anfrage hin getroffen wurden (Artikel 49 Absatz 1 Buchstabe b DSGVO).
- Für den Abschluss oder die Durchführung eines Vertrags, der in Ihrem eigenen Interesse zwischen Tictactrip und einer natürlichen oder juristischen Person Dritter geschlossen wurde (Artikel 49 Absatz 1 Buchstabe c DSGVO).
`,
    nl: `De verschillende eerder genoemde gegevensverwerkingsactiviteiten kunnen mogelijk de overdracht van gegevens aan ontvangers buiten de Europese Unie met zich meebrengen. Deze buitenlandse gegevensoverdracht gebeurt uiteraard in overeenstemming met privacyregelgeving; zie deze link: <a href="https://www.privacyshield.gov/welcome" target="_blank">https://www.privacyshield.gov/welcome</a>. Daarnaast is deze overdracht ook gebaseerd op de standaardcontractuele clausules van de Europese Unie; zie hier: <a href="https://ec.europa.eu/info/law/law-topic/data-protection_en" target="_blank">https://ec.europa.eu/info/law/law-topic/data-protection_en</a>.
Uw persoonlijke informatie kan ook worden overgedragen aan transportproviders in landen buiten de Europese Unie, waar de reis is gepland (hoewel we alleen Europese landen verwerken). Tictactrip kan echter geen aanvullende informatie verstrekken over de gegevensbescherming die deze derde landen bieden. Voor deze gegevensoverdrachten zijn geen adequaatheidsbeslissingen vereist overeenkomstig artikel 45, lid 3, AVG, noch passende waarborgen overeenkomstig artikel 46 AVG. Er zijn echter twee gevallen waarin dit noodzakelijk is:
- Voor de uitvoering van een overeenkomst tussen u en Tictactrip, of zelfs in het kader van precontractuele maatregelen genomen op uw verzoek (artikel 49, lid 1, onder b, AVG).
- Voor de sluiting of uitvoering van een contract dat in uw eigen belang is gesloten tussen Tictactrip en een natuurlijke of rechtspersoon derde partij (artikel 49, lid 1, onder c, AVG).
`,
  },
  content14: {
    id: `${privacyScope}.content14`,
    defaultMessage:
      'Nous traitons vos données conformément aux bases juridiques suivantes.',
    en: 'We process your data in accordance with the following legal bases.',
    es: 'Tratamos sus datos de acuerdo con las siguientes bases jurídicas.',
    it: 'Trattiamo i vostri dati in base alle seguenti basi giuridiche.',
    de: 'Wir verarbeiten Ihre Daten gemäß den folgenden Rechtsgrundlagen.',
    nl: 'Wij verwerken uw gegevens in overeenstemming met de volgende rechtsgrondslagen.',
  },
  content15: {
    id: `${privacyScope}.content15`,
    defaultMessage:
      'Dans le but de vous envoyer des emails marketing, des informations sur Tictactrip : nos nouvelles fonctionnalités, grandes avancées et offres promotionnelles (voyages à prix cassés, offres des compagnies de transports partenaires…). Aussi, le transfert de certaines données pour quelques réservations vers notre partenaire Amadeus (voir plus haut).',
    en: 'In order to send you marketing emails and information about Tictactrip: our new features, major advances and promotional offers (discounted travel, offers from partner transport companies, etc.). Also, the transfer of certain data for some bookings to our partner Amadeus (see above).',
    es: 'Para enviarle correos electrónicos de marketing e información sobre Tictactrip: nuestras novedades, principales avances y ofertas promocionales (descuentos en viajes, ofertas de empresas de transporte asociadas, etc.). Asimismo, para transferir determinados datos de algunas reservas a nuestro socio Amadeus (véase más arriba).',
    it: "Per inviare all'utente e-mail di marketing e informazioni su Tictactrip: le nostre nuove funzionalità, i principali progressi e le offerte promozionali (sconti sui viaggi, offerte di aziende di trasporto partner, ecc.) Inoltre, per trasferire alcuni dati per alcune prenotazioni al nostro partner Amadeus (vedi sopra).",
    de: 'Mit dem Ziel, Ihnen Marketing-E-Mails und Informationen über Tictactrip zu senden: unsere neuen Funktionen, große Fortschritte und Werbeangebote (Reisen zum Schnäppchenpreis, Angebote von Partner-Transportunternehmen...). Auch die Übertragung bestimmter Daten für einige Buchungen an unseren Partner Amadeus (siehe oben).',
    nl: 'Om u marketinge-mails en informatie over Tictactrip te sturen: onze nieuwe functies, belangrijke ontwikkelingen en promotieaanbiedingen (kortingen op reizen, aanbiedingen van partnertransportbedrijven, etc.). Ook om bepaalde gegevens voor sommige boekingen door te geven aan onze partner Amadeus (zie hierboven).',
  },
  content16: {
    id: `${privacyScope}.content16`,
    defaultMessage: `Cet article mentionne la conclusion, l’exécution, la modification, la résiliation d’une relation contractuelle avec vous :
- Pour faciliter votre utilisation de notre service ainsi que vos recherches et réservations,
- pour nous acquitter de nos obligations liées au contrat,
- pour permettre un accès et des visites simples sur notre site web et faciliter votre connexion,
- pour personnaliser au mieux votre billet et/ou réservation et pour vous contacter efficacement en rapport à votre réservation : comme vous envoyer vos billets de transport,
- pour vous reconnaître comme utilisateur et vous faciliter l’accès à votre compte.`,
    en: `To some extent, we may engage third-party service providers, especially for technical, business, legal, and tax-related needs. It may also potentially involve web hosting providers, a network of providers for caching and content creation, analytics and statistics solutions providers, providers to assist us in managing support requests, CRM system providers, centralized management of newsletters, and email marketing services (see "Email Marketing and Newsletters" above), spam and fraud prevention solutions, as well as service providers for information technology (IT) and development services.

All these various providers receive personal data only in the execution of their services, for us and on our behalf. They are bound by the contract between them and us not to use personal data for other purposes.
`,
    es: `En cierta medida, es posible que recurramos a proveedores de servicios externos, especialmente para necesidades técnicas, comerciales, legales y fiscales. También puede tratarse potencialmente de proveedores de alojamiento web, una red de proveedores para almacenar en caché y crear contenido, proveedores de soluciones de análisis y estadísticas, proveedores para ayudarnos en la gestión de solicitudes de soporte, proveedores de sistemas CRM, gestión centralizada de boletines y servicios de marketing por correo electrónico (ver "Marketing por Correo Electrónico y Boletines" arriba), soluciones de prevención de spam y fraude, así como proveedores de servicios para tecnología de la información (TI) y servicios de desarrollo.

Todos estos diversos proveedores reciben datos personales solo en el marco de la ejecución de sus servicios, para nosotros y en nuestro nombre. Están obligados por el contrato entre ellos y nosotros a no utilizar los datos personales con fines distintos.
`,
    it: `In certa misura, potremmo avvalerci di fornitori di servizi terzi, specialmente per esigenze tecniche, commerciali, legali e fiscali. Potrebbe anche coinvolgere potenzialmente fornitori di hosting web, una rete di fornitori per la memorizzazione nella cache e la creazione di contenuti, soluzioni di analisi e statistiche, fornitori per aiutarci nella gestione delle richieste di supporto, fornitori di sistemi CRM, gestione centralizzata di newsletter e servizi di marketing via email (vedi "Email Marketing e Newsletter" sopra), soluzioni di prevenzione dello spam e frodi, nonché fornitori di servizi per tecnologia dell'informazione (IT) e servizi di sviluppo.

Tutti questi vari fornitori ricevono dati personali solo nell'ambito dell'esecuzione dei loro servizi, per nostro conto. Sono vincolati dal contratto tra loro e noi a non utilizzare i dati personali per altri scopi.
`,
    de: `In gewissem Umfang können wir auf Dienstleister von Drittanbietern zurückgreifen, insbesondere für technische, geschäftliche, rechtliche und steuerliche Bedürfnisse. Es kann sich auch potenziell um Webhosting-Anbieter, ein Netzwerk von Anbietern für das Zwischenspeichern und Erstellen von Inhalten, Anbieter von Analyse- und Statistiklösungen, Anbieter handeln, die uns bei der Bearbeitung von Supportanfragen unterstützen, CRM-Systemanbieter, zentralisierte Verwaltung von Newslettern und E-Mail-Marketing-Dienstleistungen (siehe oben "E-Mail-Marketing und Newsletter"), Spam- und Betrugspräventionslösungen sowie Dienstleister für Informationstechnologie (IT) und Entwicklungsdienstleistungen.

Alle diese verschiedenen Anbieter erhalten personenbezogene Daten nur im Rahmen der Durchführung ihrer Dienstleistungen für uns und in unserem Namen. Sie sind vertraglich verpflichtet, die personenbezogenen Daten nicht für andere Zwecke zu verwenden.
`,
    nl: `In zekere mate kunnen we een beroep doen op externe dienstverleners, met name voor technische, zakelijke, juridische en fiscale behoeften. Het kan ook potentieel gaan om webhostingproviders, een netwerk van providers voor het cachen en creëren van inhoud, analyse- en statistiekoplossingen, providers om ons te helpen bij het beheren van ondersteuningsverzoeken, CRM-systeemproviders, gecentraliseerd beheer van nieuwsbrieven en e-mailmarketingdiensten (zie "E-mailmarketing en Nieuwsbrieven" hierboven), spam- en fraudepreventieoplossingen, evenals dienstverleners voor informatietechnologie (IT) en ontwikkelingsdiensten.

Al deze verschillende providers ontvangen persoonlijke gegevens alleen in het kader van de uitvoering van hun diensten, voor ons en namens ons. Ze zijn contractueel gebonden om de persoonlijke gegevens niet voor andere doeleinden te gebruiken.
`,
  },
  content17: {
    id: `${privacyScope}.content17`,
    defaultMessage:
      'Cet article cible des obligations légales telles que : le respect des délais en termes de conservation de données, qui sont requis par le droit fiscal ou commercial et tout en se pliant aux obligations de divulgation des données, fondées sur une décision judiciaire contraignante ou une ordonnance légale.',
    en: 'This article focuses on legal obligations such as complying with data retention deadlines required by tax or commercial law, and complying with data disclosure obligations based on a binding court decision or legal order.',
    es: 'Este artículo se centra en las obligaciones legales, como el cumplimiento de los periodos de conservación de datos exigidos por la legislación fiscal o mercantil y el cumplimiento de las obligaciones de divulgación de datos basadas en una resolución judicial vinculante o en una orden legal.',
    it: "Questo articolo si concentra sugli obblighi legali, come il rispetto dei periodi di conservazione dei dati richiesti dal diritto fiscale o commerciale e l'adempimento degli obblighi di divulgazione dei dati basati su una decisione giudiziaria vincolante o su un ordine di legge.",
    de: 'Dieser Artikel zielt auf rechtliche Verpflichtungen ab, wie z. B.: die Einhaltung von Fristen für die Datenaufbewahrung, die durch Steuer- oder Handelsgesetze vorgeschrieben sind, und gleichzeitig die Einhaltung von Verpflichtungen zur Offenlegung von Daten, die auf einer verbindlichen gerichtlichen Entscheidung oder einer gesetzlichen Anordnung beruhen.',
    nl: 'Dit artikel richt zich op wettelijke verplichtingen, zoals het naleven van de bewaartermijnen die vereist zijn onder de belasting- of handelswetgeving en het naleven van de verplichtingen tot openbaarmaking van gegevens op basis van een bindende uitspraak van een rechtbank of een wettelijk bevel.',
  },
  content18: {
    id: `${privacyScope}.content18`,
    defaultMessage: `Cet article se fonde sur les intérêts légitimes que nous avons et dont les finalités sont : 
- D’améliorer notre site web en l’adaptant à vos besoins,
- de prévenir, trouver, traiter et enquêter sur les fraudes, bugs, incidents, ou activités illégales de tous types ainsi qu’en diminuant les risques que de tels événements se produisent,
- d’effectuer des analyses et vérifications de la qualité des services en interne,
- de protéger la sécurité de notre réseau et des informations,
- d’analyser et de créer des statistiques sur la provenance de notre trafic utilisateur, sur l’utilisation de notre site web ainsi que sur le passage de notre site à ceux de nos partenaires de services,
- de servir à notre facturation avec nos partenaires de transport et services, ainsi qu’a notre comptabilité interne,
- de personnaliser votre expérience sur notre site ainsi que nos offres,
- et enfin, de vous afficher des annonces qui correspondent au mieux à vos intérêts.`,
    en: `This article is based on our legitimate interests, and its purposes are:
    - To improve our website by adapting it to your needs,
    - To prevent, detect, process, and investigate fraud, bugs, incidents, or illegal activities of all kinds, and by reducing the risks of such events occurring,
    - To perform internal analyses and quality checks of services,
    - To protect the security of our network and information,
    - To analyze and create statistics on the origin of our user traffic, the use of our website, and the transition from our site to those of our service partners,
    - To serve our billing with our transport and service partners, as well as our internal accounting,
    - To personalize your experience on our site and our offers,
    - And finally, to display ads that best match your interests.
    `,
    es: `Este artículo se basa en nuestros intereses legítimos, y sus propósitos son:
    - Mejorar nuestro sitio web adaptándolo a sus necesidades,
    - Prevenir, detectar, procesar e investigar fraudes, errores, incidentes o actividades ilegales de todo tipo, y reducir los riesgos de que ocurran tales eventos,
    - Realizar análisis y verificaciones internas de la calidad de los servicios,
    - Proteger la seguridad de nuestra red e información,
    - Analizar y crear estadísticas sobre el origen de nuestro tráfico de usuarios, el uso de nuestro sitio web y la transición desde nuestro sitio hacia los de nuestros socios de servicios,
    - Servir para nuestra facturación con nuestros socios de transporte y servicios, así como nuestra contabilidad interna,
    - Personalizar su experiencia en nuestro sitio y nuestras ofertas,
    - Y finalmente, mostrar anuncios que se ajusten mejor a sus intereses.
    `,
    it: `Questo articolo si basa sui nostri legittimi interessi e ha i seguenti scopi:
    - Migliorare il nostro sito web adattandolo alle vostre esigenze,
    - Prevenire, individuare, elaborare e investigare frodi, bug, incidenti o attività illegali di ogni tipo e ridurre i rischi che tali eventi si verifichino,
    - Effettuare analisi e controlli interni sulla qualità dei servizi,
    - Proteggere la sicurezza della nostra rete e delle informazioni,
    - Analizzare e creare statistiche sull'origine del nostro traffico utente, sull'uso del nostro sito web e sulla transizione dal nostro sito a quelli dei nostri partner di servizi,
    - Servire la nostra fatturazione con i nostri partner di trasporto e servizi, così come la nostra contabilità interna,
    - Personalizzare la vostra esperienza sul nostro sito e sulle nostre offerte,
    - E infine, mostrare annunci che corrispondono al meglio ai vostri interessi.
    `,
    de: `Dieser Artikel basiert auf unseren legitimen Interessen und hat folgende Zwecke:
    - Verbesserung unserer Website durch Anpassung an Ihre Bedürfnisse,
    - Verhinderung, Erkennung, Verarbeitung und Untersuchung von Betrug, Fehlern, Vorfällen oder illegalen Aktivitäten aller Art sowie Verringerung der Risiken solcher Ereignisse,
    - Durchführung interner Analysen und Qualitätskontrollen von Dienstleistungen,
    - Schutz der Sicherheit unseres Netzwerks und unserer Informationen,
    - Analyse und Erstellung von Statistiken zum Ursprung unseres Benutzertraffics, zur Nutzung unserer Website und zum Übergang von unserer Website zu denen unserer Servicepartner,
    - Bedienung unserer Abrechnung mit unseren Transport- und Servicepartnern sowie unserer internen Buchhaltung,
    - Personalisierung Ihrer Erfahrung auf unserer Website und unseren Angeboten,
    - Und schließlich Anzeigen anzeigen, die am besten Ihren Interessen entsprechen.
    `,
    nl: `Dit artikel is gebaseerd op onze legitieme belangen en heeft de volgende doelen:
    - Onze website verbeteren door deze aan te passen aan uw behoeften,
    - Fraude, bugs, incidenten of illegale activiteiten van allerlei aard voorkomen, detecteren, verwerken en onderzoeken, en de risico's van dergelijke gebeurtenissen verminderen,
    - Interne analyses en kwaliteitscontroles van diensten uitvoeren,
    - De veiligheid van ons netwerk en informatie beschermen,
    - Statistieken over de herkomst van ons gebruikersverkeer, het gebruik van onze website en de overgang van onze site naar die van onze servicepartners analyseren en creëren,
    - Onze facturatie met onze transport- en servicepartners bedienen, evenals onze interne boekhouding,
    - Uw ervaring op onze site en onze aanbiedingen personaliseren,
    - En tot slot advertenties weergeven die het beste aansluiten bij uw interesses.
    `,
  },
  content19: {
    id: `${privacyScope}.content19`,
    defaultMessage: `Nous avons déjà évoqué plus haut, le droit de retirer votre consentement par rapport à l’utilisation et à notre traitement de vos données personnelles, le cas échéant, vous avez également le droit d’y demander l’accès (article 15 RGPD), d’effacer (article 17 RGPD) ou de rectifier (article 16 RGPD) aux données à caractère personnel que nous possédons. 

Également, vous avez le droit de restreindre notre traitement de ces données (article 18 RGPD), le droit de vous opposer à ces traitements (article 21 RGPD) ou encore le droit à la portabilité des données (article 20 RGPD).

Vous avez le droit de vous opposer à tous les traitements possibles de vos données décrits sur cette page de notre politique de confidentialité qui est basée sur l’article 6, 1f RGPD et fondée sur des motifs en lien avec votre situation particulière d’après l’article 21,1 RGPD.

Comme nous traitons vos données personnelles en conformité avec l’article 6,1f RGPD, à des fins de marketing, il vous est possible de vous opposer à ce traitement de données et ce, sans avoir à fournir quelconque explication ou raison particulière.`,
    en: `We have already mentioned above the right to withdraw your consent regarding the use and processing of your personal data. If applicable, you also have the right to request access (Article 15 GDPR), deletion (Article 17 GDPR), or rectification (Article 16 GDPR) of the personal data we hold.

    Furthermore, you have the right to restrict our processing of this data (Article 18 GDPR), the right to object to these processes (Article 21 GDPR), or the right to data portability (Article 20 GDPR).
    
    You have the right to object to all possible data processing described on this page of our privacy policy, which is based on Article 6(1)(f) GDPR and based on reasons related to your particular situation according to Article 21(1) GDPR.
    
    As we process your personal data in accordance with Article 6(1)(f) GDPR for marketing purposes, you have the right to object to this data processing without having to provide any specific explanation or reason.
    `,
    es: `Ya hemos mencionado anteriormente el derecho a retirar su consentimiento con respecto al uso y procesamiento de sus datos personales. En su caso, también tiene derecho a solicitar el acceso (Artículo 15 del RGPD), la eliminación (Artículo 17 del RGPD) o la rectificación (Artículo 16 del RGPD) de los datos personales que poseemos.

    Además, tiene el derecho de restringir nuestro procesamiento de estos datos (Artículo 18 del RGPD), el derecho de oponerse a estos procesos (Artículo 21 del RGPD) o el derecho a la portabilidad de datos (Artículo 20 del RGPD).
    
    Tiene el derecho de oponerse a todos los posibles tratamientos de datos descritos en esta página de nuestra política de privacidad, que se basa en el Artículo 6, 1f del RGPD y se basa en motivos relacionados con su situación particular según el Artículo 21,1 del RGPD.
    
    Dado que procesamos sus datos personales de acuerdo con el Artículo 6,1f del RGPD, con fines de marketing, tiene el derecho de oponerse a este procesamiento de datos sin tener que proporcionar ninguna explicación o razón específica.
    `,
    it: `Abbiamo già menzionato sopra il diritto di revocare il consenso riguardo all'uso e al trattamento dei tuoi dati personali. Se applicabile, hai anche il diritto di richiedere l'accesso (articolo 15 del GDPR), la cancellazione (articolo 17 del GDPR) o la rettifica (articolo 16 del GDPR) dei dati personali che deteniamo.

    Inoltre, hai il diritto di limitare il nostro trattamento di questi dati (articolo 18 del GDPR), il diritto di opporsi a questi processi (articolo 21 del GDPR) o il diritto alla portabilità dei dati (articolo 20 del GDPR).
    
    Hai il diritto di opporsi a tutti i possibili trattamenti dei dati descritti in questa pagina della nostra informativa sulla privacy, basati sull'articolo 6,1f del GDPR e basati su motivi legati alla tua particolare situazione secondo l'articolo 21,1 del GDPR.
    
    Poiché trattiamo i tuoi dati personali in conformità all'articolo 6,1f del GDPR per scopi di marketing, hai il diritto di opporsi a questo trattamento dei dati senza dover fornire spiegazioni o motivazioni specifiche.
    `,
    de: `Wir haben bereits oben das Recht erwähnt, Ihre Einwilligung in Bezug auf die Verwendung und Verarbeitung Ihrer personenbezogenen Daten zu widerrufen. Wenn zutreffend, haben Sie auch das Recht, Zugang (Artikel 15 DSGVO), Löschung (Artikel 17 DSGVO) oder Berichtigung (Artikel 16 DSGVO) der von uns gehaltenen personenbezogenen Daten zu verlangen.

    Darüber hinaus haben Sie das Recht, unsere Verarbeitung dieser Daten zu beschränken (Artikel 18 DSGVO), das Recht, sich gegen diese Prozesse zu widersetzen (Artikel 21 DSGVO) oder das Recht auf Datenübertragbarkeit (Artikel 20 DSGVO).
    
    Sie haben das Recht, sich gegen alle möglichen Datenverarbeitungen zu widersetzen, die auf dieser Seite unserer Datenschutzrichtlinie beschrieben sind und auf Artikel 6,1f DSGVO beruhen und auf Gründen beruhen, die mit Ihrer besonderen Situation gemäß Artikel 21,1 DSGVO zusammenhängen.
    
    Da wir Ihre personenbezogenen Daten gemäß Artikel 6,1f DSGVO für Marketingzwecke verarbeiten, haben Sie das Recht, sich gegen diese Datenverarbeitung zu widersetzen, ohne eine spezifische Erklärung oder Begründung abgeben zu müssen.
    `,
    nl: `We hebben hierboven al het recht genoemd om uw toestemming met betrekking tot het gebruik en de verwerking van uw persoonlijke gegevens in te trekken. Indien van toepassing heeft u ook het recht om toegang te vragen (artikel 15 AVG), verwijdering (artikel 17 AVG) of rectificatie (artikel 16 AVG) van de persoonlijke gegevens die wij hebben.

    Bovendien heeft u het recht om onze verwerking van deze gegevens te beperken (artikel 18 AVG), het recht om bezwaar te maken tegen deze processen (artikel 21 AVG) of het recht op gegevensoverdraagbaarheid (artikel 20 AVG).
    
    U heeft het recht om u te verzetten tegen alle mogelijke gegevensverwerkingen die op deze pagina van ons privacybeleid worden beschreven, die zijn gebaseerd op artikel 6,1f AVG en gebaseerd zijn op redenen die verband houden met uw specifieke situatie volgens artikel 21,1 AVG.
    
    Aangezien wij uw persoonlijke gegevens verwerken in overeenstemming met artikel 6,1f AVG voor marketingdoeleinden, heeft u het recht om bezwaar te maken tegen deze gegevensverwerking zonder specifieke uitleg of reden te hoeven geven.
    `,
  },
};

export default defineMessages(privacyMessages);
