import { lazy, Suspense } from 'react';
import LazyLoading from '../../components/LazyLoading';

export const LazyPrivacy = lazy(() =>
  import(/* webpackChunkName: 'Privacy' */ './index'),
);

export default function Privacy() {
  return (
    <Suspense fallback={<LazyLoading />}>
      <LazyPrivacy />
    </Suspense>
  );
}
