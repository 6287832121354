import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import tw from 'tailwind.macro';
import FunnelSteps from '../../../components/Funnel/Steps';

const Container = styled.div`
  ${tw`flex items-center justify-end`};

  @media (min-width: 992px) {
    margin-right: ${(props) => (props.isWidgetBooking ? '0' : '25%')};
  }
`;

const Funnel = ({ currentStep, isBookable, partnerLogo, searchUrl }) => (
  <Container isWidgetBooking={!!partnerLogo}>
    <FunnelSteps />
  </Container>
);

Funnel.propTypes = {
  currentStep: PropTypes.string,
  isBookable: PropTypes.bool,
  partnerLogo: PropTypes.string,
  searchUrl: PropTypes.string,
};

export default Funnel;
