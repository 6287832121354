import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled, { css } from 'styled-components';
import tw from 'tailwind.macro';
import { NAVIGATION } from '../../../containers/App/constants';
import messages from '../../../containers/Header/View/messages';
import { useApp } from '../../../contexts/App';
import { FUNNEL_STEPS_PROGRESS } from '../../../contexts/App/constants';
import { EFunnelStep } from '../../../contexts/App/types';
import { useSearch } from '../../../contexts/Search';
import { ChevronIcon } from '../../../images/icons/chevron';
import { colors } from '../../../styles/constants';

const StepContainer = styled.a<{
  isCurrentStep: boolean;
  isAfterCurrentStep: boolean;
}>`
  ${tw`font-kyrial text-title-small-m leading-title-small-m font-bold text-text
    lg:text-title-large lg:leading-title-large
`}
  text-decoration: none;

  ${({ isCurrentStep }) => isCurrentStep && tw`text-primary-400`}
  ${({ isAfterCurrentStep }) => isAfterCurrentStep && tw`text-grey-300`}
`;

type StepProps = {
  isCurrentStep: boolean;
  isAfterCurrentStep: boolean;
  children: React.ReactNode;
  link?: string;
};

const Step = ({
  children,
  isAfterCurrentStep,
  isCurrentStep,
  link,
}: StepProps) => {
  return (
    <StepContainer
      href={!isAfterCurrentStep && !isCurrentStep ? link : undefined}
      isAfterCurrentStep={isAfterCurrentStep}
      isCurrentStep={isCurrentStep}
    >
      {children}
    </StepContainer>
  );
};

const Container = styled.div`
  ${tw`flex items-center`}
  gap: var(--size-small);

  @media (min-width: 768px) {
    gap: var(--size-medium2);
  }
`;

const FunnelSteps = () => {
  const { funnelStep: currentStep } = useApp();
  const { createSearchUrl, searchParameters } = useSearch();

  const currentStepIndex = FUNNEL_STEPS_PROGRESS.findIndex(
    (step) => step === currentStep,
  );

  const getLink = (step: EFunnelStep) => {
    if (step === EFunnelStep.RESULTS) {
      const url = createSearchUrl(searchParameters);

      return `${NAVIGATION.RESULTS}/${url}`;
    } else if (step === EFunnelStep.INFO) {
      const cartId = window.location.href.split('/').pop();
      return `${NAVIGATION.INFO}/${cartId}`;
    }
  };

  return (
    <Container>
      {FUNNEL_STEPS_PROGRESS.map((step, index) => {
        const isCurrentStep = index === currentStepIndex;
        const isBeforeCurrentStep = index < currentStepIndex;
        const isAfterCurrentStep = index > currentStepIndex;
        return (
          <React.Fragment key={index}>
            <Step
              isAfterCurrentStep={isAfterCurrentStep}
              isCurrentStep={isCurrentStep}
              link={isBeforeCurrentStep && getLink(step)}
            >
              <FormattedMessage {...messages[step]} />
            </Step>
            {index < FUNNEL_STEPS_PROGRESS.length - 1 && (
              <ChevronIcon
                color={
                  isCurrentStep || isAfterCurrentStep
                    ? colors['grey-300']
                    : colors['secondary-400']
                }
                customStyle={css`
                  transform: rotate(180deg);
                `}
              />
            )}
          </React.Fragment>
        );
      })}
    </Container>
  );
};

export default FunnelSteps;
