import { lazy, Suspense } from 'react';
import LazyLoading from '../../components/LazyLoading';

export const LazyLegalMention = lazy(() =>
  import(/* webpackChunkName: 'LegalMention' */ './index'),
);

export default function LegalMention() {
  return (
    <Suspense fallback={<LazyLoading />}>
      <LazyLegalMention />
    </Suspense>
  );
}
