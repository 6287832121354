/**
 * Cart selector
 */

import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectCartDomain = (state) => state.get('commonCart', initialState);

/**
 * Default selector
 */

const makeSelectCart = () =>
  createSelector(selectCartDomain, (substate) => substate.toJS());

export const selectCartData = () =>
  createSelector(selectCartDomain, (cartState) =>
    cartState.get('selectedTrip').toJS(),
  );

export default makeSelectCart;
