/*
 * Partners Messages
 *
 * This contains all the text for the Partners component.
 */
import { defineMessages } from 'react-intl';

export const partnersScope = 'app.containers.Partners';

export const partnersMessages = {
  header: {
    id: `${partnersScope}.header`,
    defaultMessage: 'Devenir partenaire Tictactrip',
    en: 'Become a Tictactrip partner',
    es: 'Hágase socio de Tictactrip',
    de: 'Tictactrip-Partner werden',
    it: 'Diventare partner di Tictactrip',
    nl: 'Word een Tictactrip partner',
  },
  becomePartner: {
    id: `${partnersScope}.becomePartner`,
    defaultMessage: 'Pourquoi devenir partenaire ?',
    en: 'Why should you become a partner?',
    es: '¿Por qué asociarse?',
    de: 'Warum Partner werden?',
    it: 'Perché diventare partner?',
    nl: 'Waarom partner worden?',
  },
  stepNetwork: {
    id: `${partnersScope}.stepNetwork`,
    defaultMessage: 'Grand réseau',
    en: 'Large network',
    es: 'Gran red',
    de: 'Großes Netzwerk',
    it: 'Rete di grandi dimensioni',
    nl: 'Groot netwerk',
  },
  stepPartners: {
    id: `${partnersScope}.stepPartners`,
    defaultMessage: 'Partenariats',
    en: 'Partnerships',
    es: 'Asociaciones',
    de: 'Partnerschaften',
    it: 'Partenariati',
    nl: 'Samenwerkingsverbanden',
  },
  stepWidgets: {
    id: `${partnersScope}.stepWidgets`,
    defaultMessage: 'Les Widgets',
    en: 'Widgets',
    es: 'Widgets',
    de: 'Die Widgets',
    it: 'Widgets',
    nl: 'Widgets',
  },
  stepCreateWidget: {
    id: `${partnersScope}.stepCreateWidget`,
    defaultMessage: 'Créer son Widget',
    en: 'Creating Widgets',
    es: 'Cree sus widgets',
    de: 'Widgets erstellen',
    it: 'Creare i widget',
    nl: 'Widgets maken',
  },
  stepMeeting: {
    id: `${partnersScope}.stepMeeting`,
    defaultMessage: 'Prendre RDV',
    en: 'Make an appointment',
    es: 'Concierte una cita',
    de: 'RDV vereinbaren',
    it: 'Fissare un appuntamento',
    nl: 'Maak een afspraak',
  },
  networkTitle: {
    id: `${partnersScope}.networkTitle`,
    defaultMessage: '1. Un vaste réseau en France',
    en: '1. A vast network in France',
    es: '1. Una amplia red en Francia',
    de: '1. Ein großes Netzwerk in Frankreich',
    it: '1. Una vasta rete in Francia',
    nl: '1. Een uitgebreid netwerk in Frankrijk',
  },
  networkSubTitle: {
    id: `${partnersScope}.networkSubTitle`,
    defaultMessage:
      'Actuellement, plus de 130 partenaires nous font confiance !',
    en: 'We currently have over 130 partners!',
    es: 'Actualmente contamos con más de 130 socios.',
    de: 'Derzeit vertrauen uns mehr als 130 Partner!',
    it: 'Attualmente abbiamo oltre 130 partner!',
    nl: 'We hebben momenteel meer dan 130 partners!',
  },
  networkText: {
    id: `${partnersScope}.networkText`,
    defaultMessage:
      "De plus en plus de villes, événements et attractions touristiques utilisent notre service ainsi que le widget afin de faciliter l'accès à l'information, " +
      "améliorer leur desserte et mettre en avant leurs atouts auprès d'une nouvelle communauté de voyageurs.",
    en:
      'More and more cities, events and tourist attractions are using our service and widget to facilitate access to information, ' +
      'improve their service and promote their assets to a new community of travelers.',
    es:
      'Cada vez son más las ciudades, eventos y atracciones turísticas que utilizan nuestro servicio y el widget para facilitar el acceso a la información, ' +
      'mejorar sus servicios y promocionar sus atracciones entre una nueva comunidad de viajeros.',
    de:
      'Immer mehr Städte, Veranstaltungen und Touristenattraktionen nutzen unseren Service sowie das Widget, um den Zugang zu Informationen zu erleichtern, ' +
      ' ihre Verkehrsanbindung zu verbessern und ihre Vorzüge einer neuen Reisegemeinschaft zu präsentieren',
    it:
      "Sempre più città, eventi e attrazioni turistiche utilizzano il nostro servizio e il widget per facilitare l'accesso alle informazioni" +
      'migliorare i loro servizi e promuovere le loro attrazioni a una nuova comunità di viaggiatori',
    nl:
      'Steeds meer steden, evenementen en toeristische attracties gebruiken onze service en de widget om de toegang tot informatie te vergemakkelijken, ' +
      'hun diensten te verbeteren en hun attracties te promoten bij een nieuwe groep reizigers.',
  },
  partnersTitle: {
    id: `${partnersScope}.partnersTitle`,
    defaultMessage: '2. Partenariats',
    en: '2. Partnerships',
    es: '2. Asociaciones',
    de: '2. Partnerschaften',
    it: '2. Partenariati',
    nl: '2. Samenwerkingsverbanden',
  },
  partnersSubTitle1: {
    id: `${partnersScope}.partnersSubTitle1`,
    defaultMessage: '2.1 Nos partenaires en parlent',
    en: '2.1 Our partners talk about it',
    es: '2.1 Nuestros socios opinan',
    de: '2.1 Unsere Partner reden darüber',
    it: '2.1 Cosa dicono i nostri partner',
    nl: '2.1 Wat onze partners te zeggen hebben',
  },
  partnersSubTitle2: {
    id: `${partnersScope}.partnersSubTitle2`,
    defaultMessage: '2.2 Découvrez nos partenaires',
    en: '2.2 Discover our partners',
    es: '2.2 Descubra nuestros socios',
    de: '2.2 Entdecken Sie unsere Partner',
    it: '2.2 Scopri i nostri partner',
    nl: '2.2 Ontdek onze partners',
  },
  partnersText2: {
    id: `${partnersScope}.partnersText2`,
    defaultMessage:
      'Ils nous font confiance et utilisent notre widget pour venir chez eux.',
    en: 'They trust us and use our widget to come to their homes.',
    es: 'Confían en nosotros y utilizan nuestro widget para visitarnos.',
    de: 'Sie vertrauen uns und nutzen unser Widget, um zu ihnen zu kommen.',
    it: 'Si fidano di noi e utilizzano il nostro widget per visitarli.',
    nl: 'Ze vertrouwen ons en gebruiken onze widget om hen te bezoeken.',
  },
  partnersSubTitle3: {
    id: `${partnersScope}.partnersSubTitle3`,
    defaultMessage: '2.3 Plus de 200 compagnies',
    en: '2.3 Over 200 companies',
    es: '2.3 Más de 200 empresas',
    de: '2.3 Mehr als 200 Gesellschaften',
    it: '2.3 Più di 200 aziende',
    nl: '2.3 Meer dan 200 bedrijven',
  },
  partnersText3: {
    id: `${partnersScope}.partnersText3`,
    defaultMessage:
      'Nous sommes en contact permanent avec les acteurs majeurs du transport en France et en Europe et travaillons pour ' +
      'intégrer toujours plus de compagnies et ainsi proposer les meilleures combinaisons aux voyageurs.',
    en:
      'We are in constant contact with the major players in the French and European transport sectors, and are working to ' +
      'to integrate more and more companies and offer travelers the best combinations.',
    es:
      "Estamos en contacto permanente con los principales actores del sector del transporte en Francia y Europa, y trabajamos para ' + ' integrar cada vez más empresas para ofrecer a los viajeros las mejores combinaciones." +
      'Estamos en contacto permanente con los principales actores del sector del transporte en Francia y Europa, y trabajamos para integrar cada vez más empresas para ofrecer a los viajeros las mejores combinaciones.',
    de:
      'Wir stehen in ständigem Kontakt mit den wichtigsten Akteuren des Transportwesens in Frankreich und Europa und arbeiten daran, ' +
      +' immer mehr Unternehmen zu integrieren und so den Reisenden die besten Kombinationen anzubieten.',
    it:
      'Siamo in costante contatto con i principali attori del settore dei trasporti in Francia e in Europa e stiamo lavorando per ' +
      ' integrare sempre più aziende per offrire ai viaggiatori le migliori combinazioni.',
    nl:
      'We staan voortdurend in contact met de grote spelers in de transportsector in Frankrijk en Europa en werken eraan om ' +
      ' steeds meer bedrijven te integreren, zodat we reizigers de beste combinaties kunnen bieden.',
  },
  partnersSubTitle4: {
    id: `${partnersScope}.partnersSubTitle4`,
    defaultMessage: '2.4 La presse parle de nous',
    en: '2.4 Press coverage',
    es: '2.4 Lo que la prensa dice de nosotros',
    de: '2.4 Die Presse berichtet über uns',
    it: '2.4 Cosa dice di noi la stampa',
    nl: '2.4 Wat de pers over ons te zeggen heeft',
  },
  partnersText4: {
    id: `${partnersScope}.partnersText4`,
    defaultMessage:
      'Créé il ya deux ans, Tictactrip a déja bien fait parler de lui !',
    en: 'Created two years ago, Tictactrip has already made a name for itself!',
    es: 'Creada hace dos años, Tictactrip ya se ha hecho un nombre',
    de: 'Tictactrip wurde vor zwei Jahren gegründet und hat schon viel von sich reden gemacht!',
    it: 'Creato due anni fa, Tictactrip si è già fatto un nome!',
    nl: 'Tictactrip werd twee jaar geleden opgericht en heeft nu al naam gemaakt!',
  },
  createWidgetTitle: {
    id: `${partnersScope}.createWidgetTitle`,
    defaultMessage: '3. Créer mon propre widget',
    en: '3. Create my own widget',
    es: '3. Crear mi propio widget',
    de: '3. Mein eigenes Widget erstellen',
    it: '3. Creare un widget personale',
    nl: '3. Mijn eigen widget maken',
  },
  createWidgetDescription: {
    id: `${partnersScope}.createWidgetDescription`,
    defaultMessage:
      'Créez votre Widget Tictactrip facilement en vous rendant sur ',
    en: 'Create your own Tictactrip Widget easily by going to',
    es: 'Cree su widget Tictactrip fácilmente accediendo a',
    de: 'Erstellen Sie Ihr Tictactrip Widget ganz einfach, indem Sie auf',
    it: 'Create facilmente il vostro Widget Tictactrip andando su',
    nl: 'Maak uw Tictactrip Widget eenvoudig door naar',
  },
  setUpMeeting: {
    id: `${partnersScope}.setUpMeeting`,
    defaultMessage: 'Prendre rendez-vous avec Nicolas ou Hugo ',
    en: 'Make an appointment with Nicolas or Hugo',
    es: 'Concierte una cita con Nicolas o Hugo',
    de: 'Einen Termin mit Nicolas oder Hugo vereinbaren',
    it: 'Fissate un appuntamento con Nicolas o Hugo',
    nl: 'Maak een afspraak met Nicolas of Hugo',
  },
};

export default defineMessages(partnersMessages);
