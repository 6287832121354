/*
 *
 * PassengersInfo constants
 *
 */
import { API_URL } from '../../helpers/constants';
import logoKayak from '../../images/partners/logo_kayak.svg';
import logoKelbillet from '../../images/partners/logo_kelbillet.svg';
import logoLiligo from '../../images/partners/logo_liligo.svg';
import logoOffways from '../../images/partners/logo_offways.svg';
import logoMappy from '../../images/partners/logoMappy.svg';
import logoAltibus from '../../images/providers/logo_altibus.png';
import logoBlablacar from '../../images/providers/logo_blablacar.svg';
import logoEurostar from '../../images/providers/logo_eurostar.svg';
import logoFlixbus from '../../images/providers/logo_flixbus.svg';
import logoSncf from '../../images/providers/logo_sncf.svg';

/**
 * Action types
 */
export const PATCH_CART = 'app/PassengersInfo/PATCH_CART';
export const TOGGLE_MODAL = 'app/PassengersInfo/TOGGLE_MODAL';
export const SET_ERROR = 'app/PassengersInfo/SET_ERROR';
export const TOGGLE_SIMPLE_MODAL = 'app/PassengersInfo/TOGGLE_SIMPLE_MODAL';
export const ACCEPT_LEGAL_TERMS_ACTION =
  'app/PassengerInfo/ACCEPT_LEGAL_TERMS_ACTION';
export const SET_LOADING = 'app/PassengersInfo/SET_LOADING';
export const GET_CART = 'app/PassengersInfo/GET_CART';
export const SET_IS_AVAILABLE = 'app/PassengersInfo/SET_IS_AVAILABLE';
export const SET_PRICE_CENTS_DIFF = 'app/PassengersInfo/SET_PRICE_CENTS_DIFF';
export const SUBMIT_CART = 'app/PassengersInfo/SUBMIT_CART';
export const SET_IS_CART_BEING_UPDATED =
  'app/PassengersInfo/SET_IS_CART_BEING_UPDATED';
export const SET_PATCHED_TRIPS = 'app/PassengersInfo/SET_PATCHED_TRIPS';
export const SET_IS_UNAVAILABLE_MODAL_DISPLAYED =
  'app/PassengersInfo/SET_IS_UNAVAILABLE_MODAL_DISPLAYED';
export const SET_ALTERNATIVE_SEGMENTS =
  'app/PassengersInfo/SET_ALTERNATIVE_SEGMENTS';
export const SET_SELECTED_ALTERNATIVE_SEGMENT =
  'app/PassengersInfo/SET_SELECTED_ALTERNATIVE_SEGMENT';
export const RESET_STATE = 'app/PassengersInfo/RESET_STATE';
export const PASSENGER_MODIFICATION =
  'app/PassengersInfo/PASSENGER_MODIFICATION';

export const ERROR_CREATE_CART = 'ERROR_CREATE_CART';
export const ERROR_DUPLICATE_NAMES = 'ERROR_DUPLICATE_NAMES';
export const ERROR_INVALID_BIRTHDATE = 'ERROR_INVALID_BIRTHDATE';
export const ERROR_INVALID_DOCUMENT_EXPIRATION_DATE =
  'ERROR_INVALID_DOCUMENT_EXPIRATION_DATE';
export const ERROR_TOO_SHORT_NAME = 'ERROR_TOO_SHORT_NAME';
export const ERROR_NAME_PATTERN_MISMATCH = 'ERROR_NAME_PATTERN_MISMATCH';
export const ERROR_INVALID_MAIL = 'ERROR_INVALID_MAIL';

/**
 * State properties
 */
export const LEGAL_TERMS_REDUCER = 'legalTerms';
export const PASSENGERS_INFO_LOADING = 'loading';
export const IS_AVAILABLE = 'isAvailable';
export const PRICE_CENTS_DIFF = 'priceCentsDiff';
export const IS_CART_BEING_UPDATED = 'isCartBeingUpdated';
export const IS_UNAVAILABLE_MODAL_DISPLAYED = 'isUnavailableModalDisplayed';
export const ALTERNATIVE_SEGMENTS = 'alternativeSegments';
export const SELECTED_ALTERNATIVE_SEGMENTS = 'selectedAlternativeSegments';

/**
 * Messages
 */
export const SEATS_AVAILABLE = 'seatsAvailable';
export const SEATS_VALIDATION = 'seatsValidation';
export const UNAVAILABLE_MODAL_BODY = 'unavailableModalBody';
export const UNAVAILABLE_MODAL_HEADER = 'unavailableModalHeader';
export const UNAVAILABLE_MODAL_BUTTON = 'unavailableModalButton';
export const CHECKING_CART_IN_PROGRESS = 'checkingCartInProgress';
export const SEE_OTHER_FARES = 'seeOtherFares';
export const SAME_PRICE = 'samePrice';
export const YOUR_BOOKING_CLASS = 'yourBookingClass';
export const SEE_CONDITIONS_AND_FARES = 'seeConditionsAndFares';
export const SEE_CONDITIONS = 'seeConditions';
export const BEST_DEAL = 'bestDeal';
export const COLLAPSE = 'collapse';
export const EXPAND = 'expand';
export const READ_MORE = 'readMore';

export const PATCHED_TRIPS = 'patchedTrips';

export const VALIDATION_ERRORS = [
  ERROR_DUPLICATE_NAMES,
  ERROR_INVALID_BIRTHDATE,
  ERROR_TOO_SHORT_NAME,
  ERROR_INVALID_MAIL,
];

export const URL_CART = `${API_URL}/booking/carts`;

export const TITLE_MR = 'MR';
export const TITLE_MS = 'MS';
export const TITLE_MX = 'MX';
export const IDENTITY_CARD = 'IDENTITY_CARD';
export const PASSPORT = 'PASSPORT';

export const OTHER_CUSTOMER = -1;
export const VSC_PROVIDER_ID = 10;

export const COUNTRIES_FOR_PHONE = [
  'FR',
  'ES',
  'GB',
  'IT',
  'BE',
  'LU',
  'CH',
  'DE',
  'PT',
  'NL',
];
export const COUNTRY_LABELS_FOR_PHONES = {
  FR: 'France',
  ES: 'Espagne',
  GB: 'Royaume-Uni',
  IT: 'Italie',
  BE: 'Belgique',
  LU: 'Luxembourg',
  CH: 'Suisse',
  DE: 'Allemagne',
  PT: 'Portugal',
  NL: 'Pays-Bas',
};

export const TRUSTPILOT_WEBSITE =
  'https://fr.trustpilot.com/review/tictactrip.eu';

export const partners = {
  kelbillet: {
    logo: logoKelbillet,
    alt: 'Logo Kelbillet',
  },
  KAYAK: {
    logo: logoKayak,
    alt: 'Logo Kayak',
  },
  'APIS-LILIGO': {
    logo: logoLiligo,
    alt: 'Logo Liligo',
    style: 'background-color: #08355A',
  },
  'APIS-OFFWAYS': {
    logo: logoOffways,
    alt: 'Logo Offways',
  },
  'APIS-MAPPY': {
    logo: logoMappy,
    alt: 'Logo Mappy',
  },
};

export const ALLOWED_PARTNERS_REINSURANCE = ['APIS-MAPPY'];

export const providers = [
  {
    logo: logoSncf,
    alt: 'Logo SNCF',
  },
  {
    logo: logoFlixbus,
    alt: 'Logo Flixbus',
  },
  {
    logo: logoAltibus,
    alt: 'Logo Altibus',
  },
  {
    logo: logoBlablacar,
    alt: 'Logo Blablacar',
  },
  {
    logo: logoEurostar,
    alt: 'Logo Eurostar',
  },
];

/**
 * Booking classes
 */
export const SECOND_CLASS = '2_ND_CLASS';
export const SECOND_CLASS_ALT = '2nde classe';
export const FIRST_CLASS = '1_ST_CLASS';
export const FIRST_CLASS_ALT = '1ère classe';
export const BERTHS_IN_SECOND_CLASS = 'BERTHS_IN_2ND_CLASS';
export const BERTHS_IN_FIRST_CLASS = 'BERTHS_IN_1ST_CLASS';
export const RECLINING_SEATS = 'RECLINING_SEATS';
