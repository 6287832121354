import { FormattedMessage } from 'react-intl';
import {
  BUS,
  CARPOOLING,
  TOP_TRAVELS,
  TRAIN,
  TRANSPORTS,
} from '../containers/Header/constants';
import messages from '../containers/Header/View/messages';
import DE from '../images/countries/flags/de.png';
import ES from '../images/countries/flags/es.png';
import FR from '../images/countries/flags/fr.png';
import UK from '../images/countries/flags/gb.png';
import IT from '../images/countries/flags/it.png';
import NL from '../images/countries/flags/nl.png';

export const topTravels = [
  {
    id: 'Paris-Lyon',
    name: 'Paris - Lyon',
    url: '/search/paris/lyon',
    mustReload: true,
  },
  {
    id: 'Paris-Marseille',
    name: 'Paris - Marseille',
    url: '/search/paris/marseille',
    mustReload: true,
  },
  {
    id: 'Paris-Toulouse',
    name: 'Paris - Toulouse',
    url: '/search/paris/toulouse',
    mustReload: true,
  },
  {
    id: 'Lyon-Bruxelles',
    name: 'Lyon - Bruxelles',
    url: '/search/lyon/bruxelles',
    mustReload: true,
  },
  {
    id: 'Paris-Strasbourg',
    name: 'Paris - Strasbourg',
    url: '/search/paris/strasbourg',
    mustReload: true,
  },
];

export const languages = [
  {
    lang: 'fr',
    flag: FR,
  },
  {
    lang: 'en',
    flag: UK,
  },
  {
    lang: 'it',
    flag: IT,
  },
  {
    lang: 'es',
    flag: ES,
  },
  {
    lang: 'de',
    flag: DE,
  },
  {
    lang: 'nl',
    flag: NL,
  },
];

export const contact = [
  {
    id: 'contactSupport',
    name: <FormattedMessage {...messages.contactSupport} />,
    url: '/support',
    mustReload: false,
  },
  {
    id: 'cancellationAndRefund',
    name: <FormattedMessage {...messages.cancellationAndRefund} />,
    url: '/faq/politique-annulation-remboursement-billet',
    mustReload: false,
  },
  {
    id: 'exchangeMyTicket',
    name: <FormattedMessage {...messages.exchangeMyTicket} />,
    url: '/faq/echanger-mes-billets',
    mustReload: true,
  },
  {
    id: 'resellYourTickets',
    name: <FormattedMessage {...messages.resellYourTickets} />,
    url: '/faq/revendre-billets-trains',
    mustReload: false,
    isCloaked: true,
  },
];

export const transports = [
  {
    id: BUS,
    name: <FormattedMessage {...messages[BUS]} />,
    url: '/company/bus',
    mustReload: true,
  },
  {
    id: TRAIN,
    name: <FormattedMessage {...messages[TRAIN]} />,
    url: '/company/train',
    mustReload: true,
  },
  {
    id: CARPOOLING,
    name: <FormattedMessage {...messages[CARPOOLING]} />,
    url: '/company/carpooling',
    mustReload: true,
  },
];

export const headerLinks = {
  languages,
  [TOP_TRAVELS]: topTravels,
  contact,
  [TRANSPORTS]: transports,
};
