/*
 *
 * Header reducer
 *
 */

import { fromJS } from 'immutable';
import { headerLinks } from '../../data/headerLinks';
import {
  HANDLE_DRAWER,
  REDUCER_DISPLAY_DRAWER,
  REDUCER_DISPLAY_MODAL,
  REDUCER_IS_NAVBAR_BACK_BUTTON_HIDDEN,
  REDUCER_MODAL_CONTENT,
  REDUCER_SELECTED_TAB,
  SELECT_TAB,
  SET_IS_NAVBAR_BACK_BUTTON_HIDDEN,
} from './constants';

export const initialState = fromJS({
  [REDUCER_DISPLAY_MODAL]: false,
  [REDUCER_DISPLAY_DRAWER]: false,
  [REDUCER_MODAL_CONTENT]: [],
  [REDUCER_SELECTED_TAB]: null,
  [REDUCER_IS_NAVBAR_BACK_BUTTON_HIDDEN]: false,
});

function headerReducer(state = initialState, action) {
  switch (action.type) {
    case HANDLE_DRAWER: {
      handleLockScroll(state.get(REDUCER_DISPLAY_DRAWER));
      return state
        .set(REDUCER_DISPLAY_DRAWER, action.value)
        .set(REDUCER_DISPLAY_MODAL, false);
    }
    case SELECT_TAB: {
      window.scrollTo(0, 0);
      handleLockScroll(
        state.get(REDUCER_DISPLAY_MODAL) && !state.get(REDUCER_DISPLAY_DRAWER),
      );
      return state
        .set(REDUCER_MODAL_CONTENT, fromJS(headerLinks[action.category]))
        .set(REDUCER_SELECTED_TAB, action.category)
        .set(
          REDUCER_DISPLAY_MODAL,
          action.category === state.get(REDUCER_SELECTED_TAB)
            ? !state.get(REDUCER_DISPLAY_MODAL)
            : true,
        );
    }

    case SET_IS_NAVBAR_BACK_BUTTON_HIDDEN: {
      return state.set(
        REDUCER_IS_NAVBAR_BACK_BUTTON_HIDDEN,
        action.isNavbarBackButtonHidden,
      );
    }

    default:
      return state;
  }
}

/* eslint-disable no-return-assign */
const handleLockScroll = (condition) =>
  (document.body.style.position = condition ? 'relative' : 'fixed');

export default headerReducer;
