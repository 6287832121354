import styled from 'styled-components/macro';
import tw from 'tailwind.macro';

export const Wrapper = styled.div`
  ${tw`flex justify-center bg-white `};
  height: 60px;
  @media (min-width: 768px) {
    height: 75px;
  }
`;

export const Container = styled.div`
  ${tw`sticky pin-t bg-white z-5 shadow-header
  	md:relative`};
  padding-top: env(safe-area-inset-top);

  ${({ isInFunnel }) => isInFunnel && tw`hidden lg:block`}
`;
