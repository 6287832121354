import hoistNonReactStatics from 'hoist-non-react-statics';
import PropTypes from 'prop-types';
import { Component } from 'react';
import getInjectors from './reducerInjectors';

/**
 * Dynamically injects a reducer
 *
 * @param {string} key A key of the reducer
 * @param {function} reducer A reducer that will be injected
 *
 */
const injectReducers =
  ({ key, reducer }) =>
  (WrappedComponent) => {
    class ReducerInjector extends Component {
      static WrappedComponent = WrappedComponent;

      static contextTypes = {
        store: PropTypes.object.isRequired,
      };

      static displayName = `withReducer(${
        WrappedComponent.displayName || WrappedComponent.name || 'Component'
      })`;

      constructor(props, context) {
        super(props, context);

        this.injectors = getInjectors(context.store);
        this.injectors.injectReducer(key, reducer);
      }

      render() {
        return <WrappedComponent {...this.props} />;
      }
    }

    return hoistNonReactStatics(ReducerInjector, WrappedComponent);
  };

export default injectReducers;
