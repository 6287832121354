/*
 * Results Messages
 *
 * This contains all the text for the Results container.
 */

import { defineMessages } from 'react-intl';

export const travelScope = 'app.components.Travel';

export const travelMessages = {
  editOutbound: {
    id: `${travelScope}.editOutbound`,
    defaultMessage: 'Modifier mon aller',
    en: 'Change my outward journey',
    it: 'Cambiare il mio percorso',
    es: 'Cambiar mi camino hacia el exterior',
    de: 'Meine Reise nach außen verändern',
    nl: 'Verander mijn heenreis',
  },
  editReturn: {
    id: `${travelScope}.editReturn`,
    defaultMessage: 'Modifier mon retour',
    en: 'Change my return journey',
    it: 'Modificare il mio ritorno',
    es: 'Cambiar mi billete de vuelta',
    de: 'Ändern Sie meine Rückreise',
    nl: 'Wijzig mijn terugkeer',
  },
  outboundTrip: {
    id: `${travelScope}.outboundTrip`,
    defaultMessage: 'Aller',
    en: 'Outbound',
    it: 'Andata',
    es: 'Salida',
    de: 'Hinreise',
    nl: 'Uitgaand',
  },
  returnTrip: {
    id: `${travelScope}.returnTrip`,
    defaultMessage: 'Retour',
    en: 'Return',
    it: 'Ritorno',
    es: 'Volver',
    de: 'Rückreise',
    nl: 'Terug',
  },
  choose: {
    id: `${travelScope}.choose`,
    defaultMessage: 'Sélectionnez un trajet pour accéder aux détails',
    en: 'Select a trip for more details',
    it: 'Selezionare un percorso per accedere ai dettagli',
    es: 'Seleccione una ruta para acceder a los detalles',
    de: 'Wählen Sie eine Fahrt aus, um zu den Details zu gelangen',
    nl: 'Selecteer een route voor toegang tot details',
  },
  totalPrice: {
    id: `${travelScope}.totalPrice`,
    defaultMessage: 'Prix pour ',
    en: 'Price for',
    it: 'Premi per ',
    es: 'Premios para ',
    de: 'Preis für ',
    nl: 'Onderscheidingen voor ',
  },
  passenger: {
    id: `${travelScope}.passenger`,
    defaultMessage: ' passager',
    en: ' passenger',
    it: ' passeggero',
    es: ' pasajero',
    de: ' passagier',
    nl: ' passagier',
  },
  passengers: {
    id: `${travelScope}.passengers`,
    defaultMessage: ' passagers',
    en: ' passengers',
    it: ' passeggeri',
    es: ' pasajeros',
    de: ' passagiere',
    nl: ' passagiers',
  },
  includedProviderFeeCentsTooltip: {
    id: `${travelScope}.includedProviderFeeCentsTooltip`,
    defaultMessage: 'Dont {includedProviderFeeCents} de frais de service',
    en: 'Including {includedProviderFeeCents} service fees',
    it: 'Di cui {includedProviderFeeCents} sono spese per il servizio',
    es: 'De los cuales {includedProviderFeeCents} son tasas de servicio',
    de: 'Davon {includedProviderFeeCents} Servicegebühren',
    nl: 'Waarvan {includedProviderFeeCents} servicekosten zijn',
  },
};

export default defineMessages(travelMessages);
