import PropTypes from 'prop-types';
import { css } from 'styled-components/macro';
import tw from 'tailwind.macro';
import AppLink from '../../../components/AppLink';
import { colors } from '../../../styles/constants';

const cssLinkItem = css`
  ${tw`
    text-h4-m text-text whitespace-no-wrap no-underline
    md:mr-abs4
    md:border-0
    hover:text-terciary500`};
  padding-bottom: 15px;
  padding-top: 15px;
  border-top: 1px solid ${colors.grey200};
  user-select: none;
  cursor: pointer;

  @media (min-width: 768px) {
    border-top: 0;
  }
`;

const ModalLinkItem = ({
  closeDrawer,
  closeModal,
  id,
  isCloaked,
  mustReload,
  name,
  openInNewTab,
  url,
}) => (
  <AppLink
    id={id}
    isCloaked={isCloaked}
    methods={() => {
      closeModal();
      closeDrawer();
      document.body.style.position = 'inherit';
    }}
    mustReload={mustReload}
    name={name}
    openInNewTab={openInNewTab}
    styles={cssLinkItem}
    url={url}
  />
);

ModalLinkItem.propTypes = {
  closeDrawer: PropTypes.func,
  closeModal: PropTypes.func,
  id: PropTypes.string,
  isCloaked: PropTypes.bool,
  mustReload: PropTypes.bool,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  openInNewTab: PropTypes.bool,
  url: PropTypes.string,
};

export default ModalLinkItem;
