import PropTypes from 'prop-types';
import { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components/macro';
import tw from 'tailwind.macro';
import AppLink from '../../../components/AppLink';
import { headerLinks, languages } from '../../../data/headerLinks';
import { NAVIGATION } from '../../App/constants';
import {
  CONTACT,
  LANGUAGES,
  ORDERS,
  TOP_TRAVELS,
  TRANSPORTS,
} from '../constants';
import Languages from './Languages';
import messages from './messages';
import { HiddenLinkList, ModalLinkList } from './ModalLinkList';
import Tab from './Tab';
import { tabLinkStyle } from './TabLink';

export const Categories = styled.div`
  ${tw`hidden w-full h-full
    bg-white
    lg:w-auto
  	lg:flex lg:flex-row lg:items-center`};
`;

const Navigation = ({ Disposition, isMobileApp, locale, selectTab }) => (
  <Disposition>
    {!isMobileApp && (
      <Fragment>
        <AppLink
          content={<FormattedMessage {...messages.purchaseSNCFAdvantageCard} />}
          id={'purchaseSNCFAdvantageCard'}
          mustReload
          openInNewTab
          styles={tabLinkStyle}
          url={'https://tally.so/r/wadNXy'}
        />

        <Tab
          category={TRANSPORTS}
          id={TRANSPORTS}
          selectTab={selectTab}
          text={<FormattedMessage {...messages[TRANSPORTS]} />}
        />
        <Tab
          category={TOP_TRAVELS}
          id="topTravels"
          selectTab={selectTab}
          text={<FormattedMessage {...messages[TOP_TRAVELS]} />}
        />
        <Tab
          category={CONTACT}
          id="contact"
          selectTab={selectTab}
          text={<FormattedMessage {...messages[CONTACT]} />}
        />
      </Fragment>
    )}
    <AppLink
      content={<FormattedMessage {...messages[ORDERS]} />}
      isCloaked
      mustReload
      styles={tabLinkStyle}
      url={NAVIGATION.ORDERS}
    />
    {!isMobileApp && (
      <Fragment>
        <Languages
          category={LANGUAGES}
          flag={languages.find((x) => x.lang === locale).flag}
          selectTab={selectTab}
        />
        <HiddenLinkList>
          <ModalLinkList
            areLinksCloaked={false}
            links={[...headerLinks[TOP_TRAVELS], ...headerLinks[TRANSPORTS]]}
          />
        </HiddenLinkList>
      </Fragment>
    )}
  </Disposition>
);

Navigation.propTypes = {
  Disposition: PropTypes.elementType,
  isMobileApp: PropTypes.bool,
  locale: PropTypes.string,
  selectTab: PropTypes.func,
};

export default Navigation;
