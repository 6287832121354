import PropTypes from 'prop-types';

export const TOGGLE_MOBILE_CART = 'app/Cart/TOGGLE_MOBILE_CART';
export const CLOSE_CART = 'app/Cart/CLOSE_CART';
export const OPEN_CART = 'app/Cart/OPEN_CART';
export const TOGGLE_OUTBOUND_CART_ON_ROUNDTRIP =
  'app/Cart/TOGGLE_OUTBOUND_CART_ON_ROUNDTRIP';
export const TRUSTPILOT_WEBSITE =
  'https://fr.trustpilot.com/review/tictactrip.eu';

export const INFO_PRICE_DOES_NOT_YET_INCLUDE_DISCOUNT_CARDS =
  'INFO_PRICE_DOES_NOT_YET_INCLUDE_DISCOUNT_CARDS';
export const INFO_PRICE_CHANGED_DISCOUNT_CARDS =
  'INFO_PRICE_CHANGED_DISCOUNT_CARDS';
export const INFO_PRICE_CHANGED_YOUTH = 'INFO_PRICE_CHANGED_YOUTH';
export const INFO_PRICE_INCREASED = 'INFO_PRICE_INCREASED';

export const displayOutboundCartPropTypes = PropTypes.shape({
  outboundTrip: PropTypes.bool,
  returnTrip: PropTypes.bool,
});
