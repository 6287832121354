/*
 * HomePage Messages
 *
 * This contains all the text for the HomePage component.
 */
import { defineMessages } from 'react-intl';
import { EFunnelStep } from '../../../contexts/App/types';
import {
  BUS,
  CARPOOLING,
  ORDERS,
  TOP_TRAVELS,
  TRAIN,
  TRANSPORTS,
} from '../constants';

export const headerScope = 'app.containers.Header';

export const headerMessages = {
  [BUS]: {
    id: `${headerScope}.${BUS}`,
    defaultMessage: 'Bus',
    en: 'Bus',
    it: 'Autobus',
    es: 'Autobús',
    de: 'Bus',
    nl: 'Bus',
  },
  [TRAIN]: {
    id: `${headerScope}.${TRAIN}`,
    defaultMessage: 'Train',
    en: 'Train',
    it: 'Treno',
    es: 'Tren',
    de: 'Zug',
    nl: 'Trein',
  },
  [CARPOOLING]: {
    id: `${headerScope}.${CARPOOLING}`,
    defaultMessage: 'Covoiturage',
    en: 'Carpooling',
    it: 'Carpooling',
    es: 'Compartir coche',
    de: 'Mitfahrgelegenheiten',
    nl: 'Carpoolen',
  },
  [TOP_TRAVELS]: {
    id: `${headerScope}.${TOP_TRAVELS}`,
    defaultMessage: 'Top trajets',
    en: 'Top travels',
    it: 'Percorsi top',
    es: 'Rutas superiores',
    de: 'Top-Fahrten',
    nl: 'Top routes',
  },
  contact: {
    id: `${headerScope}.contact`,
    defaultMessage: 'Contact',
    en: 'Contact',
    it: 'Contatto',
    es: 'Contacte con',
    de: 'Kontakt',
    nl: 'Contact',
  },
  contactSupport: {
    id: `${headerScope}.contactSupport`,
    defaultMessage: 'Service Client',
    en: 'Support',
    it: 'Servizio clienti',
    es: 'Servicio de atención al cliente',
    de: 'Kundenservice',
    nl: 'Klantenservice',
  },
  cancellationAndRefund: {
    id: `${headerScope}.cancellationAndRefund`,
    defaultMessage: 'Annulation et Remboursement',
    en: 'Cancellation and Refund',
    it: 'Cancellazione e rimborso',
    es: 'Anulación y reembolso',
    de: 'Stornierung und Erstattung',
    nl: 'Annulering en terugbetaling',
  },
  exchangeMyTicket: {
    id: `${headerScope}.exchangeMyTicket`,
    defaultMessage: 'Échanger mon billet',
    en: 'Exchange my ticket',
    it: 'Scambio di biglietti',
    es: 'Cambiar mi billete',
    de: 'Mein Ticket eintauschen',
    nl: 'Mijn ticket omruilen',
  },
  purchaseSNCFAdvantageCard: {
    id: `${headerScope}.purchaseSNCFAdvantageCard`,
    defaultMessage: 'Cartes avantage SNCF',
    en: 'SNCF Advantage Cards',
    es: 'Tarjetas de ventaja SNCF',
    it: 'Carte vantaggio SNCF',
    de: 'SNCF-Vorteilskarten',
    nl: 'SNCF Voordeelkaarten',
  },
  resellYourTickets: {
    id: `${headerScope}.resellYourTickets`,
    defaultMessage: 'Revendre ses billets',
    en: 'Resell your tickets',
    it: 'Vendi il tuo biglietto',
    es: 'Vender su billete',
    de: 'Verkaufen Sie Ihr Ticket',
    nl: 'Verkoop uw ticket',
  },
  languages: {
    id: `${headerScope}.languages`,
    defaultMessage: 'Langues',
    en: 'Languages',
    it: 'Le lingue',
    es: 'Idiomas',
    de: 'Sprachen',
    nl: 'Talen',
  },
  fr: {
    id: `${headerScope}.fr`,
    defaultMessage: 'Français',
    en: 'Français',
    it: 'Français',
    es: 'Français',
    de: 'Français',
    nl: 'Français',
  },
  en: {
    id: `${headerScope}.en`,
    defaultMessage: 'English',
    en: 'English',
    it: 'English',
    es: 'English',
    de: 'English',
    nl: 'English',
  },
  es: {
    id: `${headerScope}.es`,
    defaultMessage: 'Español',
    en: 'Español',
    es: 'Español',
    it: 'Español',
    de: 'Español',
    nl: 'Español',
  },
  it: {
    id: `${headerScope}.it`,
    defaultMessage: 'Italiano',
    es: 'Italiano',
    en: 'Italiano',
    it: 'Italiano',
    de: 'Italiano',
    nl: 'Italiano',
  },
  de: {
    id: `${headerScope}.de`,
    defaultMessage: 'Deutsch',
    es: 'Deutsch',
    en: 'Deutsch',
    it: 'Deutsch',
    de: 'Deutsch',
    nl: 'Deutsch',
  },
  nl: {
    id: `${headerScope}.nl`,
    defaultMessage: 'Nederlands',
    es: 'Nederlands',
    en: 'Nederlands',
    it: 'Nederlands',
    de: 'Nederlands',
    nl: 'Nederlands',
  },
  [EFunnelStep.RESULTS]: {
    id: `${headerScope}.${EFunnelStep.RESULTS}`,
    defaultMessage: 'Résultats',
    en: 'Results',
    it: 'Risultati',
    es: 'Resultados',
    nl: 'Resultaten',
  },
  [EFunnelStep.INFO]: {
    id: `${headerScope}.${EFunnelStep.INFO}`,
    defaultMessage: 'Informations',
    en: 'Information',
    it: 'Informazioni',
    es: 'Información',
    de: 'Ergebnisse',
    nl: 'Informatie',
  },
  [EFunnelStep.PAYMENT]: {
    id: `${headerScope}.${EFunnelStep.PAYMENT}`,
    defaultMessage: 'Paiement',
    en: 'Payment',
    it: 'Pagamento',
    es: 'Pago',
    de: 'Zahlung',
    nl: 'Betaling',
  },
  stepResultsOutbound: {
    id: `${headerScope}.stepResultsOutbound`,
    defaultMessage: 'Aller',
    en: 'Departure',
    it: 'Andata',
    es: 'Ida',
    de: 'Hinreise',
    nl: 'Vertrek',
  },
  stepResultsReturn: {
    id: `${headerScope}.stepResultsReturn`,
    defaultMessage: 'Retour',
    en: 'Return',
    it: 'Ritorno',
    es: 'Vuelta',
    de: 'Rückreise',
    nl: 'Terug',
  },
  [TRANSPORTS]: {
    id: `${headerScope}.${TRANSPORTS}`,
    defaultMessage: 'Transports',
    en: 'Transports',
    it: 'Trasporto',
    es: 'Transporte',
    de: 'Verkehrsmittel',
    nl: 'Vervoer',
  },
  [ORDERS]: {
    id: `${headerScope}.${ORDERS}`,
    defaultMessage: 'Mes billets',
    en: 'My orders',
    it: 'I miei biglietti',
    es: 'Mis pedidos',
    de: 'Meine Tickets',
    nl: 'Mijn tickets',
  },
};

export default defineMessages(headerMessages);
