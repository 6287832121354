import { createContext, useContext, useEffect, useState } from 'react';
import { NAVIGATION } from '../../containers/App/constants';
import history from '../../utils/history';
import request from '../../utils/request';
import {
  clickIdToReferer,
  PARTNER_ID_URL_PARAMETER,
  URL_GET_PARTNER,
} from './constants';
import { EFunnelStep, IAppContext, IPartner } from './types';

export const AppContext = createContext<IAppContext>({
  partner: null,
  funnelStep: EFunnelStep.NONE,
  isInFunnel: false,
  getPartner: () => null,
  referer: null,
});

interface AppProviderProps {
  children: React.ReactNode;
}

export const AppProvider: React.FC<AppProviderProps> = ({ children }) => {
  const [partner, setPartner] = useState<IPartner | null>(null);
  const [funnelStep, setFunnelStep] = useState<EFunnelStep>(EFunnelStep.NONE);
  const [referer, setReferer] = useState<string | null>(null);

  const urlParameters: string = history.location.search;
  const urlPathname: string = history.location.pathname;

  // Update the partnerId if it is found in the URL
  useEffect(() => {
    const parameters = new URLSearchParams(urlParameters);
    const newPartnerId = parameters.get(PARTNER_ID_URL_PARAMETER);

    const clickIdKey = Object.keys(clickIdToReferer).find((key) =>
      parameters.has(key),
    );

    const newReferer = clickIdToReferer[clickIdKey];

    if (!newPartnerId && !newReferer) {
      return;
    }

    if (newReferer) {
      setReferer(newReferer);
    }

    if (newPartnerId) {
      getPartner(newPartnerId).then(setPartner);
    }
  }, [urlParameters]);

  // Update the funnel step according to the page url
  useEffect(() => {
    const slug = urlPathname.split('/')[1];
    switch (`/${slug}`) {
      case NAVIGATION.RESULTS:
        setFunnelStep(EFunnelStep.RESULTS);
        break;
      case NAVIGATION.INFO:
        setFunnelStep(EFunnelStep.INFO);
        break;
      case NAVIGATION.PAYMENT:
        setFunnelStep(EFunnelStep.PAYMENT);
        break;
      default:
        setFunnelStep(EFunnelStep.NONE);
        break;
    }
  }, [urlPathname]);

  const getPartner = (partnerId: string): Promise<IPartner> => {
    return request(`${URL_GET_PARTNER}/${partnerId}`);
  };

  const value: IAppContext = {
    partner,
    funnelStep,
    referer,
    isInFunnel: funnelStep !== EFunnelStep.NONE,
    getPartner,
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export const useApp = (): IAppContext => {
  const context = useContext(AppContext);

  if (!context) {
  }

  return context;
};
