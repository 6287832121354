import { NAVIGATION } from '../../containers/App/constants';
import { isMobileApp } from '../../helpers/app';
import history from '../../utils/history';

/**
 * @description Determine if the header should be display
 * @returns {bool}
 */
export const shouldDisplayMobileHeader = () => {
  return (
    isMobileApp(navigator.userAgent) &&
    (history.location.pathname === NAVIGATION.HOME ||
      [
        NAVIGATION.LOGIN,
        NAVIGATION.ORDERS,
        NAVIGATION.CONFIRMATION,
        NAVIGATION.SEARCH,
      ].some((url) => history.location.pathname.startsWith(url)))
  );
};
