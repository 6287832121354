import * as yup from 'yup';
import {
  EPassengerTitle,
  IDiscountCard,
  IIdentityDocument,
} from '../../common/types';
import { IUsePassengersManagement } from '../../hooks/usePassengersManagement/types';
import { dayjs } from '../../libs/day';

export const discountCardSchema: yup.ObjectSchema<IDiscountCard> = yup
  .object()
  .shape({
    cardCode: yup.string().required(),
    id: yup.string().required(),
    isIdRequired: yup.boolean().required(),
    label: yup.string().required(),
    visibility: yup.number().required(),
  });

export const passengerSchema = yup.object({
  age: yup.number().required(),
  birthDay: yup.number().nullable(),
  birthMonth: yup.number().nullable(),
  birthYear: yup.number().nullable(),
  birthdate: yup.string().nullable(),
  category: yup.string().required(),
  discountCardId: yup.string().nullable(),
  discountCards: yup.array().of(discountCardSchema),
  firstName: yup.string().nullable(),
  id: yup.number().required(),
  isCustomer: yup.boolean().nullable(),
  lastName: yup.string().nullable(),
  title: yup.string().nullable(),
});

export interface IPassenger {
  id: number;
  category?: string;
  age?: number;
  discountCards: IDiscountCard[];
  discountCardNumber?: string;
  title?: EPassengerTitle;
  firstName?: string;
  lastName?: string;
  birthdate?: string;
  isCustomer?: boolean;
  identityDocument?: IIdentityDocument;
}

export const searchParamsSchema = yup.object({
  destination: yup.string().required(),
  destinationId: yup.number().required(),
  origin: yup.string().required(),
  originId: yup.number().required(),
  outboundDate: yup
    .string()
    .test('valid-date', 'Outbound date must be a valid date', (value) =>
      dayjs(value).isValid(),
    )
    .test('future', 'Outbound date must be in the future', (value) =>
      dayjs(value).isSameOrAfter(dayjs(), 'day'),
    )
    .required(),
  outboundTime: yup.number().nullable(),
  passengers: yup.array().of(passengerSchema).min(1),
  returnDate: yup
    .string()
    .nullable()
    .test('valid-date', 'Return date must be a valid date', (value) => {
      if (value) return dayjs(value).isValid();

      return true;
    })
    .test('future', 'Return date must be in the future', (value) => {
      if (value) return dayjs(value).isSameOrAfter(dayjs(), 'day');

      return true;
    }),
  returnTime: yup.number().nullable(),
});

export type ISearchParameters = {
  destination: string;
  destinationId: number;
  origin: string;
  originId: number;
  outboundDate: string;
  outboundTime: number | null;
  passengers: IPassenger[];
  returnDate: string | null;
  returnTime: number | null;
  refererId?: string;
};

export type ISearchContext = {
  searchParameters: ISearchParameters | null;
  setSearchParameters: React.Dispatch<
    React.SetStateAction<ISearchParameters | null>
  >;
  getSearchParametersFromUrl: (url: string) => ISearchParameters;
  createSearchUrl: (
    searchParameters: ISearchParameters,
    refererId?: string,
  ) => string;

  getPopularCitiesFrom: (
    cityName: string,
    limit?: number,
  ) => Promise<ISearchCity[]>;
  getPopularCitiesTo: (
    cityName: string,
    limit?: number,
  ) => Promise<ISearchCity[]>;
  getPopularCities: (limit?: number) => Promise<ISearchCity[]>;
  getSuggestedCities: (
    cityName: string,
    partnerId?: string,
    limit?: number,
  ) => Promise<ISearchCity[]>;

  setOrigin: (name: string, id) => void;
  setDestination: (name: string, id) => void;
  reverseCities: () => void;
  setOutboundDate: (date: string, from: number | null) => void;
  setReturnDate: (date: string, from: number | null) => void;
  getDiscountCards: () => Promise<IDiscountCard[]>;
  setPassengers: (passengers: IPassenger[]) => void;
  customer: ISearchCustomer;
  setCustomer: React.Dispatch<React.SetStateAction<ISearchCustomer>>;
  passengersManagement: IUsePassengersManagement;
};

export interface ISearchCustomer {
  passengerId?: number;
  mail: string;
  title: EPassengerTitle;
  firstName: string;
  lastName: string;
  birthdate: string;
  phoneNumber?: string;
  language?: string;
  isSubscribedToNewsletter?: boolean;
}

export interface IPopularCity {
  id: number;
  unique_name: string;
  local_name: string;
  latitude: number;
  longitude: number;
  new_id: string;
  city_id: number;
  gpuid: string;
  nb_search: string;
  popular: boolean;
  iscity: boolean;
}

export interface IAutoComplete {
  unique_name: string;
  local_name: string;
  city_id: number;
  station_id?: number;
  station_unique_name?: string;
  latitude: number;
  longitude: number;
  gpuid: string;
  iscity: boolean;
  distance?: number;
  nearfrom?: string;
  originalLat?: number;
  originalLon?: number;
}

export interface ISearchCity {
  id: number;
  gpuid: string;
  name: string;
  region: string;
  country: string;
  stations: Array<{
    id: number;
    name: string;
  }>;
  nearFrom: {
    name: string;
    distance: number;
  } | null;
}
