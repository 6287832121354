import { css } from 'styled-components/macro';
import tw from 'tailwind.macro';
import { colors } from '../../../styles/constants';

export const tabLinkStyle = css`
  ${tw`h-full flex items-center
    cursor-pointer outline-none
    text-text
    no-underline
    lg:ml-abs3
    lg:ml-abs4
    lg:inline-flex`};
  font-size: 18px;
  padding-bottom: 15px;
  padding-top: 15px;
  user-select: none;
  border-bottom: 1px solid ${colors.grey200};
  -webkit-tap-highlight-color: transparent;

  @media (min-width: 992px) {
    border-bottom: 7px solid transparent;
    border-top: 7px solid transparent;
    &:hover {
      border-bottom: 7px solid ${colors.terciary500};
    }
  }
`;
