/*
 * Detailed Trip Messages
 *
 * This contains all the text for the Payment container.
 */

import { defineMessages } from 'react-intl';
import { BUS, CARPOOLING, TRAIN } from '../../containers/Search/constants';

export const detailedTripScope = 'app.components.detailedTrip';

export const detailedTripMessages = {
  [BUS]: {
    id: `${detailedTripScope}.${BUS}`,
    defaultMessage: 'bus',
    en: 'bus',
    it: 'autobus',
    es: 'autobús',
    de: 'Bus',
    nl: 'Bus',
  },
  [TRAIN]: {
    id: `${detailedTripScope}.${TRAIN}`,
    defaultMessage: 'train',
    en: 'train',
    it: 'treno',
    es: 'tren',
    de: 'Zug',
    nl: 'Trein',
  },
  [CARPOOLING]: {
    id: `${detailedTripScope}.${CARPOOLING}`,
    defaultMessage: 'covoiturage',
    en: 'carpooling',
    it: 'carpooling',
    es: 'compartido',
    de: 'Mitfahrgelegenheit',
    nl: 'Carpool',
  },

  redirect: {
    id: `${detailedTripScope}.redirect`,

    defaultMessage: 'Réserver le {transportType} chez {company} ',
    en: 'Book {transportType} on {company} ',
    it: 'Prenota il {transportType} su {company} ',
    es: 'Reservar el viaje {transportType} en {company} ',
    de: '{transportType} bei {company} buchen ',
    nl: '{transportType} reserveren op {company} ',
  },
  payment: {
    id: `${detailedTripScope}.payment`,
    defaultMessage: 'Réserver',
    en: 'Book',
    it: 'Libro',
    es: 'Reservar',
    de: 'Buchen',
    nl: 'Boek',
  },
  booked: {
    id: `${detailedTripScope}.booked`,
    defaultMessage: 'Ce billet a été acheté avec succès',
    en: 'This ticket was successfully booked',
    it: 'Questo biglietto è stato acquistato con successo',
    es: 'Este billete ha sido comprado con éxito',
    de: 'Dieses Ticket wurde erfolgreich gekauft',
    nl: 'Dit ticket is succesvol gekocht',
  },
  bookingError: {
    id: `${detailedTripScope}.bookingError`,
    defaultMessage:
      'Une erreur est survenue lors de la réservation chez le transporteur',
    en: 'Something went wrong during the booking with the provider',
    it: 'Si è verificato un errore durante la prenotazione con il vettore',
    es: 'Se ha producido un error al reservar con el transportista',
    de: 'Bei der Buchung beim Transportunternehmen ist ein Fehler aufgetreten',
    nl: 'Er is een fout opgetreden bij het boeken bij de vervoerder',
  },
  notBooked: {
    id: `${detailedTripScope}.notBooked`,
    defaultMessage: 'Une erreur est survenue : ',
    en: 'Something went wrong during the booking: ',
    it: 'Si è verificato un errore: ',
    es: 'Se ha producido un error: ',
    de: 'Es ist ein Fehler aufgetreten: ',
    nl: 'Er is een fout opgetreden: ',
  },
  contactSupport: {
    id: `${detailedTripScope}.contactSupport`,
    defaultMessage: 'Cliquez ici pour contacter le support',
    en: 'Click here to contact support',
    it: "Fare clic qui per contattare l'assistenza",
    es: 'Haga clic aquí para ponerse en contacto con el servicio de asistencia',
    de: 'Klicken Sie hier, um den Support zu kontaktieren',
    nl: 'Klik hier om contact op te nemen',
  },
  retryPayment: {
    id: `${detailedTripScope}.retryPayment`,
    defaultMessage: 'Cliquez ici pour essayer avec un autre moyen de paiement',
    en: 'Click here to try booking with another payment method',
    it: 'Fare clic qui per provare a prenotare con un altro metodo di pagamento',
    de: 'Klicken Sie hier, um es mit einer anderen Zahlungsmethode zu versuchen',
    nl: 'Klik hier om een andere betaalmethode te proberen',
  },
  pendingBooking: {
    id: `${detailedTripScope}.pendingBooking`,
    defaultMessage: "Vos billets sont en cours d'émission",
    en: 'Your order is being processed',
    it: "L'ordine è in fase di elaborazione",
    es: 'Haga clic aquí para probar otro método de pago',
    de: 'Ihre Bestellung wird gerade bearbeitet',
    nl: 'Uw bestelling wordt verwerkt',
  },
  transfer: {
    id: `${detailedTripScope}.transfer`,
    defaultMessage: '{duration}&nbsp;pour&nbsp;changer&nbsp;de&nbsp;gare',
    en: '{duration}&nbsp;to&nbsp;change&nbsp;station',
    it: '{duration}&nbsp;per&nbsp;cambiare&nbsp;stazione',
    es: '{duration}&nbsp;para&nbsp;cambiar&nbsp;estación',
    de: '{duration}&nbsp;um&nbsp;den&nbsp;Bahnhof&nbsp;zu&nbsp;wechseln',
    nl: '{duration}&nbsp;om&nbsp;station&nbsp;te&nbsp;wisselen',
  },
  transferSameStation: {
    id: `${detailedTripScope}.transferSameStation`,
    defaultMessage: '{duration}&nbsp;de&nbsp;correspondance',
    en: '{duration}&nbsp;of&nbsp;correspondence',
    it: '{duration}&nbsp;di&nbsp;corrispondenza',
    es: '{duration}&nbsp;de&nbsp;correspondencia',
    de: '{duration}&nbsp;Korrespondenz',
    nl: '{duration}&nbsp;Correspondentie',
  },
  createOrderLoading: {
    id: `${detailedTripScope}.createOrderLoading`,
    defaultMessage: 'Vérification de votre panier en cours',
    en: 'Currently validating your order',
    it: 'Controllo del carrello attuale',
    es: 'Comprobación de la cesta actual',
    de: 'Ihre Bestellung wird derzeit überprüft',
    nl: 'Verificatie van momenteel uw bestelling',
  },
  duration: {
    id: `${detailedTripScope}.duration`,
    defaultMessage: 'Durée',
    en: 'Duration',
    it: 'Durata',
    es: 'Duración',
    de: 'Dauer',
    nl: 'Duur',
  },
  day: {
    id: `${detailedTripScope}.day`,
    defaultMessage: 'jour',
    en: 'day',
    it: 'giorno',
    es: 'día',
    de: 'tag',
    nl: 'dag',
  },
  cancelBookingTitle: {
    id: `${detailedTripScope}.cancelBookingTitle`,
    defaultMessage: 'Annuler un billet',
    en: 'Cancel a ticket',
    it: 'Annullare un biglietto',
    es: 'Cancelar un billete',
    de: 'Ein Ticket stornieren',
    nl: 'Een ticket annuleren',
  },
  editBookingTitle: {
    id: `${detailedTripScope}.editBookingTitle`,
    defaultMessage: 'Modifier un billet',
    en: 'Edit a ticket',
    it: 'Modificare un biglietto',
    es: 'Editar un billete',
    de: 'Ein Ticket bearbeiten',
    nl: 'Een ticket bewerken',
  },
  cancelBooking: {
    id: `${detailedTripScope}.cancelBooking`,
    defaultMessage: 'Annuler ce billet',
    en: 'Cancel this ticket',
    it: 'Annullare questo biglietto',
    es: 'Cancelar este billete',
    de: 'Dieses Ticket stornieren',
    nl: 'Annuleer dit ticket',
  },
  ticketBeingCancelled: {
    id: `${detailedTripScope}.ticketBeingCancelled`,
    defaultMessage: "Réservation en cours d'annulation",
    en: 'Ticket being cancelled',
    it: 'Cancellazione della prenotazione',
    es: 'Anulación de la reserva',
    de: 'Stornierung der Reservierung',
    nl: 'Annulering aan de gang',
  },
  ticketSuccessfullyCancelled: {
    id: `${detailedTripScope}.ticketSuccessfullyCancelled`,
    defaultMessage: 'Réservation annulée avec succès',
    en: 'Ticket successfully cancelled',
    it: 'Prenotazione annullata con successo',
    es: 'Reserva cancelada con éxito',
    de: 'Buchung erfolgreich storniert',
    nl: 'Reservering succesvol geannuleerd',
  },
  ticketCancellationError: {
    id: `${detailedTripScope}.ticketCancellationError`,
    defaultMessage: 'Une erreur est survenue, veuillez réessayer !',
    en: 'Something went wrong, please try again!',
    it: 'Si è verificato un errore, riprovare!',
    es: 'Se ha producido un error, ¡inténtelo de nuevo!',
    de: 'Es ist ein Fehler aufgetreten, bitte versuchen Sie es erneut!',
    nl: 'Er is een fout opgetreden, probeer het opnieuw!',
  },
  cancellableTicketMessage: {
    id: `${detailedTripScope}.cancellableTicketMessage`,
    defaultMessage: 'Bonne nouvelle, ',
    en: 'Good news, ',
    it: 'Buone notizie, ',
    es: '¡Buenas noticias, ',
    de: 'Gute Nachrichten, ',
    nl: 'Goed nieuws, ',
  },
  cancellableTicketMessageBoldPart: {
    id: `${detailedTripScope}.cancellableTicketMessageBoldPart`,
    defaultMessage: 'ce billet est annulable !',
    en: 'this ticket is cancelable!',
    it: 'questo biglietto è annullabile!',
    es: 'este billete es cancelable!',
    de: 'dieses Ticket ist stornierbar!',
    nl: 'dit ticket is annuleerbaar!',
  },
  cancellableVoucherTicketMessageBoldPart: {
    id: `${detailedTripScope}.cancellableVoucherTicketMessageBoldPart`,
    defaultMessage: 'Ce billet est annulable ',
    en: 'Good news, this ticket is cancelable!',
    it: 'Bene, questo biglietto è annullabile!',
    es: '¡Buenas noticias, este billete es cancelable!',
    de: 'Gute Nachricht, dieses Ticket ist stornierbar!',
    nl: 'Goed nieuws, dit ticket is annuleerbaar!',
  },
  cancellableVoucherTicketMessage1: {
    id: `${detailedTripScope}.cancellableVoucherTicketMessage1`,
    defaultMessage: 'pour un montant ',
    en: 'for an amount ',
    it: 'per un importo ',
    es: 'por un monto ',
    de: 'für einen Betrag ',
    nl: 'voor een bedrag ',
  },
  cancellableVoucherTicketMessage2: {
    id: `${detailedTripScope}.cancellableVoucherTicketMessage2`,
    defaultMessage: 'de ',
    en: 'of ',
    it: 'di ',
    es: 'de ',
    de: 'von ',
    nl: 'van ',
  },
  cancellableVoucherTicketMessage3: {
    id: `${detailedTripScope}.cancellableVoucherTicketMessage3`,
    defaultMessage: " garanti jusqu'au ",
    en: ' guaranteed until ',
    it: ' garantito fino al ',
    es: ' garantizado hasta el ',
    de: ' garantiert bis ',
    nl: ' gegarandeerd tot ',
  },
  notCancellableTicketMessage: {
    id: `${detailedTripScope}.notCancellableTicketMessage`,
    defaultMessage: "Malheureusement ce billet n'est pas ",
    en: 'Unfortunately, this ticket is not ',
    it: 'Purtroppo, questo biglietto non è ',
    es: 'Desafortunadamente, este billete no es ',
    de: 'Leider ist dieses Ticket nicht ',
    nl: 'Helaas is dit ticket niet ',
  },
  notCancellableTicketMessage1: {
    id: `${detailedTripScope}.notCancellableTicketMessage1`,
    defaultMessage: 'annulable ou remboursable.',
    en: 'cancelable or refundable.',
    it: 'annullabile o rimborsabile.',
    es: 'cancelable ni reembolsable.',
    de: 'stornierbar oder erstattungsfähig.',
    nl: 'annuleerbaar of restitueerbaar.',
  },
  notCancellableMessageRefundNull: {
    id: `${detailedTripScope}.notCancellableMessageRefundNull`,
    defaultMessage: 'Mince, ',
    en: 'Thin, ',
    it: 'Sottile, ',
    es: 'Delgado ',
    de: 'Dünn, ',
    nl: 'Dun, ',
  },
  notCancellableMessageRefundNullBoldPart: {
    id: `${detailedTripScope}.notCancellableMessageRefundNullBoldPart`,
    defaultMessage: "ce billet n'est pas annulable.",
    en: 'this ticket cannot be canceled.',
    it: 'questo biglietto non è annullabile.',
    es: 'este billete no se puede cancelar.',
    de: 'dieses Ticket kann nicht storniert werden.',
    nl: 'dit ticket kan niet worden geannuleerd.',
  },
  notCancellableMessageRefundNull1: {
    id: `${detailedTripScope}.notCancellableMessageRefundNull1`,
    defaultMessage: "Besoin d'un coup de pouce, écrivez-nous.",
    en: 'Need a hand, write to us.',
    it: 'Hai bisogno di una mano, scrivici.',
    es: '¿Necesitas ayuda? Escríbenos.',
    de: 'Brauchen Sie Hilfe? Schreiben Sie uns.',
    nl: 'Hulp nodig? Schrijf ons.',
  },
  notEditableTicketMessage: {
    id: `${detailedTripScope}.notEditableTicketMessage`,
    defaultMessage: 'Malheureusement, ',
    en: 'Unfortunately, ',
    it: 'Purtroppo, ',
    es: 'Lamentablemente ',
    de: 'Leider, ',
    nl: 'Helaas, ',
  },
  notEditableTicketMessageBoldPart: {
    id: `${detailedTripScope}.notEditableTicketMessageBoldPart`,
    defaultMessage: "ce billet n'est",
    en: "this ticket isn't",
    it: 'questo biglietto non è',
    es: 'este boleto no es',
    de: 'dieses Ticket ist nicht',
    nl: 'dit ticket is niet',
  },
  notEditableTicketMessageBoldPart1: {
    id: `${detailedTripScope}.notEditableTicketMessageBoldPart1`,
    defaultMessage: 'pas modifiable !',
    en: 'modifiable!',
    it: 'modificabile!',
    es: 'modificable!',
    de: 'veränderbar!',
    nl: 'aanpasbaar!',
  },
  editableTicketMessage: {
    id: `${detailedTripScope}.editableTicketMessage`,
    defaultMessage: 'Bonne nouvelle, ',
    en: 'Good news, ',
    it: 'Buone notizie, ',
    es: 'Buenas noticias, ',
    de: 'Gute Nachrichten, ',
    nl: 'Goed nieuws, ',
  },
  editableTicketMessageBoldPart: {
    id: `${detailedTripScope}.editableTicketMessageBoldPart`,
    defaultMessage: 'ce billet est modifiable !',
    en: 'this ticket is modifiable!',
    it: 'questo biglietto è modificabile!',
    es: 'este boleto es modificable!',
    de: 'dieses Ticket ist veränderbar!',
    nl: 'dit ticket is aanpasbaar!',
  },
  notEditableMessageRefundNull: {
    id: `${detailedTripScope}.notEditableMessageRefundNull`,
    defaultMessage: 'Mince, ',
    en: 'Thin, ',
    it: 'Sottile, ',
    es: 'Delgado ',
    de: 'Dünn, ',
    nl: 'Dun, ',
  },
  notEditableMessageRefundNullBoldPart: {
    id: `${detailedTripScope}.notEditableMessageRefundNullBoldPart`,
    defaultMessage: "ce billet n'est pas modifiable.",
    en: "this ticket isn't modifiable.",
    it: 'questo biglietto non è modificabile.',
    es: 'este boleto no es modificable.',
    de: 'dieses Ticket ist nicht veränderbar.',
    nl: 'dit ticket is niet aanpasbaar.',
  },
  notEditableMessageRefundNull1: {
    id: `${detailedTripScope}.notEditableMessageRefundNull1`,
    defaultMessage: "Besoin d'un coup de pouce, écrivez-nous.",
    en: 'Need a hand? Write to us.',
    it: 'Hai bisogno di aiuto? Scrivici.',
    es: '¿Necesitas ayuda? Escríbenos.',
    de: 'Brauchen Sie Hilfe? Schreiben Sie uns.',
    nl: 'Hulp nodig? Schrijf ons.',
  },
  notCancellableMessageSAVDown: {
    id: `${detailedTripScope}.notCancellableMessageSAVDown`,
    defaultMessage: "L'annulation automatique n'est pas disponible",
    en: 'Automatic cancellation is not available',
    it: "L'annullamento automatico non è disponibile",
    es: 'La cancelación automática no está disponible',
    de: 'Automatische Stornierung ist nicht verfügbar',
    nl: 'Automatische annulering is niet beschikbaar',
  },
  notCancellableMessageSAVDown1: {
    id: `${detailedTripScope}.notCancellableMessageSAVDown1`,
    defaultMessage: 'chez notre partenaire actuellement.',
    en: 'with our partner at the moment.',
    it: 'con il nostro partner al momento.',
    es: 'con nuestro socio en este momento.',
    de: 'bei unserem Partner im Moment.',
    nl: 'bij onze partner op dit moment.',
  },
  notEditableMessageSAVDown: {
    id: `${detailedTripScope}.notEditableMessageSAVDown`,
    defaultMessage: "La modification automatique n'est pas disponible",
    en: 'Automatic modification is not available',
    it: 'La modifica automatica non è disponibile',
    es: 'La modificación automática no está disponible',
    de: 'Automatische Änderung ist nicht verfügbar',
    nl: 'Automatische wijziging is niet beschikbaar',
  },
  notEditableMessageSAVDown1: {
    id: `${detailedTripScope}.notEditableMessageSAVDown1`,
    defaultMessage: 'chez notre partenaire actuellement.',
    en: 'with our partner currently.',
    it: 'con il nostro partner attualmente.',
    es: 'con nuestro socio actualmente.',
    de: 'bei unserem Partner derzeit.',
    nl: 'bij onze partner momenteel.',
  },
  notEditableTipFlixbus: {
    id: `${detailedTripScope}.notEditableTipFlixbus`,
    defaultMessage: 'Astuce : vous pouvez annuler votre billet et',
    en: 'Tip: you can cancel your ticket and',
    it: 'Consiglio: puoi annullare il tuo biglietto e',
    es: 'Consejo: puedes cancelar tu boleto y',
    de: 'Tipp: Sie können Ihr Ticket stornieren und',
    nl: 'Tip: u kunt uw ticket annuleren en',
  },
  notEditableTipFlixbus1: {
    id: `${detailedTripScope}.notEditableTipFlixbus1`,
    defaultMessage: 'obtenir un remboursement sous forme de',
    en: 'get a refund in the form of',
    it: 'ottenere un rimborso sotto forma di',
    es: 'obtener un reembolso en forma de',
    de: 'eine Rückerstattung in Form von erhalten',
    nl: 'een terugbetaling krijgen in de vorm van',
  },
  notEditableTipFlixbus2: {
    id: `${detailedTripScope}.notEditableTipFlixbus2`,
    defaultMessage: "bon d'achat.",
    en: 'voucher.',
    it: 'buono acquisto.',
    es: 'vale de compra.',
    de: 'Gutschein.',
    nl: 'tegoedbon.',
  },
  editableTipOuigoBlablacar: {
    id: `${detailedTripScope}.editableTipOuigoBlablacar`,
    defaultMessage: 'Astuce : vous pouvez modifier votre billet',
    en: 'Tip: you can modify your ticket',
    it: 'Consiglio: puoi modificare il tuo biglietto',
    es: 'Consejo: puedes modificar tu boleto',
    de: 'Tipp: Sie können Ihr Ticket ändern',
    nl: 'Tip: u kunt uw ticket wijzigen',
  },
  editableTipOuigoBlablacar1: {
    id: `${detailedTripScope}.editableTipOuigoBlablacar1`,
    defaultMessage: 'directement sur le site de notre',
    en: "directly on our partner's website",
    it: 'direttamente sul sito del nostro partner',
    es: 'directamente en el sitio web de nuestro socio',
    de: 'direkt auf der Website unseres Partners',
    nl: 'direct op de website van onze partner',
  },
  editableTipOuigoBlablacar2: {
    id: `${detailedTripScope}.editableTipOuigoBlablacar2`,
    defaultMessage: 'partenaire.',
    en: 'partner.',
    it: 'partner.',
    es: 'socio.',
    de: 'Partner.',
    nl: 'partner.',
  },
  editableTipAltibus: {
    id: `${detailedTripScope}.editableTipAltibus`,
    defaultMessage: 'Astuce : ce billet est valide pour toutes',
    en: 'Tip: this ticket is valid for all',
    it: 'Consiglio: questo biglietto è valido per tutti',
    es: 'Consejo: este boleto es válido para todos',
    de: 'Tipp: Dieses Ticket ist für alle gültig',
    nl: 'Tip: dit ticket is geldig voor alle',
  },
  editableTipAltibus1: {
    id: `${detailedTripScope}.editableTipAltibus1`,
    defaultMessage: 'les navettes du jour en direction de votre',
    en: 'shuttles of the day heading to your',
    it: 'navette del giorno in direzione della tua',
    es: 'traslados del día hacia tu',
    de: 'Shuttles des Tages in Richtung Ihres',
    nl: 'pendeldiensten van de dag naar uw',
  },
  editableTipAltibus2: {
    id: `${detailedTripScope}.editableTipAltibus2`,
    defaultMessage: 'destination.',
    en: 'destination.',
    it: 'destinazione.',
    es: 'destino.',
    de: 'Ziel.',
    nl: 'bestemming.',
  },
  editableTipVSC: {
    id: `${detailedTripScope}.editableTipVSC`,
    defaultMessage: 'Astuce : toutes les conditions de',
    en: 'Tip: all modification conditions are',
    it: 'Consiglio: tutte le condizioni di modifica sono',
    es: 'Consejo: todas las condiciones de modificación son',
    de: 'Tipp: Alle Änderungsbedingungen sind',
    nl: 'Tip: alle wijzigingsvoorwaarden zijn',
  },
  editableTipVSC1: {
    id: `${detailedTripScope}.editableTipVSC1`,
    defaultMessage: 'modifications sont indiquées sur votre',
    en: 'indicated on your',
    it: 'indicate sul tuo',
    es: 'indicadas en tu',
    de: 'auf Ihrem angegeben',
    nl: 'aangegeven op uw',
  },
  editableTipVSC2: {
    id: `${detailedTripScope}.editableTipVSC2`,
    defaultMessage: 'billet.',
    en: 'ticket.',
    it: 'biglietto.',
    es: 'boleto.',
    de: 'Ticket.',
    nl: 'ticket.',
  },
  refundAmount: {
    id: `${detailedTripScope}.refundAmount`,
    defaultMessage: 'Le montant du remboursement est de ',
    en: 'Refund amount: ',
    it: 'Importo del rimborso: ',
    es: 'Importe del reembolso: ',
    de: 'Rückerstattungsbetrag: ',
    nl: 'Terugbetaling bedrag: ',
  },
  noteCancellable: {
    id: `${detailedTripScope}.noteCancellable`,
    defaultMessage: 'Le remboursement est garanti ',
    en: 'The refund is guaranteed ',
    it: 'Il rimborso è garantito ',
    es: 'El reembolso está garantizado ',
    de: 'Die Rückerstattung ist garantiert ',
    nl: 'De terugbetaling is gegarandeerd ',
  },
  noteCancellableBoldPart: {
    id: `${detailedTripScope}.noteCancellableBoldPart`,
    defaultMessage: 'jusqu’au ',
    en: 'until ',
    it: 'fino al ',
    es: 'hasta el ',
    de: 'bis zum ',
    nl: 'tot ',
  },
  noteCancellableVoucher: {
    id: `${detailedTripScope}.noteCancellableVoucher`,
    defaultMessage: "Le remboursement s'effectue en bon",
    en: 'The refund will be issued as a voucher',
    it: 'Il rimborso verrà effettuato tramite voucher',
    es: 'El reembolso se realizará mediante un vale',
    de: 'Die Rückerstattung erfolgt in Form eines Gutscheins',
    nl: 'De terugbetaling wordt uitgegeven als voucher',
  },
  noteCancellableVoucher1: {
    id: `${detailedTripScope}.noteCancellableVoucher1`,
    defaultMessage: "d'achat valable 1 an à utiliser sur le site",
    en: 'valid for 1 year to be used on the website',
    it: "d'acquisto valido 1 anno da utilizzare sul sito",
    es: 'de compra válido por 1 año para usar en el sitio',
    de: 'gültig für 1 Jahr zur Verwendung auf der Website',
    nl: 'geldig voor 1 jaar te gebruiken op de website',
  },
  noteCancellableVoucher2: {
    id: `${detailedTripScope}.noteCancellableVoucher2`,
    defaultMessage: ' de notre partenaire.',
    en: ' of our partner.',
    it: ' del nostro partner.',
    es: ' de nuestro socio.',
    de: ' unseres Partners.',
    nl: ' van onze partner.',
  },
  notCancelableTicketMessage: {
    id: `${detailedTripScope}.notCancelableTicketMessage`,
    defaultMessage: 'Contacter notre (super) service client',
    en: 'Contact our (super) customer service',
    it: 'Contatta il nostro (super) servizio clienti',
    es: 'Contacta con nuestro (súper) servicio al cliente',
    de: 'Kontaktieren Sie unseren (super) Kundenservice',
    nl: 'Neem contact op met onze (super) klantenservice',
  },

  noteNotCancellable: {
    id: `${detailedTripScope}.noteNotCancellable`,
    defaultMessage: 'Astuce : ',
    en: 'Tip: ',
    it: 'Suggerimento: ',
    es: 'Consejo: ',
    de: 'Tipp: ',
    nl: 'Tip: ',
  },
  noteNotCancellableBoldPart: {
    id: `${detailedTripScope}.noteNotCancellableBoldPart`,
    defaultMessage: 'vous pouvez modifier votre billet ',
    en: 'you can modify your ticket ',
    it: 'puoi modificare il tuo biglietto ',
    es: 'puedes modificar tu billete ',
    de: 'Sie können Ihr Ticket ändern ',
    nl: 'u kunt uw ticket wijzigen ',
  },
  noteNotCancellable1: {
    id: `${detailedTripScope}.noteNotCancellable1`,
    defaultMessage: 'directement sur le site de notre partenaire',
    en: "directly on our partner's website",
    it: 'direttamente sul sito del nostro partner',
    es: 'directamente en el sitio web de nuestro socio',
    de: 'direkt auf der Website unseres Partners',
    nl: 'rechtstreeks op de website van onze partner',
  },
  noteNotCancellable2: {
    id: `${detailedTripScope}.noteNotCancellable2`,
    defaultMessage: 'avec votre numéro de billet.',
    en: 'with your ticket number.',
    it: 'con il tuo numero di biglietto.',
    es: 'con tu número de billete.',
    de: 'mit Ihrer Ticketnummer.',
    nl: 'met uw ticketnummer.',
  },
  noteNotCancellableMessageSAVDown: {
    id: `${detailedTripScope}.noteNotCancellableMessageSAVDown`,
    defaultMessage: 'On prend le relai, écrivez-nous. 💬',
    en: "We'll take over, write to us. 💬",
    it: 'Ci occupiamo noi, scrivici. 💬',
    es: 'Nosotros nos encargamos, escríbenos. 💬',
    de: 'Wir übernehmen, schreiben Sie uns. 💬',
    nl: 'Wij nemen het over, schrijf ons. 💬',
  },
  confirm: {
    id: `${detailedTripScope}.confirm`,
    defaultMessage: "Confirmer l'annulation",
    en: 'Confirm cancellation',
    it: 'Conferma annullamento',
    es: 'Confirmar cancelación',
    de: 'Stornierung bestätigen',
    nl: 'Annulering bevestigen',
  },
  edition: {
    id: `${detailedTripScope}.edition`,
    defaultMessage: 'Modifier mon billet',
    en: 'Edit my ticket',
    it: 'Modifica il mio biglietto',
    es: 'Editar mi boleto',
    de: 'Mein Ticket bearbeiten',
    nl: 'Mijn ticket bewerken',
  },
  voucherModalTitle: {
    id: `${detailedTripScope}.voucherModalTitle`,
    defaultMessage: 'Coupon de remboursement',
    en: 'Refund coupon',
    it: 'Certificato regalo',
    es: 'Cupón de canje',
    de: 'Rückzahlungscoupon',
    nl: 'Waardebon',
  },
  voucherAmount: {
    id: `${detailedTripScope}.voucherAmount`,
    defaultMessage: 'Montant du bon d’achat : ',
    en: 'Voucher amount: ',
    it: 'Importo del buono: ',
    es: 'Importe del cupón: ',
    de: 'Gutscheinbetrag: ',
    nl: 'Waardebonbedrag: ',
  },
  voucherExpirationDate: {
    id: `${detailedTripScope}.voucherExpirationDate`,
    defaultMessage: "Date d'expiration :",
    en: 'Expiration date:',
    it: 'Data di scadenza:',
    es: 'Fecha de caducidad:',
    de: 'Ablaufdatum:',
    nl: 'Vervaldatum :',
  },
  voucherCopied: {
    id: `${detailedTripScope}.voucherCopied`,
    defaultMessage: 'Copié avec succès',
    en: 'Successfully copied',
    it: 'Copiato con successo',
    es: 'Copiado con éxito',
    de: 'Erfolgreich kopiert',
    nl: 'Succesvol gekopieerd',
  },
  voucherUsableOn: {
    id: `${detailedTripScope}.voucherUsableOn`,
    defaultMessage: 'Coupon utilisable sur le site de {usableOn}',
    en: 'Coupon usable on the site of {usableOn}',
    it: 'Coupon da utilizzare sul sito web di {usableOn}',
    es: 'Cupón para utilizar en el sitio web de {usableOn}',
    de: 'Gutschein einlösbar auf der Seite von {usableOn}',
    nl: 'Coupon te gebruiken op de {usableOn} website',
  },
  copy: {
    id: `${detailedTripScope}.copy`,
    defaultMessage: 'Copier dans le presse-papiers',
    en: 'Copy in the clipboard',
    it: 'Copia negli appunti',
    es: 'Copiar al portapapeles',
    de: 'In die Zwischenablage kopieren',
    nl: 'Kopieer naar klembord',
  },
  autoRefundCTAMessage: {
    id: `${detailedTripScope}.autoRefundCTAMessage`,
    defaultMessage: 'Nous avons procédé au remboursement de votre trajet.',
    en: 'We have refunded your trip.',
    it: 'Abbiamo rimborsato il tuo viaggio.',
    es: 'Hemos reembolsado su viaje.',
    de: 'Wir haben Ihre Reise erstattet.',
    nl: 'We hebben uw reis terugbetaald.',
  },
  rebookTrip: {
    id: `${detailedTripScope}.rebookTrip`,
    defaultMessage: 'Recommencer la réservation',
    en: 'Restart booking',
    it: 'Ricomincia la prenotazione',
    es: 'Reiniciar la reserva',
    de: 'Buchung neu starten',
    nl: 'Boeking opnieuw starten',
  },
};

export default defineMessages(detailedTripMessages);
