import '../../../animations/CSSTransition/slideTop.css';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { CSSTransition } from 'react-transition-group';
import styled from 'styled-components/macro';
import tw from 'tailwind.macro';
import H4 from '../../../components/H4';
import { Icon18 } from '../../../components/Icon';
import ModalBackground from '../../../components/ModalBackground';
import ArrowSrc from '../../../images/arrowRightWhiteThin.svg';
import IconUrlClose from '../../../images/iconClose.svg';
import { hideScrollbar } from '../../../styles';
import messages from './messages';
import { ModalLinkList } from './ModalLinkList';
import NavBar from './NavBar';
import { Wrapper } from './Wrapper';

const Background = styled(ModalBackground)`
  position: absolute;
  top: unset;
  height: 100vh;
  z-index: -1;
  align-items: start;
`;

const ModalWrapper = styled(Wrapper)`
  ${tw`absolute w-full h-full
    bg-white z-0
    md:h-auto`};
`;

const Container = styled(NavBar)`
  ${tw`block md:py-abs4 py-0 px-def overflow-y-scroll
    md:px-def`};
  @media (min-width: 768px) {
    ${hideScrollbar};
  }
`;

const Content = styled.div`
  ${tw`shadow-medium2 pb-def fixed
    md:relative bg-terciary500 text-white
    md:bg-transparent md:text-text md:shadow-none
    w-full z-10 pt-def px-abs1 md:px-0
    md:pt-0`};
  left: 0;
`;

const Header = styled(H4)`
  ${tw`flex sm:text-h4-m
  m-0`};
`;

const Arrow = styled.img`
  ${tw`mx-abs1
    md:hidden`};
  transform: rotate(180deg);
`;

const Close = styled.img`
  ${Icon18}
  ${tw`hidden
    cursor-pointer
    md:block`};
  position: absolute;
  right: 20px;
  top: 20px;
`;

const Modal = ({
  category,
  closeModal,
  displayModal,
  handleDrawer,
  links,
  selectLocale,
}) => (
  <Background id="modalBackground" onClick={() => closeModal(category)}>
    <CSSTransition appear classNames="slideTop" in={displayModal} timeout={250}>
      <ModalWrapper
        id="modalContainer"
        onClick={(event) => event.stopPropagation()}
      >
        <Close
          alt="close modal"
          id="closeModalHeader"
          onClick={() => closeModal(category)}
          src={IconUrlClose}
        />
        <Container>
          <Content>
            <Header
              id={`header${category}`}
              onClick={() => closeModal(category)}
            >
              <Arrow alt="icon arrow" src={ArrowSrc} />
              <FormattedMessage {...messages[category]} />
            </Header>
          </Content>
          <ModalLinkList
            category={category}
            closeModal={closeModal}
            handleDrawer={handleDrawer}
            links={links}
            selectLocale={selectLocale}
          />
        </Container>
      </ModalWrapper>
    </CSSTransition>
  </Background>
);

Modal.propTypes = {
  category: PropTypes.string,
  closeModal: PropTypes.func,
  displayModal: PropTypes.bool,
  handleDrawer: PropTypes.func,
  links: PropTypes.array,
  selectLocale: PropTypes.func,
};

export default Modal;
