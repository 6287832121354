/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { connectRouter } from 'connected-react-router/immutable';
import { combineReducers } from 'redux-immutable';
import cartReducer from './common/Cart/reducer';
import passengersReducer from './common/Passengers/reducer';
import globalReducer from './containers/App/reducer';
import cityReducer from './containers/City/reducer';
import headerReducer from './containers/Header/reducer';
import languageProviderReducer from './containers/LanguageProvider/reducer';
import largeTravelSliderReducer from './containers/LargeTravelSlider/reducer';
import { LOGIN } from './containers/Login/constants';
import loginReducer from './containers/Login/reducer';
import { ORDERS } from './containers/Orders/constants';
import ordersReducer from './containers/Orders/reducer';
import partnersReducer from './containers/Partners/reducer';
import searchReducer from './containers/Search/reducer';
import stationReducer from './containers/Station/reducer';
import tsbReducer from './containers/TravelSearchBar/reducer';
import history from './utils/history';

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export default function createReducer(injectedReducers = {}) {
  const rootReducer = combineReducers({
    global: globalReducer,
    language: languageProviderReducer,
    header: headerReducer,
    travelSearchBar: tsbReducer,
    largeTravelSlider: largeTravelSliderReducer,
    partners: partnersReducer,
    passengers: passengersReducer,
    commonCart: cartReducer,
    search: searchReducer,
    city: cityReducer,
    station: stationReducer,
    [LOGIN]: loginReducer,
    [ORDERS]: ordersReducer,
    ...injectedReducers,
  });

  // Wrap the root reducer and return a new root reducer with router state
  const mergeWithRouterState = connectRouter(history);
  return mergeWithRouterState(rootReducer);
}
