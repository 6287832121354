import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import tw from 'tailwind.macro';
import { Icon24 } from '../../../components/Icon';
import Close from '../../../images/iconClose.svg';
import Expand from '../../../images/navbarExpand.svg';

const BurgerButton = styled.button`
  ${tw`absolute pin-r flex p-def h-full items-center lg:hidden`}
`;

const Icon = styled.img`
  ${Icon24}
`;

const MobileExpand = ({ displayDrawer, handleDrawer }) => (
  <BurgerButton onClick={() => handleDrawer(!displayDrawer)}>
    <Icon
      alt="Close Header"
      id="closeMobileHeader"
      src={displayDrawer ? Close : Expand}
    />
  </BurgerButton>
);

MobileExpand.propTypes = {
  displayDrawer: PropTypes.bool,
  handleDrawer: PropTypes.func,
};

export default MobileExpand;
