/*
 * Cart Messages
 *
 * This contains all the text for the Cart container.
 */

import { defineMessages } from 'react-intl';
import {
  INFO_PRICE_CHANGED_DISCOUNT_CARDS,
  INFO_PRICE_CHANGED_YOUTH,
  INFO_PRICE_DOES_NOT_YET_INCLUDE_DISCOUNT_CARDS,
  INFO_PRICE_INCREASED,
} from '../constants';

export const cartScope = 'app.components.Cart';

export const cartMessages = {
  totalPrice: {
    id: `${cartScope}.totalPrice`,
    defaultMessage: 'Prix total pour ',
    en: 'Total price for ',
    it: 'Prezzo totale per ',
    es: 'Precio total para ',
    de: 'Gesamtpreis für ',
    nl: 'Totale prijs voor ',
  },
  passenger: {
    id: `${cartScope}.passenger`,
    defaultMessage: ' passager',
    en: ' passenger',
    es: ' pasajero',
    it: ' passeggero',
    de: ' passagier',
    nl: ' passagier',
  },
  passengers: {
    id: `${cartScope}.passengers`,
    defaultMessage: ' passagers',
    en: ' passengers',
    es: ' pasajero',
    it: ' pasajero',
    de: ' passagiere',
    nl: ' passagiers',
  },
  submit: {
    id: `${cartScope}.submit`,
    defaultMessage: 'Valider',
    en: 'Select',
    it: 'Convalidare',
    es: 'Validar',
    de: 'Bestätigen',
    nl: 'Valideren',
  },
  details: {
    id: `${cartScope}.details`,
    defaultMessage: 'Détails',
    en: 'Details',
    es: 'Detalle',
    it: 'Dettaglio',
    de: 'Details',
    nl: 'Details',
  },
  outboundTrip: {
    id: `${cartScope}.outboundTrip`,
    defaultMessage: 'Récapitulatif de votre trajet aller',
    en: 'Summary of your outbound journey',
    it: 'Riepilogo del tuo viaggio di andata',
    es: 'Resumen de su viaje de ida',
    de: 'Zusammenfassung Ihrer Hinreise',
    nl: 'Samenvatting van uw heenreis',
  },
  returnTrip: {
    id: `${cartScope}.returnTrip`,
    defaultMessage: 'Récapitulatif de votre voyage',
    en: 'Journey summary',
    it: 'Riepilogo del viaggio',
    es: 'Resumen del viaje',
    de: 'Reisezusammenfassung',
    nl: 'Overzicht van de reis',
  },
  passengersInfo: {
    id: `${cartScope}.passengersInfo`,
    defaultMessage: 'Récapitulatif de votre voyage',
    en: 'Journey summary',
    it: 'Riepilogo del viaggio',
    es: 'Resumen del viaje',
    de: 'Reisezusammenfassung',
    nl: 'Overzicht van de reis',
  },
  priceFor: {
    id: `${cartScope}.priceFor`,
    defaultMessage: 'Prix pour ',
    en: 'Price for ',
    it: 'Premi per ',
    es: 'Premios para ',
    de: 'Prix pour ',
    nl: 'Onderscheidingen voor ',
  },
  [INFO_PRICE_DOES_NOT_YET_INCLUDE_DISCOUNT_CARDS]: {
    id: [cartScope, INFO_PRICE_DOES_NOT_YET_INCLUDE_DISCOUNT_CARDS].join('.'),
    defaultMessage:
      '{count, plural, one {Le prix affiché ne comprend} other {Les prix affichés ne comprennent}} pas encore vos cartes de réduction.',
    en: 'The {count, plural, one {price} other {prices}} shown does not yet include your discount cards.',
    it: '{count, plural, one {Il prezzo indicato non comprende} other {I prezzi indicati non comprendono}} ancora le carte sconto.',
    es: '{count, plural, one {El precio indicado} other {Los precios indicados}} aún no incluye sus tarjetas de descuento.',
    de: '{count, plural, one {Der angezeigte Preis enthält} other {Die angezeigten Preise beinhalten}} noch nicht Ihre Rabattkarten.',
    nl: 'In de getoonde {count, plural, one {prijs} other {prijzen}} zijn uw kortingskaarten nog niet inbegrepen.',
  },
  [INFO_PRICE_CHANGED_DISCOUNT_CARDS]: {
    id: [cartScope, INFO_PRICE_CHANGED_DISCOUNT_CARDS].join('.'),
    defaultMessage: 'Le prix indiqué a changé grâce à vos cartes de réduction.',
    en: 'The price shown has changed thanks to your discount cards.',
    it: 'Il prezzo indicato è stato modificato grazie alle tue carte sconto.',
    es: 'El precio indicado ha cambiado gracias a sus tarjetas de descuento.',
    de: 'Der angezeigte Preis hat sich aufgrund Ihrer Rabattkarten verändert.',
    nl: 'De prijs is veranderd door uw kortingskaarten.',
  },
  [INFO_PRICE_CHANGED_YOUTH]: {
    id: [cartScope, INFO_PRICE_CHANGED_YOUTH].join('.'),
    defaultMessage:
      'Le prix affiché a changé car un passager jeune a été ajouté.',
    en: 'The price shown has changed because a younger passenger has been added.',
    it: 'Il prezzo visualizzato è cambiato perché è stato aggiunto un giovane passeggero.',
    es: 'El precio mostrado ha cambiado porque se ha añadido un pasajero joven.',
    de: 'De getoonde prijs is veranderd omdat er een jonge passagier is bijgekomen.',
    nl: 'De prijs is veranderd omdat er een jonger passagier is toegevoegd.',
  },
  [INFO_PRICE_INCREASED]: {
    id: [cartScope, INFO_PRICE_INCREASED].join('.'),
    defaultMessage: 'Le prix a changé en raison d’une forte demande',
    en: 'The price has changed because of a strong demand',
    it: 'Il prezzo è cambiato in riferimento a una forte richiesta',
    es: 'El precio ha cambiado debido a una demanda fuerte',
    de: 'Der Preis hat sich aufgrund einer starren Anfrage verändert',
    nl: 'De prijs is veranderd omdat er een sterk verzoek is geweest',
  },
};

export default defineMessages(cartMessages);
