/*
 *
 * Header actions
 *
 */

import {
  HANDLE_DRAWER,
  SELECT_TAB,
  SET_IS_NAVBAR_BACK_BUTTON_HIDDEN,
} from './constants';

/**
 * @description Opens and closes the mobile drawer
 * @param {boolean} value To close or open the mobile drawer
 * @returns {{type: string, value: boolean}}
 */
export function handleDrawerAction(value) {
  return {
    type: HANDLE_DRAWER,
    value,
  };
}

/**
 * @description Select a tab to open in the navbar
 * @param {string} category
 * @returns {{type: string, category: string}}
 */
export function selectTabAction(category) {
  return {
    type: SELECT_TAB,
    category,
  };
}

/**
 * @description Define if the back button is hidden or not
 * @param {boolean} isNavbarBackButtonHidden
 * @returns {{type: string, category: string}}
 */
export function setIsNavbarBackButtonHiddenAction(isNavbarBackButtonHidden) {
  return {
    type: SET_IS_NAVBAR_BACK_BUTTON_HIDDEN,
    isNavbarBackButtonHidden,
  };
}
