export enum EFunnelStep {
  NONE = 'STEP_NONE',
  RESULTS = 'STEP_RESULTS',
  PAYMENT = 'STEP_PAYMENT',
  INFO = 'STEP_PASSENGERS_INFO',
}

export interface IAppContext {
  partner: IPartner | null;
  funnelStep: EFunnelStep;
  isInFunnel: boolean;
  getPartner: (partnerId: string) => Promise<IPartner>;
  referer: string;
}

export interface IPartner {
  id: string;
  gpuids?: string[];
  placeholder?: string;
  header?: string;
  subHeader?: string;
  primaryColor?: string;
  secondaryColor?: string;
  backgroundUrl?: string;
  logoUrl?: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
}
