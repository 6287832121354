import { lazy, Suspense } from 'react';
import LazyLoading from '../../components/LazyLoading';

export const LazyCompany = lazy(() =>
  import(/* webpackChunkName: 'Company' */ './index'),
);

export default function Company(props) {
  return (
    <Suspense fallback={<LazyLoading />}>
      <LazyCompany {...props} />
    </Suspense>
  );
}
