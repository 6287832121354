/*
 * Passengers Reducer
 */

import { fromJS } from 'immutable';
import { OTHER_CUSTOMER } from '../../containers/PassengersInfo/constants';
import { dayjs } from '../../libs/day';
import {
  ADD_DISCOUNT_CARD,
  ADD_PASSENGER,
  ADULT,
  BIRTH_DAY,
  BIRTH_MONTH,
  BIRTH_YEAR,
  CUSTOMER,
  CUSTOMER_ID,
  DEFAULT_ADULT_AGE,
  DEFAULT_SENIOR_AGE,
  DELETE_DISCOUNT_CARD,
  DELETE_PASSENGER,
  DISCOUNT_CARD_ID,
  DOCUMENT_COUNTRY_CODE,
  DOCUMENT_EXPIRATION_DATE,
  DOCUMENT_NUMBER,
  DOCUMENT_TYPE,
  FIRST_NAME,
  HANDLE_URL_PASSENGERS,
  IS_CUSTOMER,
  IS_SUBSCRIBED_TO_NEWSLETTER,
  LAST_NAME,
  MAIL,
  MAXIMUM_PASSENGERS,
  PASSENGERS,
  PHONE_NUMBER,
  REPLACE_CUSTOMER,
  REPLACE_PASSENGERS,
  RESET_CUSTOMER_ID,
  SENIOR,
  SET_BIRTH_DAY,
  SET_BIRTH_MONTH,
  SET_BIRTH_YEAR,
  SET_CUSTOMER_ID,
  SET_CUSTOMER_MAIL,
  SET_DISCOUNT_CARD_ID,
  SET_DOCUMENT_COUNTRY_CODE,
  SET_DOCUMENT_EXPIRATION_DATE,
  SET_DOCUMENT_NUMBER,
  SET_DOCUMENT_TYPE,
  SET_FIRST_NAME,
  SET_LAST_NAME,
  SET_PHONE_NUMBER,
  SET_TITLE,
  SUBSCRIBE_TO_NEWSLETTER,
  TITLE,
  UPDATE_PASSENGER_AGE,
  UPDATE_PASSENGER_CATEGORY,
} from './constants';

export const initialState = fromJS({
  [PASSENGERS]: [
    {
      id: 0,
      category: ADULT,
      age: DEFAULT_ADULT_AGE,
      discountCards: [],
    },
  ],
  customer: {
    isSubscribedToNewsletter: false,
  },
  customerId: undefined,
});

function passengersReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_PASSENGER: {
      const { category } = action;

      const age = getDefaultAgeByCategory(category);

      const newPassenger = fromJS({
        id: 0,
        category,
        age,
        discountCards: [],
      });
      if (state.get(PASSENGERS).size < MAXIMUM_PASSENGERS) {
        return state
          .update(PASSENGERS, (passengers) =>
            passengers.map((passenger) =>
              passenger.set('id', passenger.get('id') + 1),
            ),
          )
          .update(PASSENGERS, (passengers) => passengers.push(newPassenger));
      }

      return state;
    }

    case UPDATE_PASSENGER_CATEGORY:
      return state.setIn(
        [PASSENGERS, findIndex(state, action.passengerId), 'category'],
        action.category,
      );

    case UPDATE_PASSENGER_AGE:
      return state.setIn(
        [PASSENGERS, findIndex(state, action.passengerId), 'age'],
        action.age,
      );

    case DELETE_PASSENGER: {
      if (state.get(PASSENGERS).size === 1) {
        return state;
      }

      return state.deleteIn([PASSENGERS, findIndex(state, action.id)]);
    }

    case ADD_DISCOUNT_CARD: {
      if (!discountCardAlreadyAdded(state, action.passengerId, action.card.id))
        return state.setIn(
          ['passengers', findIndex(state, action.passengerId), 'discountCards'],
          fromJS([action.card]),
        );

      return state;
    }

    case DELETE_DISCOUNT_CARD: {
      return state.updateIn(
        ['passengers', findIndex(state, action.passengerId), 'discountCards'],
        (cardsList) => cardsList.delete(action.cardId),
      );
    }

    case HANDLE_URL_PASSENGERS: {
      return state.setIn([PASSENGERS], fromJS(action.passengers));
    }

    case SET_TITLE: {
      let altState = state;
      if (action.passengerType === CUSTOMER) {
        return state.setIn([CUSTOMER, 'title'], action.value);
      }

      if (state.get(CUSTOMER_ID) === action.passengerId) {
        altState = state.setIn([CUSTOMER, 'title'], action.value);
      }

      return altState.setIn(
        [PASSENGERS, findIndex(state, action.passengerId), 'title'],
        action.value,
      );
    }

    case SET_FIRST_NAME: {
      let altState = state;
      if (action.passengerType === CUSTOMER) {
        return state.setIn([CUSTOMER, FIRST_NAME], action.value);
      }

      if (state.get(CUSTOMER_ID) === action.passengerId) {
        altState = state.setIn([CUSTOMER, FIRST_NAME], action.value);
      }

      return altState.setIn(
        [PASSENGERS, findIndex(state, action.passengerId), FIRST_NAME],
        action.value,
      );
    }

    case SET_LAST_NAME: {
      let altState = state;
      if (action.passengerType === CUSTOMER) {
        return state.setIn([CUSTOMER, LAST_NAME], action.value);
      }

      if (state.get(CUSTOMER_ID) === action.passengerId) {
        altState = state.setIn([CUSTOMER, LAST_NAME], action.value);
      }

      return altState.setIn(
        [PASSENGERS, findIndex(state, action.passengerId), LAST_NAME],
        action.value,
      );
    }

    case SET_BIRTH_DAY: {
      let altState = state;
      if (action.passengerType === CUSTOMER) {
        return state.setIn([CUSTOMER, BIRTH_DAY], action.value);
      }

      if (state.get(CUSTOMER_ID) === action.passengerId) {
        altState = state.setIn([CUSTOMER, BIRTH_DAY], action.value);
      }

      return altState.setIn(
        [PASSENGERS, findIndex(state, action.passengerId), BIRTH_DAY],
        action.value,
      );
    }

    case SET_DOCUMENT_EXPIRATION_DATE: {
      return state.setIn(
        [
          PASSENGERS,
          findIndex(state, action.passengerId),
          DOCUMENT_EXPIRATION_DATE,
        ],
        action.value,
      );
    }

    case SET_DOCUMENT_NUMBER: {
      return state.setIn(
        [PASSENGERS, findIndex(state, action.passengerId), DOCUMENT_NUMBER],
        action.value,
      );
    }

    case SET_DOCUMENT_COUNTRY_CODE: {
      return state.setIn(
        [
          PASSENGERS,
          findIndex(state, action.passengerId),
          DOCUMENT_COUNTRY_CODE,
        ],
        action.value,
      );
    }
    case SET_DOCUMENT_TYPE: {
      return state.setIn(
        [PASSENGERS, findIndex(state, action.passengerId), DOCUMENT_TYPE],
        action.value,
      );
    }

    case SET_BIRTH_MONTH: {
      let altState = state;
      if (action.passengerType === CUSTOMER) {
        return state.setIn([CUSTOMER, BIRTH_MONTH], action.value);
      }

      if (state.get(CUSTOMER_ID) === action.passengerId) {
        altState = state.setIn([CUSTOMER, BIRTH_MONTH], action.value);
      }

      return altState.setIn(
        [PASSENGERS, findIndex(state, action.passengerId), BIRTH_MONTH],
        action.value,
      );
    }

    case SET_BIRTH_YEAR: {
      let altState = state;
      if (action.passengerType === CUSTOMER) {
        return state.setIn([CUSTOMER, BIRTH_YEAR], action.value);
      }

      if (state.get(CUSTOMER_ID) === action.passengerId) {
        altState = state.setIn([CUSTOMER, BIRTH_YEAR], action.value);
      }

      return altState.setIn(
        [PASSENGERS, findIndex(state, action.passengerId), BIRTH_YEAR],
        action.value,
      );
    }

    case SET_PHONE_NUMBER: {
      return state.setIn([CUSTOMER, PHONE_NUMBER], action.value);
    }

    case SUBSCRIBE_TO_NEWSLETTER:
      return state.setIn(
        [CUSTOMER, IS_SUBSCRIBED_TO_NEWSLETTER],
        !state.getIn([CUSTOMER, IS_SUBSCRIBED_TO_NEWSLETTER]),
      );

    case SET_CUSTOMER_MAIL:
      return state.setIn([CUSTOMER, MAIL], action.value);

    case SET_DISCOUNT_CARD_ID:
      const value = action.value.replace(/\s/g, '').substring(0, 17);
      return state.setIn(
        [PASSENGERS, findIndex(state, action.passengerId), DISCOUNT_CARD_ID],
        value,
      );

    case SET_CUSTOMER_ID: {
      let altState = state;
      if (action.id === OTHER_CUSTOMER) {
        altState = state
          .setIn([CUSTOMER, TITLE], '')
          .setIn([CUSTOMER, FIRST_NAME], '')
          .setIn([CUSTOMER, LAST_NAME], '')
          .setIn([CUSTOMER, BIRTH_DAY], undefined)
          .setIn([CUSTOMER, BIRTH_MONTH], undefined)
          .setIn([CUSTOMER, BIRTH_YEAR], undefined)
          .update(PASSENGERS, (passengers) =>
            passengers.map((passenger) => passenger.set(IS_CUSTOMER, false)),
          );
      } else {
        altState = state
          .setIn(
            [CUSTOMER, TITLE],
            state.getIn([PASSENGERS, findIndex(state, action.id), TITLE]),
          )
          .setIn(
            [CUSTOMER, FIRST_NAME],
            state.getIn([PASSENGERS, findIndex(state, action.id), FIRST_NAME]),
          )
          .setIn(
            [CUSTOMER, LAST_NAME],
            state.getIn([PASSENGERS, findIndex(state, action.id), LAST_NAME]),
          )
          .setIn(
            [CUSTOMER, BIRTH_DAY],
            state.getIn([PASSENGERS, findIndex(state, action.id), BIRTH_DAY]),
          )
          .setIn(
            [CUSTOMER, BIRTH_MONTH],
            state.getIn([PASSENGERS, findIndex(state, action.id), BIRTH_MONTH]),
          )
          .setIn(
            [CUSTOMER, BIRTH_YEAR],
            state.getIn([PASSENGERS, findIndex(state, action.id), BIRTH_YEAR]),
          )
          .update(PASSENGERS, (passengers) =>
            passengers.map((passenger) => passenger.set(IS_CUSTOMER, false)),
          )
          .setIn([PASSENGERS, findIndex(state, action.id), IS_CUSTOMER], true);
      }

      return altState.set(CUSTOMER_ID, action.id);
    }

    case REPLACE_PASSENGERS:
      return state.set(
        PASSENGERS,
        fromJS(
          action.passengers.map((passenger) => {
            const birthdate = passenger.birthdate
              ? dayjs(passenger.birthdate)
              : null;

            return {
              ...passenger,
              ...(birthdate && {
                birthDay: birthdate.date(),
                birthMonth: birthdate.month() + 1,
                birthYear: birthdate.year(),
              }),
              ...(passenger.identityDocument && {
                documentCountryCode: passenger.identityDocument.countryCode,
                documentType: passenger.identityDocument.type,
                documentNumber: passenger.identityDocument.documentIdentifier,
                documentExpirationDate:
                  passenger.identityDocument.expirationDate
                    .split('-')
                    .reverse()
                    .join('/'),
              }),
            };
          }),
        ),
      );

    case REPLACE_CUSTOMER:
      const birthdate = action.customer.birthdate
        ? dayjs(action.customer.birthdate)
        : null;

      return state.set(
        CUSTOMER,
        fromJS({
          ...action.customer,
          ...(birthdate && {
            birthDay: birthdate.date(),
            birthMonth: birthdate.month() + 1,
            birthYear: birthdate.year(),
          }),
        }),
      );

    case RESET_CUSTOMER_ID:
      return state.set(CUSTOMER_ID, undefined);

    default:
      return state;
  }
}

/**
 * @description check if a specific discount card (based on its id) already exist for the passenger
 * @param {object} state: initialState from the reducer
 * @param {number} passengerId: id of the passenger
 * @param {number} cardId: id of the card
 * @returns {boolean}
 */
const discountCardAlreadyAdded = (state, passengerId, cardId) =>
  state
    .getIn([
      PASSENGERS,
      state
        .get(PASSENGERS)
        .findIndex((passenger) => passenger.get('id') === passengerId),
      'discountCards',
    ])
    .toJS()
    .some((card) => card.id === cardId);

/**
 * @description get the index of the passenger in the passengers Array based on it's id
 * @param {object} state: initialState from the reducer
 * @param {Number} id: id of the passenger
 * @returns {Number}
 */
const findIndex = (state, id) =>
  state.get(PASSENGERS).findIndex((passenger) => passenger.get('id') === id);

/**
 * @description Get the age of a passenger based on his category
 * @param {string} category The category of the passenger, can be "adults", "youths" or "seniors"
 * @returns {number} The age of the passenger
 */
function getDefaultAgeByCategory(category) {
  switch (category) {
    case ADULT:
      return DEFAULT_ADULT_AGE;

    case SENIOR:
      return DEFAULT_SENIOR_AGE;

    default:
      return undefined;
  }
}

export default passengersReducer;
