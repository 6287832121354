/**
 * The global state selectors
 */

import { createSelector } from 'reselect';
import { colors } from '../../styles/constants';
import { COLORS, PRIMARY_COLOR, SECONDARY_COLOR } from './constants';

const selectGlobal = (state) => state.get('global');

const selectRouter = (state) => state.get('router');

const makeSelectApp = () =>
  createSelector(selectGlobal, (routerState) => routerState.toJS());

const makeSelectLocation = () =>
  createSelector(selectRouter, (routerState) =>
    routerState.get('location').toJS(),
  );

const selectFunnelStep = () =>
  createSelector(selectGlobal, (globalState) => globalState.get('funnelStep'));

const selectSearchUrl = () =>
  createSelector(selectGlobal, (globalState) => globalState.get('searchUrl'));

const selectTheme = () =>
  createSelector(selectGlobal, (globalState) => ({
    colors: {
      primary: globalState.getIn([COLORS, PRIMARY_COLOR]) || colors.terciary500,
      secondary:
        globalState.getIn([COLORS, SECONDARY_COLOR]) || colors.primary400,
    },
  }));

export {
  makeSelectApp,
  makeSelectLocation,
  selectFunnelStep,
  selectGlobal,
  selectSearchUrl,
  selectTheme,
};
