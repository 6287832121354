import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import tw from 'tailwind.macro';
import ArrowSrc from '../../../images/arrowRightDark.svg';
import { colors } from '../../../styles/constants';

const Container = styled.div`
  ${tw`h-full
    flex items-center justify-between
    cursor-pointer
    lg:ml-abs3
    lg:ml-abs4
    lg:inline-flex`};
  padding-bottom: 15px;
  padding-top: 15px;
  border-bottom: 1px solid ${colors.grey200};
  -webkit-tap-highlight-color: transparent;

  @media (min-width: 992px) {
    border-bottom: 7px solid transparent;
    border-top: 7px solid transparent;
    &:hover {
      border-bottom: 7px solid ${colors.terciary500};
    }
  }
`;

const Category = styled.p`
  ${tw`outline-none text-headerTab`};
`;

const Arrow = styled.img`
  ${tw`mx-abs1
    lg:hidden`};
`;

const Tab = ({ category, selectTab, text }) => (
  <Container onClick={() => selectTab(category)}>
    <Category id={`tab${category}`}>{text}</Category>
    <Arrow alt="icon arrow" src={ArrowSrc} />
  </Container>
);

Tab.propTypes = {
  category: PropTypes.string,
  selectTab: PropTypes.func,
  text: PropTypes.object,
};

export default Tab;
