import styled from 'styled-components/macro';

const Flag = styled.img`
  width: 25.5px;
  height: 17px;
  border-radius: 4px;
  margin-right: 1rem;
`;

export default Flag;
